import { useParams } from 'react-router-dom';
import PublicReportData from './ReportData/PublicReportData';
import { TabPanel, Tabs } from '@round/ui-kit';
import usePlatformHashSelect from 'Modules/Plans/hooks/usePlatformHashSelect';
import PlatformTabs from 'Modules/Plans/components/PlatformTabs/PlatformTabs';
import styles from './PublicReport.module.css';
import PlatformHeadlineStats from './PlatformHeadlineStats/PlatformHeadlineStats';
import PublicReportTiktokCampaigns from './Campaigns/TiktokCampaign/PublicReportTiktokCampaigns';
import PublicReportInstagramCampaigns from './Campaigns/InstagramCampaign/PublicReportInstagramCampaigns';
import PublicReportYoutubeCampaigns from './Campaigns/YoutubeCampaign/PublicReportYoutubeCampaigns';

const PublicReport = () => {
    const { uuid } = useParams<{ uuid: string }>();
    const [activeTab, setActiveTab] = usePlatformHashSelect();

    return (
        <Tabs activeTab={activeTab} onChange={setActiveTab}>
            <header className={styles.header}>
                <PublicReportData publicReportId={uuid} />
                <PlatformTabs className={styles.tabsNav}>
                    <PlatformTabs.Tab name="instagram" platform="instagram" />
                    <PlatformTabs.Tab name="tiktok" platform="tiktok" />
                    <PlatformTabs.Tab name="youtube" platform="youtube" />
                </PlatformTabs>
            </header>

            <main>
                <TabPanel name="instagram">
                    <PlatformHeadlineStats reportId={uuid} platform="instagram" />
                    <section className={styles.campaigns}>
                        <PublicReportInstagramCampaigns publicReportId={uuid} />
                    </section>
                </TabPanel>

                <TabPanel name="tiktok">
                    <PlatformHeadlineStats reportId={uuid} platform="tiktok" />
                    <section className={styles.campaigns}>
                        <PublicReportTiktokCampaigns publicReportId={uuid} />
                    </section>
                </TabPanel>

                <TabPanel name="youtube">
                    <PlatformHeadlineStats reportId={uuid} platform="youtube" />
                    <section className={styles.campaigns}>
                        <PublicReportYoutubeCampaigns publicReportId={uuid} />
                    </section>
                </TabPanel>
            </main>
        </Tabs>
    );
};

export default PublicReport;
