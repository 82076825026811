import { useNonNullContextSelector } from 'Hooks/useNonNullContextSelector';
import { ReportContext } from '../ReportContext';
import { ContextType, useCallback } from 'react';
import { creatorbase } from '@round/api';
import { useReportCampaignStats } from './hooks/useReportCampaignStats';
import moment from 'moment';
import { makeProjectDataHook } from 'Modules/Plans/Project/hooks/dataHooks/useProjectsDataHook';
import { makeBrandsDataHook } from '../../../Brand/hooks/dataHooks/useBrandsDataHook';

type ContextValue = ContextType<typeof ReportContext>;

const useProjects = makeProjectDataHook<ContextValue, creatorbase.Project>(ReportContext, ([state]) => state.projects);
const useBrands = makeBrandsDataHook<ContextValue, creatorbase.Brand>(ReportContext, ([state]) => state.brands);

export default function useCampaigns() {
    const audioTimeSeriesState = useNonNullContextSelector(ReportContext, ([values]) => values.audioTimeSeries);
    const campaignsState = useNonNullContextSelector(ReportContext, ([values]) => values.campaigns);
    const tiktokAudiosState = useNonNullContextSelector(ReportContext, ([values]) => values.tiktokAudios);
    const dispatch = useNonNullContextSelector(ReportContext, ([, dispatch]) => dispatch);

    const { data: campaignStatsState, fetchData: fetchStats } = useReportCampaignStats();
    const { data: projectsData, fetchData: fetchProjects } = useProjects();
    const { data: brandsData, fetchData: fetchBrandsData } = useBrands();

    const fetchAudioTimeSeriesData = useCallback(
        async (
            params: Pick<creatorbase.TimeSeriesRequestParams, 'start_date' | 'end_date' | 'ids'>,
            signal?: AbortSignal
        ) => {
            if (!params.ids.length) {
                dispatch({ type: 'audioTimeSeriesDataInitialized' });
                return;
            }

            try {
                dispatch({ type: 'audioTimeSeriesDataLoading', payload: { ids: params.ids } });

                const response = await creatorbase.postTimeSeries(
                    { type: 'tiktok_audio', fields: ['posts_daily_change'], ...params },
                    signal
                );

                if (response.status === 200) {
                    dispatch({
                        type: 'audioTimeSeriesDataSuccess',
                        payload: { ids: params.ids, data: response.data },
                    });
                    dispatch({ type: 'audioTimeSeriesDataInitialized' });
                    return;
                }

                dispatch({
                    type: 'audioTimeSeriesDataError',
                    payload: { ids: params.ids, error: `Couldn't fetch time series data` },
                });
            } catch (error) {
                if (error instanceof Error && error.name === 'AbortError') {
                    dispatch({ type: 'audioTimeSeriesDataIdle', payload: { ids: params.ids } });
                    throw error;
                }

                dispatch({
                    type: 'audioTimeSeriesDataError',
                    payload: { ids: params.ids, error: `Couldn't fetch time series data` },
                });
                throw error;
            }
        },
        [dispatch]
    );

    const fetchTiktokAudios = useCallback(
        async (ids: number[], requestInit?: RequestInit) => {
            if (!ids.length) {
                dispatch({ type: 'tiktokAudiosInitialized' });
                return;
            }

            dispatch({ type: 'tiktokAudiosLoading', payload: { ids } });

            try {
                const response = await creatorbase.getTiktokAudios(
                    { id: ids.join(','), page_size: ids.length },
                    requestInit
                );

                if (response.status !== 200) {
                    dispatch({ type: 'tiktokAudiosError', payload: { ids, error: 'Failed to fetch Tiktok audios' } });
                    return;
                }

                dispatch({ type: 'tiktokAudiosSuccess', payload: { ids, tiktokAudios: response.data.results } });
                dispatch({ type: 'tiktokAudiosInitialized' });
            } catch (error) {
                if (error instanceof Error && error.name === 'AbortError') {
                    dispatch({ type: 'tiktokAudiosIdle', payload: { ids } });
                    throw error;
                }

                dispatch({ type: 'tiktokAudiosError', payload: { ids, error: 'Failed to fetch Tiktok audios' } });
                throw error;
            }
        },
        [dispatch]
    );

    const fetchData = useCallback(
        async (
            params: Required<Pick<creatorbase.GetCampaignsParams, 'report_id' | 'platform'>>,
            requestInit?: RequestInit
        ) => {
            try {
                dispatch({ type: 'loadCampaigns', payload: params.platform });
                const response = await creatorbase.getCampaigns(
                    { ...params, page_size: 1000, ordering: 'team,id' },
                    requestInit
                );
                if (response.status !== 200) {
                    dispatch({
                        type: 'errorLoadingCampaigns',
                        payload: { platform: params.platform, message: response.data.detail },
                    });
                    return response;
                }

                const projectIds = response.data.results.map((campaign) => campaign.project_id);
                const projectResult = await fetchProjects(projectIds, requestInit);
                if (projectResult?.status !== 200) {
                    dispatch({
                        type: 'errorLoadingCampaigns',
                        payload: { platform: params.platform, message: 'Could not get projects' },
                    });
                    return response;
                }

                const brandIds = projectResult.data.results.map((project) => project.brand_id);
                const brandResult = await fetchBrandsData(brandIds, requestInit);
                if (brandResult?.status !== 200) {
                    dispatch({
                        type: 'errorLoadingCampaigns',
                        payload: { platform: params.platform, message: 'Could not get brands' },
                    });
                    return response;
                }

                const audioIds =
                    response.data.results
                        .map((campaign) => campaign.tiktok_details?.audio_id)
                        .filter((audio_id): audio_id is number => !!audio_id) || [];
                await Promise.allSettled([
                    fetchAudioTimeSeriesData({
                        ids: audioIds,
                        start_date: moment().subtract(14, 'days').format('YYYY-MM-DD'),
                        end_date: moment().format('YYYY-MM-DD'),
                    }),
                    fetchTiktokAudios(audioIds),
                ]);

                dispatch({
                    type: 'campaignsSuccess',
                    payload: {
                        platform: params.platform,
                        data: response.data,
                    },
                });
                const campaignIds = response.data.results.map((campaign) => campaign.id);
                fetchStats(campaignIds).catch(() => {});
                dispatch({ type: 'campaignsInitialized', payload: params.platform });
                return response;
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    dispatch({ type: 'campaignsIdle', payload: params.platform });
                    throw e;
                }

                dispatch({
                    type: 'errorLoadingCampaigns',
                    payload: { platform: params.platform, message: 'Could not get campaigns' },
                });
                throw e;
            }
        },
        [dispatch, fetchProjects, fetchBrandsData, fetchAudioTimeSeriesData, fetchTiktokAudios, fetchStats]
    );

    return {
        audioTimeSeriesData: audioTimeSeriesState,
        campaignsData: campaignsState,
        projectsData,
        brandsData,
        campaignStatsData: campaignStatsState,
        tiktokAudiosData: tiktokAudiosState,
        fetchData,
    };
}
