import { Context } from '@fluentui/react-context-selector';
import { createBatchedParamsArrayFromIds, getTiktokVideos, TiktokVideo } from '@round/api';
import { DataState, ReducerActionWithPayload } from 'App.types';
import { createReducer } from 'helpers';
import { ContextSelector, useNonNullContextSelector } from 'Hooks/useNonNullContextSelector';
import { Dispatch, useCallback } from 'react';

export type State = {
    [videoId: number]: DataState<TiktokVideo | null> | undefined;
};

export const initialState: State = {};

export type Actions =
    | ReducerActionWithPayload<'loadTiktokVideos', number[]>
    | ReducerActionWithPayload<'tiktokVideosInitialized', { videoIds: number[]; videos: TiktokVideo[] }>
    | ReducerActionWithPayload<'tiktokVideosErrorLoading', { videoIds: number[]; error: string }>;

export const reducer = createReducer<State, Actions>({
    loadTiktokVideos: (state, { payload: videoIds }) => {
        const loadingVideos = videoIds.reduce((acc, id) => {
            acc[id] = { status: 'loading', data: null, error: null };
            return acc;
        }, {} as State);

        return { ...state, ...loadingVideos };
    },
    tiktokVideosInitialized: (state, { payload: { videoIds, videos } }) => {
        const initialized = videoIds.reduce((acc, id) => {
            acc[id] = { status: 'success', data: videos.find((v) => v.id === id) ?? null, error: null };
            return acc;
        }, {} as State);

        return { ...state, ...initialized };
    },
    tiktokVideosErrorLoading: (state, { payload: { videoIds, error } }) => {
        const errors = videoIds.reduce((acc, id) => {
            acc[id] = { status: 'error', error, data: null };
            return acc;
        }, {} as State);

        return { ...state, ...errors };
    },
});

export function makeTiktokVideosDataHook<Value extends [any, Dispatch<any>] | null>(
    context: Context<Value>,
    selector: ContextSelector<Value, State>
) {
    return function () {
        const state = useNonNullContextSelector(context, selector);
        const dispatch = useNonNullContextSelector(context, ([, dispatch]) => dispatch);

        const fetchData = useCallback(
            async (videoIds: number[], requestInit?: RequestInit) => {
                if (!videoIds.length) {
                    dispatch({ type: 'tiktokVideosInitialized', payload: { videoIds: [], videos: [] } });
                    return;
                }

                try {
                    dispatch({ type: 'loadTiktokVideos', payload: videoIds });

                    // Since we aren't paginating yet, we need to batch the requests to avoid exceeding the URL length limit
                    const batchedParams = createBatchedParamsArrayFromIds(videoIds, (ids) => ({
                        id: ids.toString(),
                    }));

                    const results = await Promise.all(
                        batchedParams.map((params) =>
                            getTiktokVideos(params, requestInit).then((res) => {
                                if (res.status !== 200) {
                                    throw new Error('Could not get tiktok videos');
                                }

                                return res.data.results;
                            })
                        )
                    );

                    dispatch({
                        type: 'tiktokVideosInitialized',
                        payload: { videoIds, videos: results.flat() },
                    });
                } catch (e) {
                    if (e instanceof Error && e.name === 'AbortError') {
                        throw e;
                    }

                    dispatch({
                        type: 'tiktokVideosErrorLoading',
                        payload: { videoIds, error: 'Could not get tiktok videos' },
                    });
                    throw e;
                }
            },
            [dispatch]
        );

        return {
            data: state,
            fetchData,
        };
    };
}
