import { youtube } from '@round/api';
import { DataState, ReducerActionWithPayload } from 'App.types';
import { createReducer } from 'helpers';

export type State = {
    [channelId: number]: DataState<youtube.Channel | null>;
};

export type Actions =
    | ReducerActionWithPayload<'loadYoutubeChannels', number[]>
    | ReducerActionWithPayload<'youtubeChannelsErrorLoading', { ids: number[]; error: string }>
    | ReducerActionWithPayload<'youtubeChannelsLoaded', { ids: number[]; channels: youtube.Channel[] }>;

export const initialState: State = {};

export const reducer = createReducer<State, Actions>({
    loadYoutubeChannels: (state, { payload: channelIds }) => {
        const loadingChannels = channelIds.reduce((acc, channelId) => {
            acc[channelId] = { data: null, error: null, status: 'loading' };
            return acc;
        }, {} as State);

        return {
            ...state,
            ...loadingChannels,
        };
    },
    youtubeChannelsErrorLoading: (state, { payload: { ids, error } }) => {
        const errorChannels = ids.reduce((acc, id) => {
            acc[id] = { data: null, error, status: 'error' };
            return acc;
        }, {} as State);

        return { ...state, ...errorChannels };
    },
    youtubeChannelsLoaded: (state, { payload: { ids, channels } }) => {
        const successChannels = ids.reduce((acc, id) => {
            acc[id] = { data: channels.find((c) => c.id === id) ?? null, error: null, status: 'success' };
            return acc;
        }, {} as State);

        return {
            ...state,
            ...successChannels,
        };
    },
});
