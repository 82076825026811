import { ReportContext } from '../../ReportContext';
import { useNonNullContextSelector } from 'Hooks/useNonNullContextSelector';
import { useCallback } from 'react';
import { PostIdData } from 'Modules/Plans/Posts/types';
import { youtube } from '@round/api';
import { getIdsFromPostIdData } from 'Modules/Plans/Posts/helpers';

export function useYoutubePostStats() {
    const state = useNonNullContextSelector(ReportContext, ([values]) => values.youtubePostStats);
    const dispatch = useNonNullContextSelector(ReportContext, ([, dispatch]) => dispatch);

    const fetchData = useCallback(
        async (idData: PostIdData[], requestInit?: RequestInit) => {
            const { postIds, contentIds } = getIdsFromPostIdData(idData);

            if (!postIds.length || !contentIds.length) {
                dispatch({ type: 'youtubePostStatsInitialized' });
                return;
            }

            try {
                dispatch({ type: 'youtubePostStatsLoading', payload: { postIds } });

                const response = await youtube.getYoutubeVideos(
                    {
                        id: contentIds.join(','),
                        page_size: contentIds.length,
                    },
                    requestInit
                );

                if (response.status !== 200) {
                    dispatch({
                        type: 'youtubePostStatsError',
                        payload: { postIds, error: `Couldn't fetch Youtube post stats` },
                    });

                    return;
                }

                dispatch({
                    type: 'youtubePostStatsSuccess',
                    payload: {
                        idData,
                        stats: response.data.results,
                    },
                });
            } catch (error) {
                if (error instanceof Error && error.name === 'AbortError') {
                    dispatch({ type: 'youtubePostStatsIdle', payload: { postIds } });
                    return;
                }

                dispatch({
                    type: 'youtubePostStatsError',
                    payload: { postIds, error: `Couldn't fetch Youtube post stats` },
                });
            }
        },
        [dispatch]
    );

    return {
        data: state.data,
        fetchData,
    };
}
