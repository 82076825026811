import { creatorbase } from '@round/api';
import { getTableMetaHelper, Skeleton } from '@round/ui-kit';
import { HeaderContext } from '@tanstack/react-table';
import { formatPostStatsCellValue } from 'Modules/Plans/Posts/helpers';
import { BasePostTableMeta } from 'Modules/Plans/Posts/types';

type Row = creatorbase.PublicPost;

type Context<T extends Row> = HeaderContext<T, number | null | undefined>;

const getTableMeta = getTableMetaHelper<BasePostTableMeta<Row>>();

/**
 * [!] This component requires a valid accessorFn or accessorKey to be declared on the columnDef.
 */
const PostStatsFooterTotalCell = <T extends Row>({ table, column }: Context<T>) => {
    const { isLoading, getAreStatsLoading } = getTableMeta(table);
    const areStatsLoading = table.getRowModel().rows.some((row) => getAreStatsLoading(row.original));

    if (isLoading || areStatsLoading) {
        return <Skeleton />;
    }

    const total = table.getRowModel().rows.reduce((acc, row) => {
        const value: number | undefined = row.getValue(column.id) || undefined;
        return typeof value === 'number' ? acc + value : acc;
    }, 0);

    return formatPostStatsCellValue(total);
};

export default PostStatsFooterTotalCell;
