import { call, encodeUrlSearchParams, fetchWithToken } from '../../helpers';
import {
    ApiResponse,
    ApiResponseError,
    ForbiddenResponse,
    NotFoundResponse,
    PaginatedApiResponseData,
    PaginatedRequest,
} from '../../types';
import { isPublicReportRequestInit, PublicReportRequestInit } from '../../influencer/utils';
import { PublicReport, Report } from './reports.types';

type GetReportByIdResponse = ApiResponse<Report, 200> | ForbiddenResponse | NotFoundResponse;

export async function getReport(id: string, requestInit?: RequestInit): Promise<GetReportByIdResponse> {
    const response = await fetchWithToken(`/api/creatorbase/reports/${id}/`, requestInit);

    if (response.status === 404 || response.status === 403) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not get report');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export type GetReportsParams = Partial<
    PaginatedRequest & {
        public_report_id: string;
        search: string;
    }
>;

type BaseGetReportsResponse<T extends Report | PublicReport> =
    | ApiResponse<PaginatedApiResponseData<T>, 200>
    | ForbiddenResponse
    | NotFoundResponse;

type GetReportsResponse = BaseGetReportsResponse<Report>;
type GetPublicReportsResponse = BaseGetReportsResponse<PublicReport>;

export async function getReports(
    params: GetReportsParams,
    requestInit: PublicReportRequestInit
): Promise<GetPublicReportsResponse>;
export async function getReports(params: GetReportsParams, requestInit?: RequestInit): Promise<GetReportsResponse>;
export async function getReports(
    params: GetReportsParams,
    requestInit?: RequestInit | PublicReportRequestInit
): Promise<GetPublicReportsResponse | GetReportsResponse> {
    const shouldBypassTokens = requestInit && isPublicReportRequestInit(requestInit);

    const response = await call(`/api/creatorbase/reports/${encodeUrlSearchParams(params)}`, requestInit, {
        shouldBypassTokens,
    });

    if (response.status === 403 || response.status === 404) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not get reports');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export type PatchReportData = Pick<
    Report,
    'name' | 'description' | 'project_ids' | 'assignee_ids' | 'currency_id' | 'team_id'
>;
type PatchReportResponse =
    | ApiResponse<Report, 200>
    | ApiResponse<ApiResponseError<PatchReportData>, 400>
    | NotFoundResponse
    | ForbiddenResponse;

export async function patchReport(reportId: string, data: Partial<PatchReportData>): Promise<PatchReportResponse> {
    const response = await fetchWithToken(`/api/creatorbase/reports/${reportId}/`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status === 404 || response.status === 403 || response.status === 400) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not update report');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export type PostReportData = Pick<
    Report,
    'name' | 'description' | 'team_id' | 'currency_id' | 'campaign_ids' | 'assignee_ids' | 'project_ids'
>;

type PostReportResponse =
    | ApiResponse<Report, 201>
    | ApiResponse<ApiResponseError<PostReportData>, 400>
    | ForbiddenResponse
    | NotFoundResponse;

export async function postReport(data: PostReportData): Promise<PostReportResponse> {
    const response = await fetchWithToken('/api/creatorbase/reports/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status === 404 || response.status === 403 || response.status === 400) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not create report');
    }

    return {
        status: 201,
        data: await response.json(),
    };
}
