import { creatorbase, InstagramPost, InstagramUserImage } from '@round/api';
import { Skeleton, TableProps, getTableMetaHelper } from '@round/ui-kit';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useInstagramReportCampaignPosts } from './useInstagramReportCampaignPosts';
import useAbortableEffect from 'Hooks/useAbortableEffect';
import moment from 'moment';
import { useReportCampaignStats } from '../../Campaigns/hooks/useReportCampaignStats';
import CostCell from 'Modules/Plans/components/CostCell/CostCell';
import TotalCostFooterCell from 'Modules/Plans/components/TotalCostFooterCell/TotalCostFooterCell';
import { CampaignTableRow } from '../../Campaigns/CampaignsTable/ReportCampaignsTable';
import NotesCell from 'Modules/Plans/components/NotesCell/NotesCell';
import PostsTable from 'Modules/Plans/Posts/components/PostsTable/PostsTable';
import PostsTableStatusCell, {
    StatusData,
} from 'Modules/Plans/Posts/components/PostsTable/cells/StatusCell/PostsTableStatusCell';
import { BasePostTableMeta } from 'Modules/Plans/Posts/types';
import PostStatsValueCell from 'Modules/Plans/Posts/components/PostsTable/cells/PostsStatsValueCell';
import PostStatsFooterTotalCell from 'Modules/Plans/Posts/components/PostsTable/cells/PostsTableStatFooterCell';
import PostStatsViewsFooterCell from 'Modules/Plans/Posts/components/PostsTable/cells/PostStatsViewsFooterCell';
import PostsTableAccountCell, {
    AccountData,
} from 'Modules/Plans/Posts/components/PostsTable/cells/PostsTableAccountCell/PostsTableAccountCell';

export type InstagramReportPostsTableRow = creatorbase.InstagramPost & {
    stats: InstagramPost | null;
    userImage: InstagramUserImage | null;
};

type Props = Pick<TableProps<InstagramReportPostsTableRow>, 'noDataLabel'> & {
    campaign: CampaignTableRow;
};

type TableCellContext<K extends keyof InstagramReportPostsTableRow> = CellContext<
    InstagramReportPostsTableRow,
    InstagramReportPostsTableRow[K]
>;

type Meta = BasePostTableMeta<InstagramReportPostsTableRow> & {
    campaign: CampaignTableRow;
};
const getTableMeta = getTableMetaHelper<Meta>();

const InstagramReportPostsTable = ({ campaign }: Props) => {
    const {
        postsData,
        postStatsData,
        userImagesData,
        fetchData,
        getAreStatsLoading,
        getIsAccountDataLoading,
    } = useInstagramReportCampaignPosts(campaign.id);
    const isInitialized = postsData?.status === 'success' || postsData?.status === 'error';

    useAbortableEffect(
        (signal) => {
            if (!isInitialized) {
                fetchData({ signal }).catch(() => {});
            }
        },
        [campaign.id, fetchData, isInitialized]
    );

    const { data: campaignStatsData, getAreCampaignStatsLoading } = useReportCampaignStats();

    const columns = useMemo<ColumnDef<InstagramReportPostsTableRow, any>[]>(
        () => [
            {
                header: 'ID',
                accessorKey: 'id',
                cell: ({ getValue, table }: TableCellContext<'id'>) => {
                    const { isLoading } = getTableMeta(table);

                    if (isLoading) {
                        return <Skeleton />;
                    }

                    return getValue().slice(-8);
                },
            },
            {
                header: 'Account',
                accessorFn: (row): AccountData => ({
                    image: row.userImage?.avatar_thumb.original_url ?? row.userImage?.avatar_thumb.cached_url ?? '',
                    title: row.stats?.owner_username || '-',
                    followerCount: row.stats?.owner_follower_count,
                }),
                id: 'account',
                cell: PostsTableAccountCell,
            },
            {
                header: 'Status',
                id: 'status',
                accessorFn: (original): StatusData => ({
                    postUrl: original.post_url,
                    platform: original.platform,
                    isReadOnly: true,
                }),
                cell: PostsTableStatusCell,
            },
            {
                header: 'Cost',
                accessorKey: 'cost',
                cell: ({ getValue, table }: TableCellContext<'cost'>) => {
                    const { isLoading, campaign } = getTableMeta(table);

                    if (isLoading) {
                        return <Skeleton />;
                    }
                    return <CostCell isReadonly cost={getValue()} currency={campaign?.currency ?? null} />;
                },
                footer: ({ table }) => {
                    const { isLoading, campaign } = getTableMeta(table);

                    if (isLoading) {
                        return <Skeleton />;
                    }

                    return (
                        <TotalCostFooterCell
                            costs={table.getRowModel().rows.map((r) => r.original.cost)}
                            currency={campaign.currency}
                        />
                    );
                },
            },
            {
                header: 'Upload date',
                accessorKey: 'stats',
                id: 'uploadedDate',
                cell: ({ getValue, row: { original }, table }: TableCellContext<'stats'>) => {
                    const { isLoading, getAreStatsLoading } = getTableMeta(table);

                    if (isLoading || getAreStatsLoading(original)) {
                        return <Skeleton />;
                    }

                    const uploadedAt = getValue()?.taken_at;

                    if (typeof uploadedAt !== 'string') {
                        return '-';
                    }

                    return moment(uploadedAt).format('DD MMM YYYY');
                },
            },
            {
                header: 'Views',
                id: 'views',
                accessorFn: (row) => row.stats?.play_count ?? row.stats?.view_count,
                cell: PostStatsValueCell,
                footer: PostStatsViewsFooterCell,
            },
            {
                header: 'Likes',
                id: 'likes',
                accessorFn: (row) => row.stats?.like_count,
                cell: PostStatsValueCell,
                footer: PostStatsFooterTotalCell,
            },
            {
                header: 'Story views',
                accessorFn: (original) => original.instagram_details?.story_view_count,
                cell: PostStatsValueCell,
                footer: PostStatsFooterTotalCell,
            },
            {
                header: 'Comments',
                accessorKey: 'stats',
                id: 'comments',
                accessorFn: (row) => row.stats?.comment_count,
                cell: PostStatsValueCell,
                footer: PostStatsFooterTotalCell,
            },
            {
                header: 'Notes',
                accessorKey: 'notes',
                cell: ({ getValue, table }: TableCellContext<'notes'>) => {
                    const { isLoading } = getTableMeta(table);

                    if (isLoading) {
                        return <Skeleton />;
                    }
                    return <NotesCell notes={getValue()} />;
                },
            },
        ],
        []
    );

    const meta: Meta = {
        isLoading: !isInitialized,
        getAreStatsLoading: (row) => getAreStatsLoading(row.id),
        getIsAccountDataLoading: (row) => getIsAccountDataLoading(row.instagram_details?.account_id),
        campaign,
        campaignStats: campaignStatsData[campaign.id]?.data ?? null,
        areCampaignStatsLoading: getAreCampaignStatsLoading(campaign.id),
    };

    const rows = useMemo(
        () =>
            postsData?.data?.map((post) => {
                const accountId = post.instagram_details?.account_id;
                return {
                    ...post,
                    stats: postStatsData[post.id]?.data ?? null,
                    userImage: (accountId && userImagesData[accountId]?.data) ?? null,
                } as InstagramReportPostsTableRow;
            }) ?? [],
        [postsData?.data, postStatsData, userImagesData]
    );

    return (
        <PostsTable
            columns={columns}
            data={rows}
            meta={meta}
            isLoading={!isInitialized}
            hasError={postsData?.status === 'error'}
        />
    );
};

export default InstagramReportPostsTable;
