import useAbortableEffect from 'Hooks/useAbortableEffect';
import useReportData from './useReportData';
import styles from './ReportData.module.css';
import { Image } from '@round/ui-kit';
import HeaderSummary from 'Modules/Plans/components/HeaderSummary/HeaderSummary';
import CircleBadge from 'Modules/Plans/components/CircleBadge/CircleBadge';
import { getUserNameInitials } from 'Modules/Plans/User/user.helpers';
import RunDates from 'Modules/Plans/components/RunDates/RunDates';
import moment from 'moment';
import { asMoney } from 'helpers';
import { OptionsContext } from 'contexts/OptionsContext/OptionsContext';
import useNonNullContext from 'Hooks/useNonNullContext';
import AutoGrowthInput from 'ui-new/whitelabel/AutoGrowthInput/AutoGrowthInput';
import { useState } from 'react';
import { creatorbase } from '@round/api';
import { showNotification } from 'helpers';
import { ReactComponent as SonyMusicLogo } from 'assets/brand/SonyMusic.svg';
import OverflowCircleBadgeContainer from 'Modules/Plans/components/OverflowCircleBadgeContainer/OverflowCircleBadgeContainer';
import cn from 'classnames';
import Button from 'ui-new/whitelabel/Button/Button';
import copy from 'copy-to-clipboard';
import { ReactComponent as CopyLinkIcon } from 'assets/whitelabel/CopyLink.svg';

type Props = {
    reportId: string | undefined;
    className?: string;
};

const ReportData = ({ reportId, className }: Props) => {
    const [reportName, setReportName] = useState('');
    const [reportDescription, setReportDescription] = useState('');
    const [isPublishReportLoading, setIsPublishReportLoading] = useState(false);

    const {
        fetchData,
        updateReport,
        createPublicReport,
        deletePublicReport,
        data,
        status,
        teams: { data: teams, status: teamsStatus },
        assignees: { data: assignees, status: assigneesStatus },
    } = useReportData();
    const isInitialized = status === 'success' || status === 'error';
    const areTeamsInitialized = teamsStatus === 'success' || teamsStatus === 'error';
    const areAssigneesInitialized = assigneesStatus === 'success' || assigneesStatus === 'error';
    const { currencies } = useNonNullContext(OptionsContext);

    const budget = asMoney(
        data?.total_cost,
        currencies.find((c) => c.id === data?.currency_id)
    );

    useAbortableEffect(
        (signal) => {
            if (!isInitialized && reportId) {
                fetchData(reportId, { signal })
                    .then((response) => {
                        if (response.status === 200) {
                            setReportName(response.data.name);
                            setReportDescription(response.data.description);
                        }
                    })
                    .catch(() => {});
            }
        },
        [fetchData, isInitialized, reportId]
    );

    const handleReportUpdate = async (payload: Partial<creatorbase.PatchReportData>) => {
        try {
            const response = await updateReport(payload);
            if (response?.status === 200) {
                setReportName(response.data.name);
                setReportDescription(response.data.description);
                showNotification('Report updated', 'info');
                return;
            }

            const errorMessage =
                response?.status === 400 ? Object.values(response.data).join('. ') : response?.data.detail;
            setReportName(data?.name || '-');
            setReportDescription(data?.description || '-');
            showNotification(errorMessage || 'Could not update report', 'error');
        } catch {
            setReportName(data?.name || '-');
            setReportDescription(data?.description || '-');
            showNotification('Could not update report', 'error');
        }
    };

    const handlePublishReport = async (payload: creatorbase.PostPublicReportData) => {
        setIsPublishReportLoading(true);
        try {
            const response = await createPublicReport(payload);

            if (response?.status === 201) {
                showNotification('success', 'info');
                return;
            }

            const errorMessage =
                response?.status === 400 ? Object.values(response.data).join('. ') : response?.data.detail;
            showNotification(errorMessage || 'Could not publish report', 'error');
        } catch {
            showNotification('Could not publish report', 'error');
        } finally {
            setIsPublishReportLoading(false);
        }
    };

    const handleUnpublishReport = async (publicReportId: string | null | undefined) => {
        setIsPublishReportLoading(true);

        if (!publicReportId) {
            showNotification('Could not unpublish report', 'error');
            setIsPublishReportLoading(false);
            return;
        }

        try {
            const response = await deletePublicReport(publicReportId);

            if (response?.status === 204) {
                showNotification('success', 'info');
                return;
            }

            const errorMessage =
                response?.status === 404 ? Object.values(response.data).join('. ') : response?.data.detail;
            showNotification(errorMessage || 'Could not publish report', 'error');
        } catch (error) {
            showNotification(error instanceof Error ? error.message : 'Could not publish report', 'error');
        } finally {
            setIsPublishReportLoading(false);
        }
    };

    return (
        <div className={cn(styles.container, className)}>
            <div className={styles.titleSection}>
                <div className={styles.titles}>
                    <AutoGrowthInput
                        containerClassName={styles.title}
                        isLoading={!isInitialized}
                        type="text"
                        value={reportName}
                        onChange={(e) => setReportName(e.target.value)}
                        onBlur={() => {
                            if (status !== 'success' || reportName === data.name) {
                                return;
                            }

                            handleReportUpdate({ name: reportName });
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                e.currentTarget.blur();
                            }
                        }}
                    />

                    <AutoGrowthInput
                        containerClassName={styles.description}
                        isLoading={!isInitialized}
                        type="text"
                        value={reportDescription}
                        onChange={(e) => setReportDescription(e.target.value)}
                        onBlur={() => {
                            if (status !== 'success' || reportDescription === data.description) {
                                return;
                            }

                            handleReportUpdate({ description: reportDescription });
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                e.currentTarget.blur();
                            }
                        }}
                    />
                </div>
                <div className={styles.publishActions}>
                    {isInitialized &&
                        (data?.public_report_id === null ? (
                            <Button
                                appearance="primary"
                                isLoading={isPublishReportLoading}
                                disabled={!isInitialized}
                                onClick={() => handlePublishReport({ report_id: data.id })}
                            >
                                Publish Report
                            </Button>
                        ) : (
                            <div className={styles.publishedState}>
                                <Button
                                    appearance="primary"
                                    className={styles.unpublishButton}
                                    isLoading={isPublishReportLoading}
                                    disabled={!isInitialized}
                                    onClick={() => handleUnpublishReport(data?.public_report_id)}
                                >
                                    Unpublish
                                </Button>
                                <Button
                                    iconLeft={<CopyLinkIcon />}
                                    className={styles.copyLinkButton}
                                    appearance="primary"
                                    disabled={isPublishReportLoading}
                                    onClick={() => {
                                        const currentDomain = window.location.origin;
                                        const path = `/c/public-reports/${data?.public_report_id}/`;
                                        const url = `${currentDomain}${path}`;
                                        const success = copy(url);
                                        if (success) {
                                            showNotification('Copied!', 'info');
                                        }
                                    }}
                                >
                                    Copy Link
                                </Button>
                            </div>
                        ))}
                </div>
            </div>

            <HeaderSummary>
                <HeaderSummary.Item>
                    <HeaderSummary.Label>Run Dates</HeaderSummary.Label>
                    <HeaderSummary.Value isLoading={!isInitialized}>
                        {data?.first_post_date && data?.last_post_date ? (
                            <RunDates
                                startDate={moment(data.first_post_date).format('DD MMM YYYY')}
                                endDate={moment(data.last_post_date).format('DD MMM YYYY')}
                            />
                        ) : (
                            '-'
                        )}
                    </HeaderSummary.Value>
                </HeaderSummary.Item>

                <HeaderSummary.Item>
                    <HeaderSummary.Label>Total budget</HeaderSummary.Label>
                    <HeaderSummary.Value isLoading={!isInitialized}>{budget}</HeaderSummary.Value>
                </HeaderSummary.Item>

                <HeaderSummary.Item>
                    <HeaderSummary.Label>Teams</HeaderSummary.Label>
                    <HeaderSummary.Value>
                        {teams?.length ? (
                            <OverflowCircleBadgeContainer
                                isLoading={!areTeamsInitialized}
                                className={styles.badgeContainer}
                                skeletonClassName={styles.badge}
                                overflowingCountBadgeClassName={styles.badge}
                            >
                                {teams?.map((t) => (
                                    <CircleBadge
                                        className={cn(styles.badge, styles.teamBadge)}
                                        key={t.id}
                                        tooltip={t.name}
                                    >
                                        <SonyMusicLogo className={styles.sonyLogo} />

                                        <Image skeletonCircle className={styles.flag} src={t.logo || ''} />
                                    </CircleBadge>
                                ))}
                            </OverflowCircleBadgeContainer>
                        ) : (
                            '-'
                        )}
                    </HeaderSummary.Value>
                </HeaderSummary.Item>

                <HeaderSummary.Item>
                    <HeaderSummary.Label>Account team</HeaderSummary.Label>
                    <HeaderSummary.Value className={styles.badgeContainer}>
                        {assignees?.length ? (
                            <OverflowCircleBadgeContainer
                                isLoading={!areAssigneesInitialized}
                                className={styles.badgeContainer}
                                skeletonClassName={styles.badge}
                                overflowingCountBadgeClassName={styles.badge}
                            >
                                {assignees?.map((assignee) => (
                                    <CircleBadge className={styles.badge} key={assignee.id} tooltip={assignee.name}>
                                        {getUserNameInitials(assignee.name)}
                                    </CircleBadge>
                                ))}
                            </OverflowCircleBadgeContainer>
                        ) : (
                            '-'
                        )}
                    </HeaderSummary.Value>
                </HeaderSummary.Item>
            </HeaderSummary>
        </div>
    );
};

export default ReportData;
