import { InstagramUserImage } from '@round/api';
import { DataState, ReducerActionWithPayload } from 'App.types';
import { createReducer } from 'helpers';

export type State = {
    [userId: number]: DataState<InstagramUserImage | null>;
};

export const initialState: State = {};

export type Actions =
    | ReducerActionWithPayload<'loadInstagramUserImages', number[]>
    | ReducerActionWithPayload<'instagramUserImagesLoaded', { ids: number[]; images: InstagramUserImage[] }>
    | ReducerActionWithPayload<'instagramUserImagesErrorLoading', { ids: number[]; error: string }>;

export const reducer = createReducer<State, Actions>({
    loadInstagramUserImages: (state, { payload: ids }) => {
        const loadingImages = ids.reduce((acc, id) => {
            acc[id] = { data: null, status: 'loading', error: null };
            return acc;
        }, {} as State);

        return {
            ...state,
            ...loadingImages,
        };
    },
    instagramUserImagesErrorLoading: (state, { payload: { ids, error } }) => {
        const errorImages = ids.reduce((acc, id) => {
            acc[id] = { data: null, status: 'error', error };
            return acc;
        }, {} as State);

        return {
            ...state,
            ...errorImages,
        };
    },
    instagramUserImagesLoaded: (state, { payload: { ids, images } }) => {
        const successImages = ids.reduce((acc, id) => {
            acc[id] = { data: images.find((i) => i.user_id === id) ?? null, status: 'success', error: null };
            return acc;
        }, {} as State);

        return {
            ...state,
            ...successImages,
        };
    },
});
