import { Context } from '@fluentui/react-context-selector';
import { creatorbase } from '@round/api';
import { DataState, ReducerAction, ReducerActionWithPayload } from 'App.types';
import { createReducer } from 'helpers';
import { ContextSelector, useNonNullContextSelector } from 'Hooks/useNonNullContextSelector';
import { useCallback } from 'react';
import { BaseDataHookContextValue } from 'utility/dataHook';
import { InfiniteScrollState, mapPaginatedApiData } from 'utility/api';

type ProjectUnion = creatorbase.Project | creatorbase.PublicProject;

export type State<T extends ProjectUnion> = DataState<InfiniteScrollState<T>>;

export const makeInitialState = <T extends ProjectUnion>() =>
    ({
        status: 'idle',
        data: null,
        error: null,
    } as State<T>);

export type Actions<T extends ProjectUnion> =
    | ReducerAction<'loadProjectsList'>
    | ReducerActionWithPayload<'errorLoadingProjectsList', { error: string }>
    | ReducerActionWithPayload<'successLoadingProjectsList', InfiniteScrollState<T>>;

export const makeProjectsListReducer = <T extends ProjectUnion>() => {
    return createReducer<State<T>, Actions<T>>({
        loadProjectsList: (state) => {
            return { ...state, status: 'loading', data: state?.data ?? null, error: null };
        },
        errorLoadingProjectsList: (state, { payload: { error } }) => {
            return { ...state, status: 'error', data: state?.data ?? null, error };
        },
        successLoadingProjectsList: (state, { payload }) => {
            return {
                ...state,
                status: 'success',
                error: null,
                data: {
                    ...payload,
                    data: (state?.data?.data ?? []).concat(payload.data),
                },
            };
        },
    });
};

export const makeProjectsListDataHook = <
    TValue extends BaseDataHookContextValue<Actions<TProject>>,
    TProject extends ProjectUnion
>(
    context: Context<TValue>,
    selector: ContextSelector<TValue, State<TProject>>
) => {
    return () => {
        const state = useNonNullContextSelector(context, selector);
        const dispatch = useNonNullContextSelector(context, ([, dispatch]) => dispatch);

        const fetchData = useCallback(
            async (params: creatorbase.GetProjectsParams, requestInit?: RequestInit) => {
                try {
                    dispatch({ type: 'loadProjectsList' });
                    const response = await creatorbase.getProjects(params, requestInit);

                    if (response.status === 200) {
                        dispatch({
                            type: 'successLoadingProjectsList',
                            payload: {
                                ...mapPaginatedApiData({
                                    ...response.data,
                                    results: response.data.results as TProject[],
                                }),
                                lastFetchedPage: params.page,
                            },
                        });

                        return response;
                    }

                    dispatch({
                        type: 'errorLoadingProjectsList',
                        payload: { error: response.data.detail },
                    });

                    return response;
                } catch (e) {
                    if (e instanceof Error && e.name === 'AbortError') {
                        throw e;
                    }

                    dispatch({
                        type: 'errorLoadingProjectsList',
                        payload: { error: 'Could not load projects' },
                    });
                    throw e;
                }
            },
            [dispatch]
        );

        return {
            state,
            fetchData,
        };
    };
};
