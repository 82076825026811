import { createContext, Dispatch, ReactNode, useCallback, useContext, useReducer } from 'react';
import { InstagramAccountsContextActions, InstagramAccountsContextState } from './reducer';
import reducer from './reducer';
import useNonNullContext from 'Hooks/useNonNullContext';
import { getInstagramUserImages } from '@round/api';

const initialState: InstagramAccountsContextState = {
    isInitialized: false,
    data: {},
};

const InstagramAccountsContext = createContext<
    [InstagramAccountsContextState, Dispatch<InstagramAccountsContextActions>] | null
>(null);

type Props = { children?: ReactNode };
export const InstagramAccountsProvider = ({ children }: Props) => {
    const state = useReducer(reducer, initialState);

    return <InstagramAccountsContext.Provider value={state}>{children}</InstagramAccountsContext.Provider>;
};

export function useInstagramAccountsData() {
    const [state, dispatch] = useNonNullContext(InstagramAccountsContext);
    const fetchData = useCallback(
        async (accountIds: number[], requestInit?: RequestInit) => {
            if (!accountIds.length) {
                dispatch({ type: 'setAccountsDataInitialized' });
                return;
            }

            try {
                dispatch({ type: 'setAccountsDataLoading', payload: { accountIds } });

                const response = await getInstagramUserImages(accountIds, requestInit);

                if (response.status === 200) {
                    dispatch({
                        type: 'setAccountsDataSuccess',
                        payload: {
                            accountIds,
                            userImages: response.data,
                        },
                    });

                    dispatch({ type: 'setAccountsDataInitialized' });
                    return;
                }

                dispatch({
                    type: 'setAccountsDataError',
                    payload: { accountIds, error: `Couldn't fetch instagram user image data` },
                });
            } catch (error) {
                if (error instanceof Error && error.name === 'AbortError') {
                    dispatch({ type: 'setAccountsDataIdle', payload: { accountIds } });
                    return;
                }

                dispatch({
                    type: 'setAccountsDataError',
                    payload: { accountIds, error: `Couldn't fetch instagram user image data` },
                });
            }
        },
        [dispatch]
    );

    return {
        data: state.data,
        isInitialized: state.isInitialized,
        fetchData,
    };
}

export function useInstagramAccountsDataActions() {
    const context = useContext(InstagramAccountsContext);

    const [, dispatch] = context ?? [];

    const fetchData = useCallback(
        async (accountIds: number[], requestInit?: RequestInit) => {
            if (!dispatch) {
                return;
            }

            try {
                dispatch({ type: 'setAccountsDataLoading', payload: { accountIds } });

                const response = await getInstagramUserImages(accountIds, requestInit);

                if (response.status === 200) {
                    dispatch({
                        type: 'setAccountsDataSuccess',
                        payload: {
                            accountIds,
                            userImages: response.data,
                        },
                    });

                    return;
                }

                dispatch({
                    type: 'setAccountsDataError',
                    payload: { accountIds, error: `Couldn't fetch instagram user image data` },
                });
            } catch (error) {
                if (error instanceof Error && error.name === 'AbortError') {
                    dispatch({ type: 'setAccountsDataIdle', payload: { accountIds } });
                    return;
                }

                dispatch({
                    type: 'setAccountsDataError',
                    payload: { accountIds, error: `Couldn't fetch instagram user image data` },
                });
            }
        },
        [dispatch]
    );

    return {
        fetchData,
    };
}
