import ClipboardTable from 'utility/copyToClipboard/ClipboardTable/ClipboardTable';
import { Column } from 'react-table';
import { InvoiceRequestsTableRow } from 'Modules/Finance/InvoiceRequests/InvoiceRequestsTable/InvoiceRequestsTable';
import moment from 'moment';
import { Currency } from '@round/api';
import { asMoney } from 'helpers';
import { invoiceRequestStatusOptions } from 'Modules/Finance/InvoiceRequests/helpers';
import { ReactComponent as InstagramIcon } from 'assets/icons/platform/InstagramSolid.svg';
import { ReactComponent as TikTokIcon } from 'assets/icons/platform/TikTokSolid.svg';
import { ReactComponent as YoutubeIcon } from 'assets/icons/platform/YoutubeSolid.svg';
import React from 'react';

type Props = {
    data: InvoiceRequestsTableRow[];
    currencies: Currency[];
};

const ClipboardCopyTable = ({ data, currencies }: Props) => {
    const columns: Column<InvoiceRequestsTableRow>[] = [
        {
            accessor: 'release',
            Header: 'Campaign',
            Cell: ({ value, row: { original } }) => {
                if (!value) {
                    return <>-</>;
                }

                const platform = original.tiktok_group_id
                    ? '#tiktok'
                    : original.instagram_group_id
                    ? '#instagram'
                    : original.youtube_group_id
                    ? '#youtube'
                    : '';
                const campaignUrl = value ? `${window.location.origin}/campaigns/${value.id}/creators/${platform}` : '';

                return (
                    <div
                        style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '1rem' }}
                    >
                        <ClipboardTable.Link href={campaignUrl}>{value.name}</ClipboardTable.Link>
                        {original.instagram_group_id && <InstagramIcon />}
                        {original.tiktok_group_id && <TikTokIcon />}
                        {original.youtube_group_id && <YoutubeIcon />}
                    </div>
                );
            },
        },
        {
            accessor: 'group_name',
            Header: 'Group Name',
            Cell: ({ value }) => <>{value}</>,
        },
        {
            accessor: 'plan_start_date',
            Header: 'Plan Start Date',
            Cell: ({ value }) => <>{moment(value).format('DD MMM YYYY')}</>,
        },
        {
            accessor: 'group_budget',
            Header: 'Group Budget',
            Cell: ({ value, row: { original } }) => {
                return (
                    <>
                        {asMoney(
                            Number(value),
                            currencies.find((c) => c.id === original.currency_id)
                        )}
                    </>
                );
            },
        },
        {
            accessor: 'notes',
            Header: 'Notes',
            Cell: ({ value }) => <>{value || '-'}</>,
        },
        {
            accessor: 'po_number',
            Header: 'PO Number',
            Cell: ({ value }) => <>{value || '-'}</>,
        },
        {
            accessor: 'invoice',
            Header: 'Invoice Number',
            Cell: ({ value }) => <>{value?.invoice_number ?? '-'}</>,
        },
        {
            accessor: 'contact',
            Header: 'Uniport Contact',
            Cell: ({ value }) => <>{value || '-'}</>,
        },
        {
            accessor: 'status',
            Header: 'Status',
            Cell: ({ value }) => <>{invoiceRequestStatusOptions.find((o) => o.value === value)?.label ?? '-'}</>,
        },
    ];

    return <ClipboardTable columns={columns} data={data} />;
};

export default ClipboardCopyTable;
