import { createBatchedParamsArrayFromIds, getTiktokVideoStats, getTiktokVideos } from '@round/api';
import useNonNullContext from 'Hooks/useNonNullContext';
import { Dispatch, ReactNode, createContext, useCallback, useContext, useReducer } from 'react';
import {
    reducer,
    initialState,
    State as TiktokPostStatsByPostIdState,
    Actions as TiktokPostStatsByPostIdActions,
} from 'Modules/Plans/Posts/reducers/tiktokPostStatsByPostId';
import { PostIdData } from 'Modules/Plans/Posts/types';
import { getIdsFromPostIdData } from 'Modules/Plans/Posts/helpers';

const TiktokPostStatsContext = createContext<
    [TiktokPostStatsByPostIdState, Dispatch<TiktokPostStatsByPostIdActions>] | null
>(null);

type Props = { children?: ReactNode };
export const TiktokPostStatsProvider = ({ children }: Props) => {
    const state = useReducer(reducer, initialState);

    return <TiktokPostStatsContext.Provider value={state}>{children}</TiktokPostStatsContext.Provider>;
};

export function useTiktokPostStats() {
    const [state, dispatch] = useNonNullContext(TiktokPostStatsContext);

    const fetchData = useCallback(
        async (idData: PostIdData[], requestInit?: RequestInit) => {
            if (!idData.length) {
                dispatch({ type: 'tiktokPostStatsInitialized' });
                return;
            }

            const { postIds, contentIds } = getIdsFromPostIdData(idData);

            try {
                dispatch({ type: 'tiktokPostStatsLoading', payload: { postIds } });

                // Since we aren't paginating yet, we need to batch the requests to avoid exceeding the URL length limit
                const batchedStatsParams = createBatchedParamsArrayFromIds(contentIds, (ids) => ({
                    video_ids: ids.join(','),
                    latest: true,
                }));

                const batchedVideosParams = createBatchedParamsArrayFromIds(contentIds, (ids) => ({
                    id: ids.join(','),
                }));

                const [statsResult, videosResult] = await Promise.allSettled([
                    Promise.all(
                        batchedStatsParams.map((params) =>
                            getTiktokVideoStats(params, requestInit).then((res) =>
                                res.status === 200 ? res.data.results : []
                            )
                        )
                    ),
                    Promise.all(
                        batchedVideosParams.map((params) =>
                            getTiktokVideos(params, requestInit).then((res) =>
                                res.status === 200 ? res.data.results : []
                            )
                        )
                    ),
                ]);

                dispatch({
                    type: 'tiktokPostStatsSuccess',
                    payload: {
                        idData,
                        stats: statsResult.status === 'fulfilled' ? statsResult.value.flat() : [],
                        videos: videosResult.status === 'fulfilled' ? videosResult.value.flat() : [],
                    },
                });

                dispatch({ type: 'tiktokPostStatsInitialized' });
            } catch (error) {
                if (error instanceof Error && error.name === 'AbortError') {
                    dispatch({ type: 'tiktokPostStatsIdle', payload: { postIds } });
                    return;
                }

                dispatch({
                    type: 'tiktokPostStatsError',
                    payload: { postIds, error: `Couldn't fetch tiktok post stats` },
                });
            }
        },
        [dispatch]
    );

    return {
        data: state.data,
        isInitialized: state.isInitialized,
        fetchData,
    };
}

export function useTiktokPostStatsActions() {
    const context = useContext(TiktokPostStatsContext);

    const [, dispatch] = context ?? [];

    const fetchData = useCallback(
        async (idData: PostIdData[], requestInit?: RequestInit) => {
            if (!dispatch) {
                return;
            }

            const { postIds, contentIds } = getIdsFromPostIdData(idData);

            try {
                dispatch({ type: 'tiktokPostStatsLoading', payload: { postIds } });

                // Since we aren't paginating yet, we need to batch the requests to avoid exceeding the URL length limit
                const batchedStatsParams = createBatchedParamsArrayFromIds(contentIds, (ids) => ({
                    video_ids: ids.join(','),
                    latest: true,
                }));

                const batchedVideosParams = createBatchedParamsArrayFromIds(contentIds, (ids) => ({
                    id: ids.join(','),
                }));

                const [statsResult, videosResult] = await Promise.allSettled([
                    Promise.all(
                        batchedStatsParams.map((params) =>
                            getTiktokVideoStats(params, requestInit).then((res) =>
                                res.status === 200 ? res.data.results : []
                            )
                        )
                    ),
                    Promise.all(
                        batchedVideosParams.map((params) =>
                            getTiktokVideos(params, requestInit).then((res) =>
                                res.status === 200 ? res.data.results : []
                            )
                        )
                    ),
                ]);

                dispatch({
                    type: 'tiktokPostStatsSuccess',
                    payload: {
                        idData,
                        stats: statsResult.status === 'fulfilled' ? statsResult.value.flat() : [],
                        videos: videosResult.status === 'fulfilled' ? videosResult.value.flat() : [],
                    },
                });
            } catch (error) {
                if (error instanceof Error && error.name === 'AbortError') {
                    dispatch({ type: 'tiktokPostStatsIdle', payload: { postIds } });
                    return;
                }

                dispatch({
                    type: 'tiktokPostStatsError',
                    payload: { postIds, error: `Couldn't fetch tiktok post stats` },
                });
            }
        },
        [dispatch]
    );

    const removeStats = useCallback(
        (postIds: string[]) => {
            if (!dispatch) {
                return;
            }

            dispatch({ type: 'removeTiktokPostStats', payload: postIds });
        },
        [dispatch]
    );

    return {
        fetchData,
        removeStats,
    };
}
