import { creatorbase, music } from '@round/api';
import { Image, Skeleton, SkeletonizedValue, TableProps, getTableMetaHelper } from '@round/ui-kit';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import Table from 'ui-new/whitelabel/Table/Table';
import styles from './SongsTable.module.css';
import Badge from 'ui-new/whitelabel/Badge/Badge';
import { capitalize, uniq } from 'lodash';
import { ReactComponent as InstagramIcon } from 'assets/whitelabel/platform/InstagramColor.svg';
import { ReactComponent as TikTokIcon } from 'assets/whitelabel/platform/TiktokColor.svg';
import { ReactComponent as EditIcon } from 'assets/whitelabel/Edit.svg';
import { ReactComponent as TrashIcon } from 'assets/whitelabel/Trash.svg';
import { numberWithCommas } from 'helpers';
import MiniLineChart from 'ui-new/whitelabel/Charts/MiniLineChart/MiniLineChart';
import { ReactComponent as SongIcon } from 'assets/whitelabel/Song.svg';
import Tooltip from 'ui-new/whitelabel/Tooltip/Tooltip';
import moment from 'moment';
import Dropdown from 'ui-new/whitelabel/Dropdown/Dropdown';

export type SongsTableRow = creatorbase.Song & {
    musicSong: music.Song | undefined;
    timeSeries: creatorbase.TimeSeriesResponse<'tiktok_daily_change'>[0] | null;
};

type Props = Pick<TableProps<SongsTableRow>, 'data' | 'onRowClick'> & {
    isLoading?: boolean;
    hasError?: boolean;
    onEditSong: (song: SongsTableRow) => void;
    onDeleteSong: (song: SongsTableRow) => void;
};

type TableCellContext<K extends keyof SongsTableRow> = CellContext<SongsTableRow, SongsTableRow[K]>;
type Meta = Pick<Props, 'isLoading' | 'onEditSong' | 'onDeleteSong'>;
const getTableMeta = getTableMetaHelper<Meta>();

const SongsTable = ({ data, isLoading, hasError, onRowClick, onEditSong, onDeleteSong }: Props) => {
    const columns: ColumnDef<SongsTableRow, any>[] = [
        {
            header: 'Song',
            id: 'song',
            cell: ({ row: { original }, table }) => {
                const { isLoading } = getTableMeta(table);

                return (
                    <div className={styles.songContainer}>
                        <Image
                            skeletonCircle={false}
                            className={styles.image}
                            loading={isLoading}
                            src={original.musicSong?.image ?? ''}
                            alt={original.title}
                        />

                        <div>
                            <p className={styles.songTitle}>
                                <SkeletonizedValue width="10rem" isInitialized={!isLoading}>
                                    {original.musicSong?.title || original.title}
                                </SkeletonizedValue>
                            </p>
                            <p className={styles.subValue}>
                                <SkeletonizedValue width="7rem" isInitialized={!isLoading}>
                                    {original.musicSong?.artists.map((a) => a.name).join(', ') || original.artist_name}
                                </SkeletonizedValue>
                            </p>
                        </div>
                    </div>
                );
            },
        },
        {
            header: 'Status',
            accessorKey: 'status',
            cell: ({ getValue, table }: TableCellContext<'status'>) => {
                const { isLoading } = getTableMeta(table);

                if (isLoading) {
                    return <Skeleton />;
                }

                return <Badge className={styles[getValue()]}>{capitalize(getValue())}</Badge>;
            },
        },
        {
            header: 'Audios',
            id: 'audios',
            meta: {
                className: styles.audiosHeader,
            },
            columns: [
                {
                    header: () => <InstagramIcon className={styles.platformHeaderIcon} />,
                    accessorKey: 'instagram_audios',
                    meta: {
                        className: styles.audiosCell,
                    },
                    cell: ({ getValue, table }: TableCellContext<'instagram_audios'>) => {
                        const { isLoading } = getTableMeta(table);

                        if (isLoading) {
                            return <Skeleton />;
                        }

                        return numberWithCommas(getValue().length);
                    },
                },
                {
                    header: () => <TikTokIcon className={styles.platformHeaderIcon} />,
                    accessorKey: 'tiktok_audios',
                    meta: {
                        className: styles.audiosCell,
                    },
                    cell: ({ getValue, row: { original }, table }: TableCellContext<'tiktok_audios'>) => {
                        const { isLoading } = getTableMeta(table);

                        if (isLoading) {
                            return <Skeleton />;
                        }

                        const audios = uniq((original.musicSong?.tiktok_audios ?? []).concat(getValue()));
                        return numberWithCommas(audios.length);
                    },
                },
            ],
        },
        {
            header: '',
            id: 'dailyChange',
            accessorKey: 'timeSeries',
            meta: {
                className: styles.dailyChange,
            },
            cell: ({ getValue, table }: TableCellContext<'timeSeries'>) => {
                const { isLoading } = getTableMeta(table);
                if (isLoading) {
                    return <Skeleton />;
                }

                const series = getValue()?.['tiktok_daily_change'] ?? [];
                return (
                    <div className={styles.dailyChangeContainer}>
                        <SongIcon className={styles.songIcon} />

                        <div className={styles.chartContainer}>
                            <MiniLineChart
                                flatLineOnEmpty
                                lineColor="#2A94FF"
                                points={series.map((s) => ({ x: s.timestamp, y: s.value }))}
                                renderTooltip={(tooltipItem) => (
                                    <>
                                        <Tooltip.Title>{moment(tooltipItem.x).format('D MMM YYYY')}</Tooltip.Title>
                                        <Tooltip.Body>
                                            <dl className={styles.chartTooltipStats}>
                                                <div className={styles.chartTooltipStatsItem}>
                                                    <dt>TikTok growth</dt>
                                                    <dd>{numberWithCommas(tooltipItem.y)}</dd>
                                                </div>
                                            </dl>
                                        </Tooltip.Body>
                                    </>
                                )}
                            />
                        </div>
                    </div>
                );
            },
        },
        {
            header: '',
            id: 'actions',
            cell: ({ row: { original }, table }) => {
                const { isLoading, onEditSong } = getTableMeta(table);

                if (isLoading) {
                    return <Skeleton />;
                }

                return (
                    <div className={styles.actions} onClick={(e) => e.stopPropagation()}>
                        <Dropdown>
                            {({ setIsOpen }) => (
                                <>
                                    <Dropdown.Target />
                                    <Dropdown.Menu>
                                        <Dropdown.Item
                                            icon={EditIcon}
                                            onClick={() => {
                                                onEditSong(original);
                                                setIsOpen(false);
                                            }}
                                        >
                                            Edit song
                                        </Dropdown.Item>

                                        <Dropdown.Separator />

                                        <Dropdown.Item
                                            icon={() => <TrashIcon className={styles.deleteAction} />}
                                            onClick={() => {
                                                onDeleteSong(original);
                                                setIsOpen(false);
                                            }}
                                            className={styles.deleteAction}
                                        >
                                            Delete song
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </>
                            )}
                        </Dropdown>
                    </div>
                );
            },
        },
    ];

    const meta: Meta = {
        isLoading,
        onEditSong,
        onDeleteSong,
    };

    // skeletons are rendered in table cells,
    // so we need some rows to display skeletons on initial loading
    const initialLoading = !data.length && isLoading;
    const emptyRows = new Array(10).fill({}) as SongsTableRow[];
    const rows = initialLoading ? emptyRows : data;

    return (
        <Table
            className={styles.table}
            data={rows}
            columns={columns}
            meta={meta}
            noDataLabel={
                <div aria-label="no songs" className={styles.noDataContainer}>
                    {hasError ? "Couldn't fetch songs" : 'No songs found'}
                </div>
            }
            onRowClick={onRowClick}
        />
    );
};

export default SongsTable;
