import cn from 'classnames';
import styles from './RadioOptionCard.module.css';

type Props = {
    isSelected: boolean;
    onClick: () => void;
    label: string;
    subLabel?: string;
    isDisabled?: boolean;
    name: string;
};

const RadioOptionCard = ({ label, subLabel, isSelected, onClick, isDisabled, name }: Props) => {
    return (
        <button
            className={cn(styles.container, { [styles.checked]: isSelected, [styles.disabled]: isDisabled })}
            onClick={() => {
                if (!isDisabled) {
                    onClick();
                }
            }}
            type="button"
        >
            <div
                role="radio"
                aria-checked={isSelected}
                aria-labelledby={name}
                aria-disabled={isDisabled}
                className={cn(styles.radio, { [styles.checked]: isSelected })}
            />

            <div className={styles.labels}>
                <p className={styles.label}>{label}</p>
                {subLabel && <p className={styles.subLabel}>{subLabel}</p>}
            </div>
        </button>
    );
};

export default RadioOptionCard;
