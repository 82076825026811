import { PaginatedApiResponseData, creatorbase } from '@round/api';
import { DataState } from 'App.types';
import useNonNullContext from 'Hooks/useNonNullContext';
import { Dispatch, ReactNode, SetStateAction, createContext, useCallback, useContext, useState } from 'react';

type State = DataState<{
    project: creatorbase.Project;
    brand?: creatorbase.Brand | undefined;
    team?: creatorbase.Team | undefined;
    usersAssignedToCampaigns?: creatorbase.User[];
}>;

const initialState: State = {
    status: 'idle',
    data: null,
    error: null,
};

const ProjectContext = createContext<[State, Dispatch<SetStateAction<State>>] | null>(null);

type Props = { children?: ReactNode };
export const ProjectProvider = ({ children }: Props) => {
    const state = useState<State>(initialState);

    return <ProjectContext.Provider value={state}>{children}</ProjectContext.Provider>;
};

export function useProjectDetails() {
    const [state, setState] = useNonNullContext(ProjectContext);

    const fetchData = useCallback(
        async (projectId: number, requestInit?: RequestInit) => {
            setState({ status: 'loading', data: null, error: null });

            try {
                const response = await creatorbase.getProject(projectId, requestInit);
                if (response.status === 403 || response.status === 404) {
                    setState({ status: 'error', data: null, error: response.data.detail });
                    return response;
                }

                const results = await Promise.all([
                    creatorbase.getBrand(response.data.brand_id, requestInit),
                    creatorbase.getTeam(response.data.team_id, requestInit),
                    response.data.users_assigned_to_campaigns.length
                        ? creatorbase.getUsers(
                              {
                                  id: response.data.users_assigned_to_campaigns.toString(),
                                  page_size: response.data.users_assigned_to_campaigns.length,
                              },
                              requestInit
                          )
                        : Promise.resolve(),
                ]);

                const [brandResponse, teamResponse, usersResponse] = results;
                if (
                    results
                        .filter((r): r is Exclude<typeof results[number], void> => !!r)
                        .some((r) => [404, 403, 400].includes(r.status))
                ) {
                    setState({
                        status: 'error',
                        data: {
                            project: response.data,
                            brand: brandResponse.status === 200 ? brandResponse.data : undefined,
                            team: teamResponse.status === 200 ? teamResponse.data : undefined,
                            usersAssignedToCampaigns:
                                usersResponse?.status === 200
                                    ? (usersResponse.data as PaginatedApiResponseData<creatorbase.User>).results
                                    : undefined,
                        },
                        error: 'Could not get project data',
                    });

                    return response;
                }

                setState({
                    status: 'success',
                    data: {
                        project: response.data,
                        brand: brandResponse.data as creatorbase.Brand,
                        team: teamResponse.data as creatorbase.Team,
                        usersAssignedToCampaigns:
                            (usersResponse?.data as PaginatedApiResponseData<creatorbase.User>)?.results ?? [],
                    },
                    error: null,
                });
                return response;
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    throw e;
                }

                setState({ status: 'error', data: null, error: 'Could not get project data' });
                throw e;
            }
        },
        [setState]
    );

    const updateProject = useCallback(
        async (data: Partial<creatorbase.PatchProjectData>) => {
            if (!state.data?.project) {
                return;
            }

            const response = await creatorbase.patchProject(state.data.project.id, data);
            if (response.status === 200) {
                setState((prev) => ({
                    status: 'success',
                    data: {
                        ...prev.data,
                        project: response.data,
                    },
                    error: null,
                }));
            }

            return response;
        },
        [setState, state.data?.project]
    );

    return {
        ...state,
        fetchData,
        updateProject,
    };
}

export function useProjectActions() {
    const projectContext = useContext(ProjectContext);

    const [state, setState] = projectContext ?? [];

    const refreshProjectStats = useCallback(
        async (requestInit?: RequestInit) => {
            if (!setState || !state?.data?.project.id) {
                console.warn('ProjectContext is not provided');
                return;
            }

            setState({ status: 'loading', data: null, error: null });

            try {
                const response = await creatorbase.getProject(state.data.project.id, requestInit);
                if (response.status === 403 || response.status === 404) {
                    setState({ status: 'error', data: null, error: response.data.detail });
                    return response;
                }

                const results = await Promise.all([
                    creatorbase.getBrand(response.data.brand_id, requestInit),
                    creatorbase.getTeam(response.data.team_id, requestInit),
                    response.data.users_assigned_to_campaigns.length
                        ? creatorbase.getUsers(
                              {
                                  id: response.data.users_assigned_to_campaigns.toString(),
                                  page_size: response.data.users_assigned_to_campaigns.length,
                              },
                              requestInit
                          )
                        : Promise.resolve(),
                ]);

                const [brandResponse, teamResponse, usersResponse] = results;
                if (
                    results
                        .filter((r): r is Exclude<typeof results[number], void> => !!r)
                        .some((r) => [404, 403].includes(r.status))
                ) {
                    setState({
                        status: 'error',
                        data: {
                            project: response.data,
                            brand: brandResponse.status === 200 ? brandResponse.data : undefined,
                            team: teamResponse.status === 200 ? teamResponse.data : undefined,
                            usersAssignedToCampaigns:
                                usersResponse?.status === 200
                                    ? (usersResponse.data as PaginatedApiResponseData<creatorbase.User>).results
                                    : undefined,
                        },
                        error: 'Could not get project data',
                    });

                    return response;
                }

                setState({
                    status: 'success',
                    data: {
                        project: response.data,
                        brand: brandResponse.data as creatorbase.Brand,
                        team: teamResponse.data as creatorbase.Team,
                        usersAssignedToCampaigns:
                            (usersResponse?.data as PaginatedApiResponseData<creatorbase.User>)?.results ?? [],
                    },
                    error: null,
                });
                return response;
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    throw e;
                }

                setState({ status: 'error', data: null, error: 'Could not get project data' });
                throw e;
            }
        },
        [setState, state?.data?.project.id]
    );

    return {
        refreshProjectStats,
    };
}
