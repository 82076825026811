import { creatorbase } from '@round/api';
import { Form } from 'formik';
import { FormField } from 'ui-new/whitelabel/formik';
import * as yup from 'yup';
import styles from './PostForm.module.css';
import { CurrencyField } from 'ui-new/whitelabel/formik';

export type PostFormValues = Pick<creatorbase.PostPostData, 'identifier'> & {
    cost: number | null | undefined;
};

export const getValidationSchema = (isPostCostEditable: boolean) => {
    const schema = yup.object({ identifier: yup.string().required('Post field is required') });
    if (isPostCostEditable) {
        return schema.shape({ cost: yup.string().required('Cost is required') });
    }

    return schema;
};

type Props = {
    isPostCostEditable?: boolean;
    currency?: number;
};

const PostForm = ({ isPostCostEditable, currency }: Props) => {
    return (
        <Form className={styles.form}>
            <FormField name="identifier" label="Post" placeholder="Enter post URL" />
            {isPostCostEditable && (
                <CurrencyField
                    name="cost"
                    label="Cost"
                    placeholder="Enter cost"
                    currency={currency}
                    groupSeparator=","
                    decimalSeparator="."
                    decimalsLimit={2}
                />
            )}
        </Form>
    );
};

export default PostForm;
