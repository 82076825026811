import { Context } from '@fluentui/react-context-selector';
import { youtube } from '@round/api';
import { DataState, ReducerActionWithPayload } from 'App.types';
import { createReducer } from 'helpers';
import { ContextSelector, useNonNullContextSelector } from 'Hooks/useNonNullContextSelector';
import { useCallback } from 'react';
import { BaseDataHookContextValue } from 'utility/dataHook';
import { makeErrorStateSlice, makeLoadingStateSlice, makeSuccessStateSlice } from 'utility/dataState';

export type State = {
    [videoId: number]: DataState<youtube.YoutubeVideo | null> | undefined;
};

export const initialState: State = {};

export type Actions =
    | ReducerActionWithPayload<'loadYoutubeVideos', number[]>
    | ReducerActionWithPayload<'youtubeVideosSuccess', { videoIds: number[]; videos: youtube.YoutubeVideo[] }>
    | ReducerActionWithPayload<'youtubeVideosErrorLoading', { videoIds: number[]; error: string }>;

export const reducer = createReducer<State, Actions>({
    loadYoutubeVideos: (state, { payload: videoIds }) => {
        const loadingData = makeLoadingStateSlice<number, youtube.YoutubeVideo | null>(videoIds);

        return { ...state, ...loadingData };
    },
    youtubeVideosSuccess: (state, { payload: { videoIds, videos } }) => {
        const successData = makeSuccessStateSlice<number, youtube.YoutubeVideo | null>(
            videoIds,
            (id) => videos.find((v) => v.id === id) ?? null
        );

        return { ...state, ...successData };
    },
    youtubeVideosErrorLoading: (state, { payload: { videoIds, error } }) => {
        const errorData = makeErrorStateSlice<number, youtube.YoutubeVideo | null>(videoIds, error);

        return { ...state, ...errorData };
    },
});

export function makeYoutubeVideosDataHook<Value extends BaseDataHookContextValue<Actions>>(
    context: Context<Value>,
    selector: ContextSelector<Value, State>
) {
    return function useYoutubeVideos() {
        const state = useNonNullContextSelector(context, selector);
        const dispatch = useNonNullContextSelector(context, ([, dispatch]) => dispatch);

        const fetchData = useCallback(
            async (videoIds: number[], requestInit?: RequestInit) => {
                if (!videoIds.length) {
                    dispatch({ type: 'youtubeVideosSuccess', payload: { videoIds: [], videos: [] } });
                    return;
                }

                try {
                    dispatch({ type: 'loadYoutubeVideos', payload: videoIds });
                    const response = await youtube.getYoutubeVideos(
                        { id: videoIds.toString(), page_size: videoIds.length },
                        requestInit
                    );

                    if (response.status === 200) {
                        dispatch({
                            type: 'youtubeVideosSuccess',
                            payload: { videoIds, videos: response.data.results },
                        });
                        return response;
                    }

                    dispatch({
                        type: 'youtubeVideosErrorLoading',
                        payload: { videoIds, error: 'Could not load youtube videos' },
                    });
                    return response;
                } catch (e) {
                    if (e instanceof Error && e.name === 'AbortError') {
                        throw e;
                    }

                    dispatch({
                        type: 'youtubeVideosErrorLoading',
                        payload: { videoIds, error: 'Could not load youtube videos' },
                    });
                    throw e;
                }
            },
            [dispatch]
        );

        return {
            data: state,
            fetchData,
        };
    };
}
