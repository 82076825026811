import { creatorbase } from '@round/api';
import { DataState } from 'App.types';
import useNonNullContext from 'Hooks/useNonNullContext';
import { Dispatch, ReactNode, SetStateAction, createContext, useCallback, useState } from 'react';
import { useProjectActions, useProjectDetails } from './ProjectContext';
import { DistributiveOmit } from 'utility/utility.types';
import { useRefreshPlatformStats } from './PlatformStatsContext';
import { useCampaignStatsActions } from './CampaignStatsContext/CampaignsStatsContext';
import { usePostsActions } from './PostsContext';
import { useYoutubePostStatsActions } from './PostStatsContexts/YoutubePostStatsContext/YoutubePostStatsContext';
import { useTiktokPostStatsActions } from './PostStatsContexts/TiktokPostStatsContext/TiktokPostStatsContext';
import { useInstagramPostStatsActions } from './PostStatsContexts/InstagramPostStatsContext/InstagramPostStatsContext';
import { getCampaignAudioId } from './CampaignsContext.helpers';
import { useAudioTimeSeriesDataActions } from './AudioTimeSeriesContext/AudioTimeSeriesContext';
import moment from 'moment';
import { useTiktokAudiosActions } from './TiktokAudiosContext';

type State = DataState<creatorbase.Campaign[]>;
const initialState: State = {
    data: null,
    error: null,
    status: 'idle',
};

const today = moment();
const twoWeeksAgo = moment().subtract(14, 'days');

const CampaignsContext = createContext<[State, Dispatch<SetStateAction<State>>] | null>(null);

type Props = { children?: ReactNode };
export const CampaignsProvider = ({ children }: Props) => {
    const state = useState<State>(initialState);

    return <CampaignsContext.Provider value={state}>{children}</CampaignsContext.Provider>;
};

export type UseCampaigns = ReturnType<typeof useCampaigns>;

export function useCampaigns() {
    const [state, setState] = useNonNullContext(CampaignsContext);
    const { data: projectData } = useProjectDetails();

    const { refreshProjectStats } = useProjectActions();
    const refreshPlatformStats = useRefreshPlatformStats();
    const { refreshCampaignStats, removeCampaignStats } = useCampaignStatsActions();
    const { removeCampaignPosts } = usePostsActions();

    const { removeStats: removeTiktokPostStats } = useTiktokPostStatsActions();
    const { removeStats: removeInstagramPostStats } = useInstagramPostStatsActions();
    const { removeStats: removeYoutubePostStats } = useYoutubePostStatsActions();
    const { fetchData: fetchAudioTimeSeriesData } = useAudioTimeSeriesDataActions();
    const { fetchData: fetchTiktokAudios } = useTiktokAudiosActions();

    const getRemovePostStatsHandler = useCallback(
        (platform: 'instagram' | 'tiktok' | 'youtube') => {
            switch (platform) {
                case 'tiktok':
                    return removeTiktokPostStats;
                case 'instagram':
                    return removeInstagramPostStats;
                case 'youtube':
                    return removeYoutubePostStats;
            }
        },
        [removeInstagramPostStats, removeTiktokPostStats, removeYoutubePostStats]
    );

    // we're not using project data to fetch campaigns and expecting id
    // because we want to fetch campaigns in parallel with project data
    const fetchData = useCallback(
        async (projectId: number, requestInit?: RequestInit) => {
            try {
                setState((prev) => ({ data: prev.data, error: null, status: 'loading' }));
                const campaigns = await creatorbase.getAllCampaigns({ project_id: projectId }, requestInit);
                setState({
                    data: campaigns,
                    error: null,
                    status: 'success',
                });
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    setState((prev) => ({
                        data: prev.data,
                        error: null,
                        status: 'idle',
                    }));
                    throw e;
                }

                setState((prev) => ({
                    data: prev.data,
                    error: e instanceof Error ? e.message : 'Could not get campaigns',
                    status: 'error',
                }));
                throw e;
            }
        },
        [setState]
    );

    const createCampaign = useCallback(
        async (data: DistributiveOmit<creatorbase.PostCampaignBody, 'project_id'>) => {
            if (!projectData?.project.id) {
                return;
            }

            const response = await creatorbase.postCampaign({
                project_id: projectData.project.id,
                ...data,
            });

            if (response.status === 201) {
                const audioId = getCampaignAudioId(response.data);

                setState((prev) => ({
                    ...prev,
                    data: (prev.data ?? []).concat(response.data),
                }));
                refreshPlatformStats();
                refreshCampaignStats(response.data.id).catch(() => {});
                refreshProjectStats().catch(() => {});

                if (audioId && response.data.platform === 'tiktok') {
                    fetchAudioTimeSeriesData({
                        ids: [audioId],
                        start_date: twoWeeksAgo.format('YYYY-MM-DD'),
                        end_date: today.format('YYYY-MM-DD'),
                    });
                    fetchTiktokAudios([audioId]);
                }
            }

            return response;
        },
        [
            projectData?.project.id,
            setState,
            refreshPlatformStats,
            refreshCampaignStats,
            refreshProjectStats,
            fetchAudioTimeSeriesData,
            fetchTiktokAudios,
        ]
    );

    const updateCampaign = useCallback(
        async (campaignId: number, data: Partial<creatorbase.PatchCampaignBody>) => {
            const response = await creatorbase.patchCampaign(campaignId, data);

            if (response.status === 200) {
                setState((prev) => ({
                    ...prev,
                    data: (prev.data ?? []).map((existingCampaign) => {
                        return existingCampaign.id === response.data.id ? response.data : existingCampaign;
                    }),
                }));
                refreshPlatformStats();
                refreshCampaignStats(response.data.id).catch(() => {});
                refreshProjectStats().catch(() => {});
            }

            return response;
        },
        [setState, refreshPlatformStats, refreshCampaignStats, refreshProjectStats]
    );

    const deleteCampaign = useCallback(
        async (campaignId: number) => {
            const response = await creatorbase.deleteCampaign(campaignId);
            if (response.status === 204) {
                setState((prev) => ({
                    ...prev,
                    data: (prev.data ?? []).filter((c) => c.id !== campaignId),
                }));
                refreshPlatformStats();
                removeCampaignStats(campaignId);
                refreshProjectStats().catch(() => {});

                const removedPostIds = removeCampaignPosts(campaignId);

                const campaign = state.data?.find((c) => c.id === campaignId);

                if (campaign && removedPostIds?.length) {
                    const removePostStats = getRemovePostStatsHandler(campaign?.platform);
                    removePostStats(removedPostIds);
                }
            }

            return response;
        },
        [
            setState,
            refreshPlatformStats,
            removeCampaignStats,
            refreshProjectStats,
            removeCampaignPosts,
            state.data,
            getRemovePostStatsHandler,
        ]
    );

    const refetchCampaign = useCallback(
        async (campaignId: number, requestInit?: RequestInit) => {
            const response = await creatorbase.getCampaign(campaignId, requestInit);

            if (response.status === 200) {
                setState((prev) => ({
                    ...prev,
                    data: prev.data?.length
                        ? prev.data.map((c) => (c.id === response.data.id ? response.data : c))
                        : [response.data],
                }));
            }

            return response;
        },
        [setState]
    );

    return {
        ...state,
        fetchData,
        createCampaign,
        updateCampaign,
        deleteCampaign,
        refetchCampaign,
    };
}
