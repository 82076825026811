import { creatorbase, music } from '@round/api';
import { DataState } from 'App.types';
import omit from 'lodash/omit';
import moment from 'moment';
import { useCallback, useState } from 'react';
import { isNumber } from 'utility/utility';

type State = DataState<{
    songs: creatorbase.Song[];
    musicSongs: music.Song[];
    timeSeries: creatorbase.TimeSeriesResponse<'tiktok_daily_change'> | null;
}>;

const initialState: State = {
    data: null,
    error: null,
    status: 'idle',
};

const today = moment();
const twoWeeksAgo = moment().subtract(14, 'days');
export default function useSongsData() {
    const [state, setState] = useState<State>(initialState);

    const fetchData = useCallback(async (params: creatorbase.GetSongsParams, requestInit?: RequestInit) => {
        setState((prev) => ({
            status: 'loading',
            data: prev.data,
            error: null,
        }));

        try {
            const response = await creatorbase.getSongs(params, requestInit);
            if (response.status !== 200) {
                setState((prev) => ({
                    status: 'error',
                    data: prev.data,
                    error: 'Could not get songs',
                }));

                return response;
            }

            const musicSongIds = response.data.results.map((s) => s.music_song_id).filter(isNumber);
            const songIds = response.data.results.map((s) => s.id);
            const [musicSongs, timeSeriesResponse] = await Promise.all([
                musicSongIds.length
                    ? music
                          .getSongs(
                              { id: musicSongIds.toString(), page_size: musicSongIds.length, page: 1 },
                              requestInit
                          )
                          .then((response) => response.data.results)
                    : Promise.resolve([]),
                songIds.length
                    ? creatorbase.postTimeSeries({
                          type: 'song',
                          ids: songIds,
                          fields: ['tiktok_daily_change'],
                          start_date: twoWeeksAgo.format('YYYY-MM-DD'),
                          end_date: today.format('YYYY-MM-DD'),
                      })
                    : Promise.resolve(null),
            ]);

            setState((prev) => ({
                status: 'success',
                error: null,
                data: {
                    songs: (prev.data?.songs ?? []).concat(response.data.results),
                    musicSongs: (prev.data?.musicSongs ?? []).concat(musicSongs),
                    timeSeries:
                        !timeSeriesResponse || timeSeriesResponse?.status !== 200
                            ? prev.data?.timeSeries ?? null
                            : { ...prev.data?.timeSeries, ...timeSeriesResponse.data },
                },
            }));
        } catch (e) {
            if (e instanceof Error && e.name === 'AbortError') {
                setState((prev) => ({
                    status: 'idle',
                    data: prev.data,
                    error: null,
                }));
                return;
            }

            setState({
                status: 'error',
                data: null,
                error: 'Could not get songs',
            });
        }
    }, []);

    const deleteSong = useCallback(async (songId: creatorbase.Song['id']) => {
        const response = await creatorbase.deleteSong(songId);
        if (response.status === 204) {
            setState((prev) => ({
                status: 'success',
                error: null,
                data: {
                    songs: prev.data?.songs.filter((s) => s.id !== songId) ?? [],
                    musicSongs: prev.data?.musicSongs ?? [],
                    timeSeries: prev.data?.timeSeries ? omit(prev.data.timeSeries, [songId]) : null,
                },
            }));
        }
        return response;
    }, []);

    const reset = useCallback(() => setState(initialState), []);

    return {
        ...state,
        fetchData,
        deleteSong,
        reset,
    };
}
