import { creatorbase } from '@round/api';
import { Form } from 'formik';
import { FormField, SelectField } from 'ui-new/whitelabel/formik';
import * as yup from 'yup';
import styles from './ReportForm.module.css';
import useTeamSelect, { TeamOption } from 'Modules/Plans/Team/hooks/useTeamSelect';
import omit from 'lodash/omit';
import { GenericDropdownOption } from 'App.types';
import { useCurrencies } from 'contexts/OptionsContext/useCurrencies';
import { ValueType } from 'react-select';
import { buildUserSelectFetchOptions, useUsersMultiSelect } from '../../../User/hooks/useUsersSelect';
import { useMemo } from 'react';
import ExternalMultiValuesControl from 'ui-new/whitelabel/Select/components/ExternalMultiValuesControl/ExternalMultiValuesControl';
import StaticTextValue from 'ui-new/whitelabel/Select/components/StaticTextValue';
import None from 'ui-new/whitelabel/Select/components/None';

export type FormValues = Pick<creatorbase.PostReportData, 'description' | 'name'> & {
    team: TeamOption | null;
    currency: GenericDropdownOption<number> | null;
    assignees: ValueType<GenericDropdownOption<number>, true>;
};

export const validationSchema = yup.object({
    name: yup.string().required('Name is required'),
    description: yup.string().required('Description is required'),
    team: yup.object().nullable().required('Team is required'),
    currency: yup.object().nullable().required('Currency is required'),
});

const ReportForm = () => {
    const { options: currencyOptions } = useCurrencies();

    const { props: teamSelectProps } = useTeamSelect({ initOn: 'mount' });
    const { props: teamMembersProps } = useUsersMultiSelect({
        initOn: 'menuOpen',
        fetchOptions: useMemo(() => buildUserSelectFetchOptions({}), []),
    });

    return (
        <Form className={styles.form}>
            <FormField name="name" label="Name" placeholder="Enter a report name" />

            <FormField
                name="description"
                label="Description"
                placeholder="Enter a report description"
                component="textarea"
            />

            <SelectField
                {...omit(teamSelectProps, ['value', 'onChange'])}
                name="team"
                label="Team"
                placeholder="Select a team"
            />

            <SelectField name="currency" label="Currency" placeholder="Select a currency" options={currencyOptions} />

            <SelectField
                name="assignees"
                label="Assignees"
                isClearable={false}
                controlShouldRenderValue={false}
                {...omit(teamMembersProps, ['value', 'onChange'])}
                components={{
                    Control: ExternalMultiValuesControl,
                    Input: StaticTextValue,
                    MultiValue: None,
                    Placeholder: None,
                }}
            />
        </Form>
    );
};

export default ReportForm;
