import { Component, ErrorInfo, ReactNode } from 'react';
import { datadogRum } from '@datadog/browser-rum';

export type FallbackProps = {
    error: Error;
    reset: () => void;
};

export const mapUnknownError = (error: unknown, errorInfo?: ErrorInfo): Error => {
    const e = new Error();
    e.name = 'ReactRenderingError';
    e.message = error instanceof Error ? error.message : String(error);
    e.stack = errorInfo?.componentStack ?? undefined;
    e.cause = error;

    return e;
};

type Props = {
    fallback: (props: FallbackProps) => ReactNode;
    children: ReactNode;
};

type State = { hasError: true; error: Error } | { hasError: false; error: null };

class ErrorBoundary extends Component<Props, State> {
    state: State = {
        hasError: false,
        error: null,
    };

    static getDerivedStateFromError(error: unknown) {
        return { hasError: true, error: mapUnknownError(error) };
    }

    componentDidCatch(error: unknown, errorInfo: ErrorInfo): void {
        const e = mapUnknownError(error, errorInfo);

        if (process.env.NODE_ENV === 'production') {
            datadogRum.addError(e);
        } else {
            console.error(e.message, e);
        }
    }

    render() {
        if (this.state.hasError) {
            return this.props.fallback({
                error: this.state.error,
                reset: () => this.setState({ error: null, hasError: false }),
            });
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
