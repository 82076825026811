import { useNonNullContextSelector } from 'Hooks/useNonNullContextSelector';
import { PublicReportContext } from '../PublicReportContext';
import { useCallback } from 'react';
import { creatorbase, influencer } from '@round/api';
import { makeProjectDataHook } from 'Modules/Plans/Project/hooks/dataHooks/useProjectsDataHook';
import { omit } from 'lodash';
import { makeBrandsDataHook } from 'Modules/Plans/Brand/hooks/dataHooks/useBrandsDataHook';
import { makeTeamsDataHook } from 'Modules/Plans/Team/hooks/dataHooks/useTeamsDataHook';
import { getSuccessfulStateIds } from 'utility/dataHook';
import usePublicReportCampaignStats from './usePublicReportCampaignStats';

type Platform = 'instagram' | 'tiktok' | 'youtube';

const useProjects = makeProjectDataHook(PublicReportContext, ([values]) => values.projects);
const useBrands = makeBrandsDataHook(PublicReportContext, ([values]) => values.brands);
const useTeams = makeTeamsDataHook(PublicReportContext, ([values]) => values.teams);

export default function useCampaigns(platform: Platform) {
    const campaignsState = useNonNullContextSelector(PublicReportContext, ([values]) => values.campaigns[platform]);
    const dispatch = useNonNullContextSelector(PublicReportContext, ([, dispatch]) => dispatch);
    const { data: campaignStatsData, fetchData: fetchCampaignStats } = usePublicReportCampaignStats();
    const { data: projectsData, fetchData: fetchProjects } = useProjects();
    const { data: brandsData, fetchData: fetchBrands } = useBrands();
    const { data: teamsData, fetchData: fetchTeams } = useTeams();

    const fetchData = useCallback(
        async (
            params: Required<Pick<creatorbase.GetCampaignsParams, 'public_report_id' | 'platform'>>,
            requestInit?: RequestInit
        ) => {
            try {
                dispatch({ type: 'loadCampaigns', payload: params.platform });
                const publicReportRequestInit = influencer.addPublicReportIdToRequestInit(
                    params.public_report_id,
                    requestInit || {}
                );

                const response = await creatorbase.getCampaigns(
                    { ...params, page_size: 1000, ordering: 'team,id' },
                    publicReportRequestInit
                );
                if (response.status !== 200) {
                    dispatch({
                        type: 'errorLoadingCampaigns',
                        payload: { platform: params.platform, message: response.data.detail },
                    });
                    return response;
                }

                const campaignIds = response.data.results.map((c) => c.id);
                const existingProjectIds = getSuccessfulStateIds(projectsData).map(Number);
                const projectIds = response.data.results
                    .map((c) => c.project_id)
                    .filter((projectId) => !existingProjectIds.includes(projectId));

                Promise.allSettled([
                    fetchProjects(projectIds, omit(publicReportRequestInit, ['signal'])),
                    fetchCampaignStats(campaignIds, omit(publicReportRequestInit, ['signal'])),
                ])
                    .then(([projectsResult]) => {
                        if (projectsResult.status === 'rejected') {
                            return;
                        }

                        const response = projectsResult.value;

                        if (response?.status === 200) {
                            const existingBrandIds = getSuccessfulStateIds(brandsData).map(Number);
                            const brandIds = response.data.results
                                .map((p) => p.brand_id)
                                .filter((brandId) => !existingBrandIds.includes(brandId));

                            const existingTeamIds = getSuccessfulStateIds(teamsData).map(Number);
                            const teamIds = response.data.results
                                .map((p) => p.team_id)
                                .filter((teamId) => !existingTeamIds.includes(teamId));
                            Promise.allSettled([
                                fetchBrands(brandIds, omit(publicReportRequestInit, ['signal'])),
                                fetchTeams(teamIds, omit(publicReportRequestInit, ['signal'])),
                            ]).catch(() => {});
                        }
                    })
                    .catch(() => {});

                dispatch({
                    type: 'campaignsSuccess',
                    payload: {
                        platform: params.platform,
                        data: response.data,
                    },
                });
                dispatch({ type: 'campaignsInitialized', payload: params.platform });
                return response;
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    dispatch({ type: 'campaignsIdle', payload: params.platform });
                    throw e;
                }

                dispatch({
                    type: 'errorLoadingCampaigns',
                    payload: { platform: params.platform, message: 'Could not get campaigns' },
                });
                throw e;
            }
        },
        [brandsData, dispatch, fetchBrands, fetchCampaignStats, fetchProjects, fetchTeams, projectsData, teamsData]
    );

    return {
        campaignsData: campaignsState,
        campaignStatsData,
        projectsData,
        brandsData,
        teamsData,
        fetchData,
        isProjectLoading: (campaignId: number) => {
            const campaign = campaignsState.data?.results.find((c) => c.id === campaignId);
            return Boolean(campaign && projectsData[campaign.project_id]?.status === 'loading');
        },
        isTeamLoading: (campaignId: number) => {
            const projectId = campaignsState.data?.results.find((c) => c.id === campaignId)?.project_id;
            const project = typeof projectId === 'number' ? projectsData[projectId]?.data ?? null : null;
            return Boolean(project && teamsData[project.team_id]?.status === 'loading');
        },
        isBrandLoading: (campaignId: number) => {
            const projectId = campaignsState.data?.results.find((c) => c.id === campaignId)?.project_id;
            const project = typeof projectId === 'number' ? projectsData[projectId]?.data ?? null : null;
            return Boolean(project && brandsData[project.brand_id]?.status === 'loading');
        },
        isCampaignStatsLoading: (campaignId: number) => {
            return campaignStatsData[campaignId]?.status === 'loading';
        },
    };
}
