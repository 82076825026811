import { creatorbase, InstagramPost } from '@round/api';
import { Skeleton, TableProps, getTableMetaHelper } from '@round/ui-kit';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { useMemo } from 'react';
import useAbortableEffect from 'Hooks/useAbortableEffect';
import PostsTable from 'Modules/Plans/Posts/components/PostsTable/PostsTable';
import { CampaignTableRow } from '../../Campaigns/CampaignsTable/PublicReportCampaignTable';
import PostsTableStatusCell, {
    StatusData,
} from 'Modules/Plans/Posts/components/PostsTable/cells/StatusCell/PostsTableStatusCell';
import { BasePostTableMeta } from 'Modules/Plans/Posts/types';
import { usePublicReportInstagramPosts } from './hooks/usePublicReportInstagramPosts';
import PostStatsValueCell from 'Modules/Plans/Posts/components/PostsTable/cells/PostsStatsValueCell';
import PostStatsFooterTotalCell from 'Modules/Plans/Posts/components/PostsTable/cells/PostsTableStatFooterCell';
import usePublicReportCampaignStats from '../../Campaigns/usePublicReportCampaignStats';
import PostStatsViewsFooterCell from 'Modules/Plans/Posts/components/PostsTable/cells/PostStatsViewsFooterCell';
import PostsTableUploadedDateCell from 'Modules/Plans/Posts/components/PostsTable/cells/PostsTableUploadedDateCell';
import PostsTableAccountCell, {
    AccountData,
} from 'Modules/Plans/Posts/components/PostsTable/cells/PostsTableAccountCell/PostsTableAccountCell';

export type InstagramPostsTableRow = creatorbase.PublicInstagramPost & {
    postStats: InstagramPost | null;
    userImageUrl: string | undefined;
};

type Props = Pick<TableProps<InstagramPostsTableRow>, 'noDataLabel'> & {
    campaign: CampaignTableRow;
    publicReportId: string | undefined;
};

type TableCellContext<K extends keyof InstagramPostsTableRow> = CellContext<
    InstagramPostsTableRow,
    InstagramPostsTableRow[K]
>;

type Meta = BasePostTableMeta<InstagramPostsTableRow>;
const getTableMeta = getTableMetaHelper<Meta>();

const PublicReportInstagramPostsTable = ({ campaign, publicReportId }: Props) => {
    const {
        postsData,
        postStatsData,
        userImagesData,
        fetchData,
        getAreStatsLoading,
        getIsAccountDataLoading,
    } = usePublicReportInstagramPosts(campaign.id);
    const isInitialized = postsData?.status === 'success' || postsData?.status === 'error';

    const { data: campaignStats, getAreCampaignStatsLoading } = usePublicReportCampaignStats();

    useAbortableEffect(
        (signal) => {
            if (!isInitialized && publicReportId) {
                fetchData(publicReportId, { signal }).catch(() => {});
            }
        },
        [publicReportId, fetchData, isInitialized]
    );

    const columns = useMemo<ColumnDef<InstagramPostsTableRow, any>[]>(
        () => [
            {
                header: 'ID',
                accessorKey: 'id',
                cell: ({ getValue, table }: TableCellContext<'id'>) => {
                    const { isLoading } = getTableMeta(table);

                    if (isLoading) {
                        return <Skeleton />;
                    }

                    return getValue().slice(-8);
                },
            },
            {
                header: 'Account',
                accessorFn: (row): AccountData => ({
                    image: row.userImageUrl,
                    title: row.postStats?.owner_username || '-',
                    followerCount: row.postStats?.owner_follower_count,
                }),
                id: 'account',
                cell: PostsTableAccountCell,
            },
            {
                header: 'Status',
                id: 'status',
                accessorFn: (original): StatusData => ({
                    postUrl: original.post_url,
                    platform: original.platform,
                    isReadOnly: true,
                }),
                cell: PostsTableStatusCell,
            },
            {
                header: 'Uploaded date',
                accessorFn: (original) => original.postStats?.taken_at,
                cell: PostsTableUploadedDateCell,
            },
            {
                header: 'Views',
                accessorFn: (original) => original.postStats?.play_count ?? original.postStats?.view_count,
                cell: PostStatsValueCell,
                footer: PostStatsViewsFooterCell,
            },
            {
                header: 'Likes',
                accessorFn: (original) => original.postStats?.like_count,
                cell: PostStatsValueCell,
                footer: PostStatsFooterTotalCell,
            },
            {
                header: 'Story views',
                accessorFn: (original) => original.instagram_details?.story_view_count,
                cell: PostStatsValueCell,
                footer: PostStatsFooterTotalCell,
            },
            {
                header: 'Comments',
                accessorFn: (original) => original.postStats?.comment_count,
                cell: PostStatsValueCell,
                footer: PostStatsFooterTotalCell,
            },
        ],
        []
    );

    const meta: Meta = {
        isLoading: !isInitialized,
        getAreStatsLoading: (row: InstagramPostsTableRow) => getAreStatsLoading(row.instagram_details?.content_id),
        campaignStats: campaignStats[campaign.id]?.data ?? null,
        areCampaignStatsLoading: getAreCampaignStatsLoading(campaign.id),
        getIsAccountDataLoading: (row: InstagramPostsTableRow) =>
            getIsAccountDataLoading(row.instagram_details?.account_id),
    };

    const rows: InstagramPostsTableRow[] =
        //there is currently no platform field in public posts, so we'll use the instagram_details field for now
        (postsData?.data?.filter((p): p is creatorbase.PublicInstagramPost => p.instagram_details !== null) || []).map(
            (post) => {
                const postId = post.instagram_details?.content_id;
                const postStats = postId ? postStatsData[postId]?.data || null : null;
                const userId = post.instagram_details?.account_id;
                const userImage = userId ? userImagesData[userId]?.data || null : null;
                const userImageUrl = userImage?.avatar_thumb.cached_url || userImage?.avatar_thumb.original_url;

                return {
                    ...post,
                    postStats: postStats,
                    userImageUrl,
                };
            }
        );

    return (
        <PostsTable
            columns={columns}
            data={rows}
            meta={meta}
            isLoading={!isInitialized}
            hasError={postsData?.status === 'error'}
        />
    );
};

export default PublicReportInstagramPostsTable;
