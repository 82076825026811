import { useNonNullContextSelector, ContextSelector } from 'Hooks/useNonNullContextSelector';
import { Dispatch, useCallback } from 'react';
import { createBatchedParamsArrayFromIds, fetchTiktokUserStats, getTiktokUserImages, getTiktokUsers } from '@round/api';

import { TiktokUser, TiktokUserImage, TiktokUserStats } from '@round/api';
import { DataState, ReducerAction, ReducerActionWithPayload } from 'App.types';
import { createReducer } from 'helpers';
import { Context } from '@fluentui/react-context-selector';

type Data = {
    [accountId: number]:
        | DataState<{
              user: TiktokUser | null;
              stats: TiktokUserStats | null;
              image: TiktokUserImage | null;
          }>
        | undefined;
};

export type State = {
    isInitialized: boolean;
    data: Data;
};

export type Actions =
    | ReducerActionWithPayload<'setTiktokAccountsDataLoading', { accountIds: number[] }>
    | ReducerActionWithPayload<
          'setTiktokAccountsDataSuccess',
          { accountIds: number[]; users: TiktokUser[]; userStats: TiktokUserStats[]; userImages: TiktokUserImage[] }
      >
    | ReducerActionWithPayload<'setTiktokAccountsDataError', { accountIds: number[]; error: string }>
    | ReducerActionWithPayload<'setTiktokAccountsDataIdle', { accountIds: number[] }>
    | ReducerAction<'setTiktokAccountsDataInitialized'>;

export const initialState: State = {
    isInitialized: false,
    data: {},
};

export const reducer = createReducer<State, Actions>({
    setTiktokAccountsDataLoading: (state, { payload: { accountIds } }) => {
        const incomingAccountsDataAsLoading = accountIds.reduce((acc, accountId) => {
            acc[accountId] = { status: 'loading', data: null, error: null };
            return acc;
        }, {} as Data);

        return { ...state, data: { ...state.data, ...incomingAccountsDataAsLoading } };
    },
    setTiktokAccountsDataSuccess: (state, { payload: { accountIds, users, userStats, userImages } }) => {
        const incomingAccountsDataAsSuccess = accountIds.reduce((acc, accountId) => {
            const linkedUser = users.find((user) => user.id === accountId) || null;
            const linkedUserStats = userStats.find((stats) => stats.user_id === accountId) || null;
            const linkedUserImage = userImages.find((image) => image.user_id === accountId) || null;

            acc[accountId] = {
                status: 'success',
                data: {
                    user: linkedUser,
                    stats: linkedUserStats,
                    image: linkedUserImage,
                },
                error: null,
            };
            return acc;
        }, {} as Data);

        return { ...state, data: { ...state.data, ...incomingAccountsDataAsSuccess } };
    },
    setTiktokAccountsDataError: (state, { payload: { accountIds, error } }) => {
        const incomingAccountsDataAsError = accountIds.reduce((acc, accountId) => {
            acc[accountId] = { status: 'error', data: null, error };
            return acc;
        }, {} as Data);

        return { ...state, data: { ...state.data, ...incomingAccountsDataAsError } };
    },
    setTiktokAccountsDataIdle: (state, { payload: { accountIds } }) => {
        const incomingAccountsDataAsIdle = accountIds.reduce((acc, accountId) => {
            acc[accountId] = { status: 'idle', data: null, error: null };
            return acc;
        }, {} as Data);

        return { ...state, data: { ...state.data, ...incomingAccountsDataAsIdle } };
    },
    setTiktokAccountsDataInitialized: (state) => ({ ...state, isInitialized: true }),
});

export function makeTiktokAccountsDataHook<Value extends [any, Dispatch<Actions>] | null>(
    context: Context<Value>,
    selector: ContextSelector<Value, State>
) {
    return function () {
        const state = useNonNullContextSelector(context, selector);
        const dispatch = useNonNullContextSelector(context, ([, dispatch]) => dispatch);

        const fetchData = useCallback(
            async (accountIds: number[], requestInit?: RequestInit) => {
                if (!accountIds.length) {
                    dispatch({ type: 'setTiktokAccountsDataInitialized' });
                    return;
                }

                try {
                    dispatch({ type: 'setTiktokAccountsDataLoading', payload: { accountIds } });

                    // Since we aren't paginating yet, we need to batch the requests to avoid exceeding the URL length limit
                    const batchedUsersParams = createBatchedParamsArrayFromIds(accountIds, (ids) => ({
                        id: ids.toString(),
                    }));
                    const batchedStatsParams = createBatchedParamsArrayFromIds(accountIds, (ids) => ({
                        user_ids: ids,
                        latest: true,
                    }));
                    const batchedImagesParams = createBatchedParamsArrayFromIds(accountIds, (ids) => ({ ids }));

                    const [usersResponse, userStatsResponse, userImagesResponse] = await Promise.allSettled([
                        Promise.all(
                            batchedUsersParams.map((params) =>
                                getTiktokUsers(params, requestInit).then((res) => {
                                    if (res.status !== 200) {
                                        throw new Error('Could not get tiktok users');
                                    }
                                    return res.data.results;
                                })
                            )
                        ),
                        Promise.all(
                            batchedStatsParams.map((params) =>
                                fetchTiktokUserStats(params, requestInit).then((res) => {
                                    if (res.status !== 200) {
                                        throw new Error('Could not get tiktok user stats');
                                    }
                                    return res.data.results;
                                })
                            )
                        ),
                        Promise.all(
                            batchedImagesParams.map((params) =>
                                //user images fetch has non-standard params, so we'll just use the ids
                                getTiktokUserImages(params.ids, requestInit)
                            )
                        ),
                    ]);

                    if (usersResponse.status === 'fulfilled') {
                        dispatch({
                            type: 'setTiktokAccountsDataSuccess',
                            payload: {
                                accountIds,
                                users: usersResponse.value.flat(),
                                userStats:
                                    userStatsResponse.status === 'fulfilled' ? userStatsResponse.value.flat() : [],
                                userImages:
                                    userImagesResponse.status === 'fulfilled' ? userImagesResponse.value.flat() : [],
                            },
                        });

                        dispatch({ type: 'setTiktokAccountsDataInitialized' });
                        return;
                    }

                    dispatch({
                        type: 'setTiktokAccountsDataError',
                        payload: { accountIds, error: `Couldn't fetch tiktok account data` },
                    });
                } catch (error) {
                    if (error instanceof Error && error.name === 'AbortError') {
                        dispatch({ type: 'setTiktokAccountsDataIdle', payload: { accountIds } });
                        return;
                    }

                    dispatch({
                        type: 'setTiktokAccountsDataError',
                        payload: { accountIds, error: `Couldn't fetch tiktok account data` },
                    });
                }
            },
            [dispatch]
        );

        return {
            data: state,
            fetchData,
        };
    };
}
