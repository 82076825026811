import FullscreenModal from 'ui-new/whitelabel/FullscreenModal/FullscreenModal';
import Modal from 'ui-new/whitelabel/Modal/Modal';
import { useProjectSelect } from './useProjectSelect';
import useAbortableEffect from 'Hooks/useAbortableEffect';
import useReportData from '../ReportData/useReportData';
import ProjectSelectTable, { ProjectSelectTableRow } from './ProjectSelectTable/ProjectSelectTable';
import styles from './ProjectSelectModal.module.css';
import { useMemo } from 'react';
import { RowSelectionState } from '@tanstack/react-table';
import { useFormik } from 'formik';
import { useFeatureFlags } from 'utility/featureFlags/FeatureFlagsContext';
import Button from 'ui-new/whitelabel/Button/Button';
import { creatorbase } from '@round/api';
import { showNotification } from 'helpers';

type Props = {
    isOpen: boolean;
    onClose: () => void;
};

type FormValues = {
    project_ids: number[];
};

const ProjectSelectModal = ({ isOpen, onClose }: Props) => {
    const featureFlags = useFeatureFlags();

    const { data: reportData, status: reportStatus } = useReportData();
    const { projectsState, teamsData, brandsData, assigneesData, fetchData } = useProjectSelect();

    const isInitialized = projectsState.status === 'success' || projectsState.status === 'error';
    const hasError = projectsState.status === 'error' || reportStatus === 'error';

    useAbortableEffect(
        (signal) => {
            if (isInitialized || !reportData?.team_id || typeof projectsState.data?.lastFetchedPage === 'number') {
                return;
            }

            fetchData({ page: 1, page_size: 25, team_id: reportData.team_id }, { signal }).catch(() => {});
        },
        [fetchData, isInitialized, projectsState.data?.lastFetchedPage, reportData?.team_id]
    );

    const fetchNextPage = async () => {
        if (projectsState.status === 'success' && projectsState.data.hasNextPage && reportData?.team_id) {
            fetchData({
                page: (projectsState.data.lastFetchedPage ?? 0) + 1,
                page_size: 25,
                team_id: reportData.team_id,
            }).catch(() => {});
        }
    };

    const rows = useMemo<ProjectSelectTableRow[]>(() => {
        return (
            projectsState.data?.data.map((p) => {
                const team = teamsData[p.team_id]?.data ?? null;
                const brand = brandsData[p.brand_id]?.data ?? null;
                const users = Object.entries(assigneesData)
                    .filter(([userId]) => p.users_assigned_to_campaigns.includes(Number(userId)))
                    .map(([, data]) => data?.data)
                    .filter((data): data is Exclude<typeof data, undefined | null> => !!data);

                return { ...p, team, brand, users };
            }) ?? []
        );
    }, [assigneesData, brandsData, projectsState.data?.data, teamsData]);

    const formik = useFormik<FormValues>({
        initialValues: {
            project_ids: featureFlags.isEnabled('cb-edit-reports') ? reportData?.project_ids ?? [] : [],
        },
        onSubmit: async (values, helpers) => {
            if (!reportData?.id) {
                return;
            }

            try {
                helpers.setSubmitting(true);

                const response = await creatorbase.patchReport(reportData?.id, {
                    project_ids: values.project_ids,
                });

                if (response.status !== 200) {
                    showNotification("Couldn't save projects", 'error');
                    return;
                }

                showNotification('Projects saved', 'info');
                onClose();
            } finally {
                helpers.setSubmitting(false);
            }
        },
        enableReinitialize: true,
    });

    return (
        <FullscreenModal className={styles.modal} isOpen={isOpen} onClose={onClose} closeOnEscape closeOnOverlayClick>
            <Modal.Header>
                <Modal.Title>Edit projects</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <ProjectSelectTable
                    containerClassName={styles.table}
                    data={rows}
                    isLoading={!isInitialized}
                    hasError={hasError}
                    onEndReached={fetchNextPage}
                    rowSelection={formik.values.project_ids.reduce((acc, id) => {
                        acc[`${id}`] = true;
                        return acc;
                    }, {} as RowSelectionState)}
                    onRowSelectionChange={(selection) => {
                        formik.setFieldValue('project_ids', Object.keys(selection).map(Number) ?? []);
                    }}
                />
            </Modal.Body>
            <Modal.Actions>
                <Button
                    appearance="primary"
                    onClick={() => formik.handleSubmit()}
                    isLoading={formik.isSubmitting}
                    disabled={formik.isSubmitting || !formik.dirty}
                >
                    Save
                </Button>
            </Modal.Actions>
        </FullscreenModal>
    );
};

export default ProjectSelectModal;
