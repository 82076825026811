import { creatorbase } from '@round/api';
import { DataState, ReducerAction, ReducerActionWithPayload } from 'App.types';
import { createReducer } from 'helpers';

type TiktokAudiosByIdData = { [id: number]: DataState<creatorbase.TiktokAudio | null> };

type TiktokAudiosByIdState = {
    isInitialized: boolean;
    data: TiktokAudiosByIdData;
};

type TiktokAudiosByIdActions =
    | ReducerActionWithPayload<'tiktokAudiosIdle', { ids: number[] }>
    | ReducerActionWithPayload<'tiktokAudiosLoading', { ids: number[] }>
    | ReducerActionWithPayload<'tiktokAudiosSuccess', { ids: number[]; tiktokAudios: creatorbase.TiktokAudio[] }>
    | ReducerActionWithPayload<'tiktokAudiosError', { ids: number[]; error: string }>
    | ReducerAction<'tiktokAudiosInitialized'>;

export const initialState: TiktokAudiosByIdState = {
    isInitialized: false,
    data: {},
};

export const reducer = createReducer<TiktokAudiosByIdState, TiktokAudiosByIdActions>({
    tiktokAudiosLoading: (state, { payload: { ids } }) => {
        const incomingTiktokAudiosAsLoading = ids.reduce((acc, postId) => {
            acc[postId] = { status: 'loading', data: null, error: null };
            return acc;
        }, {} as TiktokAudiosByIdData);

        return { ...state, data: { ...state.data, ...incomingTiktokAudiosAsLoading } };
    },
    tiktokAudiosSuccess: (state, { payload: { ids, tiktokAudios } }) => {
        const incomingTiktokAudiosAsSuccess = ids.reduce((acc, id) => {
            const linkedAudio = tiktokAudios.find((audio) => audio.id === id) || null;

            acc[id] = {
                status: 'success',
                data: linkedAudio,
                error: null,
            };
            return acc;
        }, {} as TiktokAudiosByIdData);

        return { ...state, data: { ...state.data, ...incomingTiktokAudiosAsSuccess } };
    },
    tiktokAudiosError: (state, { payload: { ids, error } }) => {
        const incomingTiktokAudiosAsError = ids.reduce((acc, postId) => {
            acc[postId] = { status: 'error', data: null, error };
            return acc;
        }, {} as TiktokAudiosByIdData);

        return { ...state, data: { ...state.data, ...incomingTiktokAudiosAsError } };
    },
    tiktokAudiosIdle: (state, { payload: { ids } }) => {
        const incomingTiktokAudiosAsIdle = ids.reduce((acc, postId) => {
            acc[postId] = { status: 'idle', data: null, error: null };
            return acc;
        }, {} as TiktokAudiosByIdData);

        return { ...state, data: { ...state.data, ...incomingTiktokAudiosAsIdle } };
    },
    tiktokAudiosInitialized: (state) => ({ ...state, isInitialized: true }),
});
