import { creatorbase } from '@round/api';
import { DataState, ReducerAction, ReducerActionWithPayload } from 'App.types';
import { createReducer } from 'helpers';
import { omit } from 'lodash';

export type Data = { [campaignId: number]: DataState<creatorbase.AggregatedPostStats> | undefined };

export type State = {
    isInitialized: boolean;
    data: Data;
};

export const initialState: State = {
    isInitialized: false,
    data: {},
};

export type Actions =
    | ReducerActionWithPayload<'setStatsLoading', { campaignIds: number[] }>
    | ReducerActionWithPayload<'setStatsSuccess', { campaignId: number; data: creatorbase.AggregatedPostStats }>
    | ReducerActionWithPayload<'setStatsError', { campaignId: number; error: string }>
    | ReducerActionWithPayload<'setStatsIdle', { campaignId: number }>
    | ReducerAction<'setStatsInitialized'>
    | ReducerActionWithPayload<'removeCampaignStats', number>;

export const reducer = createReducer<State, Actions>({
    setStatsLoading: (state, { payload: { campaignIds } }) => {
        const incomingStatsAsLoading = campaignIds.reduce((acc, campaignId) => {
            acc[campaignId] = { status: 'loading', data: null, error: null };
            return acc;
        }, {} as Data);

        return { ...state, data: { ...state.data, ...incomingStatsAsLoading } };
    },
    setStatsSuccess: (state, { payload: { campaignId, data } }) => ({
        ...state,
        data: {
            ...state.data,
            [campaignId]: { status: 'success', data, error: null },
        },
    }),
    setStatsError: (state, { payload: { campaignId, error } }) => ({
        ...state,
        data: {
            ...state.data,
            [campaignId]: { status: 'error', data: null, error },
        },
    }),
    setStatsIdle: (state, { payload: { campaignId } }) => ({
        ...state,
        data: {
            ...state.data,
            [campaignId]: { status: 'idle', data: null, error: null },
        },
    }),
    setStatsInitialized: (state) => ({ ...state, isInitialized: true }),
    removeCampaignStats: (state, { payload }) => ({
        ...state,
        data: omit(state.data, payload),
    }),
});
