import useAbortableEffect from 'Hooks/useAbortableEffect';
import useReports from './useReports';
import styles from './ReportsList.module.css';
import { ScrollView } from '@round/ui-kit';
import ReportsTable, { ReportsTableRow } from './ReportsTable/ReportsTable';
import useUrlState from 'Hooks/useUrlState';
import SearchInput from 'ui-new/whitelabel/SearchInput/SearchInput';
import { useMemo, useState } from 'react';
import Button from 'ui-new/whitelabel/Button/Button';
import CreateReportModal from '../CreateReportModal/CreateReportModal';
import { useFeatureFlags } from 'utility/featureFlags/FeatureFlagsContext';
import { useNavigate } from 'react-router-dom';
import EditReportModal from '../EditReportModal/EditReportModal';
import { creatorbase } from '@round/api';

type UrlState = {
    search: string;
};

const ReportsList = () => {
    const featureFlags = useFeatureFlags();
    const navigate = useNavigate();
    const [urlState, setUrlState] = useUrlState<UrlState>();

    const [isCreateReportOpen, setIsCreateReportOpen] = useState(false);
    const [isUpdateReportOpen, setIsUpdateReportOpen] = useState(false);
    const [reportToUpdate, setReportToUpdate] = useState<creatorbase.Report | null>(null);

    const { data, status, fetchData, reset, createReport, updateReport } = useReports();
    const areReportsInitialized = status === 'success' || status === 'error';

    useAbortableEffect(
        (signal) => {
            if (!areReportsInitialized) {
                fetchData({ search: urlState.search }, { signal }).catch(() => {});
            }
        },
        [areReportsInitialized, fetchData, urlState.search]
    );

    const rows: ReportsTableRow[] = useMemo(() => {
        return (
            data?.reports.map((report) => {
                return { ...report, assignees: data.assignees.filter((a) => report.assignee_ids.includes(a.id)) };
            }) ?? []
        );
    }, [data?.assignees, data?.reports]);

    return (
        <>
            <div className={styles.container}>
                <header className={styles.header}>
                    <h1 className={styles.title}>Reports</h1>

                    {featureFlags.isEnabled('cb-create-reports') && (
                        <Button onClick={() => setIsCreateReportOpen(true)} appearance="primary">
                            Create Report
                        </Button>
                    )}
                </header>

                <SearchInput
                    className={styles.searchInput}
                    value={urlState.search}
                    onChange={(e) => {
                        setUrlState({ search: e.target.value });
                        reset();
                    }}
                />

                <ScrollView>
                    <ReportsTable
                        data={rows}
                        hasError={status === 'error'}
                        isLoading={!areReportsInitialized}
                        onEditReport={(row) => {
                            setReportToUpdate(row);
                            setIsUpdateReportOpen(true);
                        }}
                    />
                </ScrollView>
            </div>

            <CreateReportModal
                isOpen={isCreateReportOpen}
                onClose={() => {
                    setIsCreateReportOpen(false);
                }}
                createReport={createReport}
                onCreated={(report) => {
                    // isEdit opens a project select modal
                    navigate(`${report.id}/?isEdit=true`);
                }}
            />

            <EditReportModal
                isOpen={isUpdateReportOpen}
                onClose={() => {
                    setIsUpdateReportOpen(false);
                }}
                report={reportToUpdate}
                updateReport={updateReport}
            />
        </>
    );
};

export default ReportsList;
