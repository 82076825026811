import { Model, Response } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { Report } from './reports.types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';
import { AppServer, RouteHandlerContext } from '../../mirage';

export const models = {
    creatorbaseReport: Model as ModelDefinition<Report>,
};

export const serializers = {
    creatorbaseReport: makeSerializer<Report>([]),
};

export const factories = {
    creatorbaseReport: createFactory<Report>({
        assignee_ids: [],
        campaign_ids: [],
        created_at: new Date().toISOString(),
        currency_id: 1,
        description: '',
        first_post_date: new Date().toISOString(),
        last_post_date: new Date().toISOString(),
        name: (index: number) => `Name ${index}`,
        team_id: (index: number) => index,
        total_cost: 0,
        updated_at: new Date().toISOString(),
        platforms: [],
        public_report_id: null,
        teams_assigned_to_campaigns: [],
    }),
};

export function handleRequests(server: AppServer) {
    const reportsBaseUrl = '/api/creatorbase/reports/';
    server.get(`${reportsBaseUrl}:id/`, (schema, request) => {
        const report = schema.find('creatorbaseReport', request.params.id);
        if (!report) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        return report;
    });

    server.get(reportsBaseUrl, function (this: RouteHandlerContext, schema, request) {
        const reports = schema.all('creatorbaseReport');
        return buildPaginatedResponse(reports, {
            url: reportsBaseUrl,
            queryParams: request.queryParams,
            serialize: (resource) => this.serialize(resource, 'creatorbaseReport'),
        });
    });

    server.patch(`${reportsBaseUrl}:id/`, (schema, request) => {
        const report = schema.find('creatorbaseReport', request.params.id);
        if (!report) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        report.update(JSON.parse(request.requestBody));
        return report;
    });

    server.post(reportsBaseUrl, (schema, request) => {
        const attrs = JSON.parse(request.requestBody);
        const report = schema.create('creatorbaseReport', attrs);
        return report;
    });
}
