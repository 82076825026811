import { creatorbase, TiktokUser, TiktokUserImage, TiktokUserStats, TiktokVideoStats } from '@round/api';
import { Skeleton, TableProps, getTableMetaHelper } from '@round/ui-kit';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTiktokReportCampaignPosts } from './useTiktokReportCampaignPosts';
import useAbortableEffect from 'Hooks/useAbortableEffect';
import { TiktokVideo } from 'App.types';
import moment from 'moment';
import { useReportCampaignStats } from '../../Campaigns/hooks/useReportCampaignStats';
import CostCell from 'Modules/Plans/components/CostCell/CostCell';
import TotalCostFooterCell from 'Modules/Plans/components/TotalCostFooterCell/TotalCostFooterCell';
import { CampaignTableRow } from '../../Campaigns/CampaignsTable/ReportCampaignsTable';
import NotesCell from 'Modules/Plans/components/NotesCell/NotesCell';
import PostsTable from 'Modules/Plans/Posts/components/PostsTable/PostsTable';
import PostsTableStatusCell, {
    StatusData,
} from 'Modules/Plans/Posts/components/PostsTable/cells/StatusCell/PostsTableStatusCell';
import { BasePostTableMeta } from 'Modules/Plans/Posts/types';
import PostStatsValueCell from 'Modules/Plans/Posts/components/PostsTable/cells/PostsStatsValueCell';
import PostStatsFooterTotalCell from 'Modules/Plans/Posts/components/PostsTable/cells/PostsTableStatFooterCell';
import PostStatsViewsFooterCell from 'Modules/Plans/Posts/components/PostsTable/cells/PostStatsViewsFooterCell';
import PostsTableAccountCell, {
    AccountData,
} from 'Modules/Plans/Posts/components/PostsTable/cells/PostsTableAccountCell/PostsTableAccountCell';

export type TiktokReportPostsTableRow = creatorbase.TiktokPost & {
    video: TiktokVideo | null;
    stats: TiktokVideoStats | null;
    user: TiktokUser | null;
    userStats: TiktokUserStats | null;
    image?: TiktokUserImage | null;
};

type Props = Pick<TableProps<TiktokReportPostsTableRow>, 'noDataLabel'> & {
    campaign: CampaignTableRow;
};

type TableCellContext<K extends keyof TiktokReportPostsTableRow> = CellContext<
    TiktokReportPostsTableRow,
    TiktokReportPostsTableRow[K]
>;

type Meta = BasePostTableMeta<TiktokReportPostsTableRow> & {
    campaign: CampaignTableRow;
};
const getTableMeta = getTableMetaHelper<Meta>();

const TiktokReportPostsTable = ({ campaign }: Props) => {
    const {
        postsData,
        postStatsData,
        accountsData,
        fetchData,
        getAreStatsLoading,
        getIsAccountDataLoading,
    } = useTiktokReportCampaignPosts(campaign.id);
    const isInitialized = postsData?.status === 'success' || postsData?.status === 'error';

    useAbortableEffect(
        (signal) => {
            if (!isInitialized) {
                fetchData({ signal }).catch(() => {});
            }
        },
        [campaign.id, fetchData, isInitialized]
    );

    const { data: campaignStatsData, getAreCampaignStatsLoading } = useReportCampaignStats();

    const columns = useMemo<ColumnDef<TiktokReportPostsTableRow, any>[]>(
        () => [
            {
                header: 'ID',
                accessorKey: 'id',
                cell: ({ getValue, table }: TableCellContext<'id'>) => {
                    const { isLoading } = getTableMeta(table);

                    if (isLoading) {
                        return <Skeleton />;
                    }

                    return getValue().slice(-8);
                },
            },

            {
                header: 'Account',
                accessorFn: (row): AccountData => ({
                    image: row.image?.avatar_thumb.original_url ?? row.image?.avatar_thumb.cached_url ?? '',
                    title: row.user?.unique_id || '-',
                    followerCount: row.userStats?.follower_count,
                }),
                id: 'account',
                cell: PostsTableAccountCell,
            },
            {
                header: 'Status',
                id: 'status',
                accessorFn: (original): StatusData => ({
                    postUrl: original.post_url,
                    platform: original.platform,
                    isReadOnly: true,
                }),
                cell: PostsTableStatusCell,
            },
            {
                header: 'Cost',
                accessorKey: 'cost',
                cell: ({ getValue, table }: TableCellContext<'cost'>) => {
                    const { isLoading, campaign } = getTableMeta(table);

                    if (isLoading) {
                        return <Skeleton />;
                    }
                    return <CostCell isReadonly cost={getValue()} currency={campaign?.currency ?? null} />;
                },
                footer: ({ table }) => {
                    const { isLoading, campaign } = getTableMeta(table);

                    if (isLoading) {
                        return <Skeleton />;
                    }

                    return (
                        <TotalCostFooterCell
                            costs={table.getRowModel().rows.map((r) => r.original.cost)}
                            currency={campaign.currency}
                        />
                    );
                },
            },
            {
                header: 'Upload date',
                id: 'uploadedDate',
                accessorKey: 'video',
                cell: ({ row: { original }, table }) => {
                    const { isLoading, getAreStatsLoading } = getTableMeta(table);

                    if (isLoading || getAreStatsLoading(original)) {
                        return <Skeleton />;
                    }

                    const uploadedAt = original.video?.create_time
                        ? new Date(original.video?.create_time * 1000)
                        : null;

                    if (uploadedAt === null) {
                        return '-';
                    }

                    return moment(uploadedAt).format('DD MMM YYYY');
                },
            },
            {
                header: 'Views',
                id: 'views',
                accessorFn: (row) => row.stats?.play_count,
                cell: PostStatsValueCell,
                footer: PostStatsViewsFooterCell,
            },
            {
                header: 'Likes',
                id: 'likes',
                accessorFn: (row) => row.stats?.digg_count,
                cell: PostStatsValueCell,
                footer: PostStatsFooterTotalCell,
            },
            {
                header: 'Shares',
                id: 'shares',
                accessorFn: (row) => row.stats?.share_count,
                cell: PostStatsValueCell,
                footer: PostStatsFooterTotalCell,
            },
            {
                header: 'Comments',
                id: 'comments',
                accessorFn: (row) => row.stats?.comment_count,
                cell: PostStatsValueCell,
                footer: PostStatsFooterTotalCell,
            },
            {
                header: 'Saves',
                id: 'saves',
                accessorFn: (row) => row.stats?.save_count,
                cell: PostStatsValueCell,
                footer: PostStatsFooterTotalCell,
            },
            {
                header: 'Notes',
                accessorKey: 'notes',
                cell: ({ getValue, table }: TableCellContext<'notes'>) => {
                    const { isLoading } = getTableMeta(table);

                    if (isLoading) {
                        return <Skeleton />;
                    }
                    return <NotesCell notes={getValue()} />;
                },
            },
        ],
        []
    );

    const meta: Meta = {
        isLoading: !isInitialized,
        getAreStatsLoading: (row) => getAreStatsLoading(row.tiktok_details?.content_id),
        getIsAccountDataLoading: (row) => getIsAccountDataLoading(row.tiktok_details?.account_id),
        campaign,
        campaignStats: campaignStatsData[campaign.id]?.data || null,
        areCampaignStatsLoading: getAreCampaignStatsLoading(campaign.id),
    };

    const rows = useMemo(
        () =>
            postsData?.data?.map((post) => {
                const accountId = post.tiktok_details?.account_id;
                const userAccountData = accountId ? accountsData[accountId]?.data : null;

                const videoId = post.tiktok_details?.content_id;
                const video = typeof videoId === 'number' ? postStatsData?.videos[videoId]?.data || null : null;
                const videoStats =
                    typeof videoId === 'number' ? postStatsData?.videoStats[videoId]?.data || null : null;

                return {
                    ...post,
                    stats: videoStats,
                    video,
                    user: userAccountData?.user,
                    userStats: userAccountData?.stats,
                    image: userAccountData?.image,
                } as TiktokReportPostsTableRow;
            }) ?? [],
        [postsData?.data, postStatsData, accountsData]
    );

    return (
        <PostsTable
            columns={columns}
            data={rows}
            meta={meta}
            isLoading={!isInitialized}
            hasError={postsData?.status === 'error'}
        />
    );
};

export default TiktokReportPostsTable;
