import { creatorbase } from '@round/api';
import { GenericDropdownOption } from 'App.types';
import { OptionsParams, UseSingleValueSelectParams, useSelect } from 'Hooks/useSelect';
import { useCallback } from 'react';

export type TeamOption = GenericDropdownOption<number>;

export const mapTeamToOption = (team: creatorbase.Team): TeamOption => ({
    value: team.id,
    label: team.name,
});

type Params = Omit<UseSingleValueSelectParams<TeamOption>, 'fetchOptions' | 'isMulti'>;

export default function useTeamSelect(params: Params) {
    const fetchOptions = useCallback(async (params: OptionsParams, requestInit?: RequestInit) => {
        const response = await creatorbase.getTeams(params, requestInit);

        if (response.status === 404 || response.status === 403) {
            throw new Error(response.data.detail);
        }

        return {
            ...response.data,
            results: response.data.results.map(mapTeamToOption),
        };
    }, []);

    return useSelect<TeamOption>({ fetchOptions, isMulti: false, ...params });
}
