import { InstagramPost, InstagramUserImage, creatorbase } from '@round/api';
import { Skeleton, TableProps, getTableMetaHelper } from '@round/ui-kit';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { useMemo } from 'react';
import styles from './PostsTable.module.css';
import StatusCell from 'Modules/Plans/components/StatusCell/StatusCell';
import CostCell from 'Modules/Plans/components/CostCell/CostCell';
import { UsePostsReturn } from 'Modules/Plans/Project/contexts/PostsContext';
import { CampaignTableRow } from '../ProjectCampaignsTable/ProjectCampaignsTable';
import { formatNumberToKNotation } from 'helpers';
import moment from 'moment';
import TotalFooterCell from '../../../components/TotalFooterCell';
import TotalCostFooterCell from 'Modules/Plans/components/TotalCostFooterCell/TotalCostFooterCell';
import PostsNoDataLabel from '../PostsNoDataLabel/PostsNoDataLabel';
import ActionsCell from './components/ActionsCell/ActionsCell';
import Image from '../../../../../../ui/Image/Image';
import NotesCell from 'Modules/Plans/components/NotesCell/NotesCell';
import StoryViewsCell from 'Modules/Plans/components/StoryViewsCell/StoryViewsCell';
import { formatPostStatsCellValue } from 'Modules/Plans/Posts/helpers';
import PostsTable from 'Modules/Plans/Posts/components/PostsTable/PostsTable';

export type InstagramPostsTableRow = creatorbase.InstagramPost & {
    stats?: InstagramPost | null;
    image?: InstagramUserImage | null;
};

type Props = Pick<TableProps<InstagramPostsTableRow>, 'data' | 'noDataLabel'> & {
    campaign: CampaignTableRow;
    isLoading?: boolean;
    hasError: boolean;
    statsLoading: string[];
    onCreatePost: (campaign: CampaignTableRow) => void;
    onDeletePost: (post: InstagramPostsTableRow) => void;
    updatePost: UsePostsReturn['updatePost'];
};

type TableCellContext<K extends keyof InstagramPostsTableRow> = CellContext<
    InstagramPostsTableRow,
    InstagramPostsTableRow[K]
>;

type Meta = Pick<Props, 'campaign' | 'isLoading' | 'onDeletePost' | 'statsLoading' | 'updatePost'>;
const getTableMeta = getTableMetaHelper<Meta>();

const InstagramPostsTable = ({
    data,
    campaign,
    isLoading,
    hasError,
    statsLoading,
    onCreatePost,
    onDeletePost,
    updatePost,
}: Props) => {
    const columns = useMemo<ColumnDef<InstagramPostsTableRow, any>[]>(
        () => [
            {
                header: 'ID',
                accessorKey: 'id',
                cell: ({ getValue, table }: TableCellContext<'id'>) => {
                    const { isLoading } = getTableMeta(table);

                    if (isLoading) {
                        return <Skeleton />;
                    }

                    return getValue().slice(-8);
                },
            },
            {
                header: 'Account',
                id: 'account',
                cell: ({ row: { original }, table }) => {
                    const { isLoading } = getTableMeta(table);
                    const image =
                        original?.image?.avatar_thumb?.cached_url || original?.image?.avatar_thumb?.original_url || '';
                    const followerCount = original?.stats?.owner_follower_count;

                    if (isLoading) {
                        return <Skeleton />;
                    }

                    return (
                        <div className={styles.accountContainer}>
                            <Image loading={isLoading} src={image} className={styles.accountImage} />
                            <div className={styles.accountDetails}>
                                <p>{original.stats?.owner_username ? `@${original.stats.owner_username}` : '-'}</p>
                                <p className={styles.followerCount}>
                                    {typeof followerCount === 'number' ? formatNumberToKNotation(followerCount) : '-'}
                                </p>
                            </div>
                        </div>
                    );
                },
            },
            {
                header: 'Status',
                id: 'status',
                cell: ({ row: { original }, table }: TableCellContext<'post_url'>) => {
                    const { isLoading, updatePost } = getTableMeta(table);

                    if (isLoading) {
                        return <Skeleton />;
                    }

                    return <StatusCell post={original} updatePost={(data) => updatePost(original.id, data)} />;
                },
            },
            {
                header: 'Cost',
                accessorKey: 'cost',
                cell: ({ getValue, row: { original }, table }: TableCellContext<'cost'>) => {
                    const { isLoading, updatePost, campaign } = getTableMeta(table);

                    if (isLoading) {
                        return <Skeleton />;
                    }

                    return (
                        <CostCell
                            cost={getValue()}
                            currency={campaign.currency ?? null}
                            updateCost={(cost: number | null) => updatePost(original.id, { cost })}
                            isReadonly={!campaign.is_post_cost_editable}
                        />
                    );
                },
                footer: ({ table }) => {
                    const { isLoading, campaign } = getTableMeta(table);

                    return isLoading ? (
                        <Skeleton />
                    ) : (
                        <TotalCostFooterCell
                            costs={table.getRowModel().rows.map((r) => r.original.cost)}
                            currency={campaign.currency}
                        />
                    );
                },
            },
            {
                header: 'Upload date',
                accessorKey: 'stats',
                id: 'uploadedDate',
                cell: ({ getValue, row: { original }, table }: TableCellContext<'stats'>) => {
                    const { isLoading, statsLoading } = getTableMeta(table);

                    if (isLoading || statsLoading.includes(original.id)) {
                        return <Skeleton />;
                    }

                    const uploadedAt = getValue()?.taken_at;

                    if (typeof uploadedAt !== 'string') {
                        return '-';
                    }

                    return moment(uploadedAt).format('DD MMM YYYY');
                },
            },
            {
                header: 'Views',
                id: 'views',
                accessorKey: 'stats',
                cell: ({ getValue, row: { original }, table }: TableCellContext<'stats'>) => {
                    const { isLoading, statsLoading } = getTableMeta(table);

                    if (isLoading || statsLoading.includes(original.id)) {
                        return <Skeleton />;
                    }

                    const views = getValue()?.play_count ?? getValue()?.view_count;

                    return formatPostStatsCellValue(views);
                },
                footer: ({ table }) => {
                    const { isLoading, statsLoading, campaign } = getTableMeta(table);

                    if (isLoading || !!statsLoading.length) {
                        return <Skeleton />;
                    }

                    return formatPostStatsCellValue(campaign.stats?.view_count);
                },
            },
            {
                header: 'Likes',
                accessorKey: 'stats',
                id: 'likes',
                cell: ({ getValue, row: { original }, table }: TableCellContext<'stats'>) => {
                    const { isLoading, statsLoading } = getTableMeta(table);

                    if (isLoading || statsLoading.includes(original.id)) {
                        return <Skeleton />;
                    }

                    const likes = getValue()?.like_count;

                    return formatPostStatsCellValue(likes);
                },
                footer: ({ table }) => {
                    const { isLoading, statsLoading } = getTableMeta(table);

                    return isLoading || !!statsLoading.length ? (
                        <Skeleton />
                    ) : (
                        <TotalFooterCell values={table.getRowModel().rows.map((r) => r.original.stats?.like_count)} />
                    );
                },
            },
            {
                header: 'Story views',
                accessorKey: 'instagram_details',
                id: 'storyViews',
                cell: ({ row: { original }, table }: TableCellContext<'instagram_details'>) => {
                    const { isLoading, statsLoading, updatePost } = getTableMeta(table);

                    if (isLoading || statsLoading.includes(original.id)) {
                        return <Skeleton />;
                    }

                    return (
                        <StoryViewsCell
                            storyViews={original.instagram_details?.story_view_count || null}
                            updateStoryViews={(data) => updatePost(original.id, data)}
                        />
                    );
                },
                footer: ({ table }) => {
                    const { isLoading, statsLoading } = getTableMeta(table);

                    return isLoading || !!statsLoading.length ? (
                        <Skeleton />
                    ) : (
                        <TotalFooterCell
                            values={table.getRowModel().rows.map((r) => r.original.instagram_details?.story_view_count)}
                        />
                    );
                },
            },
            {
                header: 'Comments',
                accessorKey: 'stats',
                id: 'comments',
                cell: ({ getValue, row: { original }, table }: TableCellContext<'stats'>) => {
                    const { isLoading, statsLoading } = getTableMeta(table);

                    if (isLoading || statsLoading.includes(original.id)) {
                        return <Skeleton />;
                    }

                    const comments = getValue()?.comment_count;

                    return formatPostStatsCellValue(comments);
                },
                footer: ({ table }) => {
                    const { isLoading, statsLoading } = getTableMeta(table);

                    return isLoading || !!statsLoading.length ? (
                        <Skeleton />
                    ) : (
                        <TotalFooterCell
                            values={table.getRowModel().rows.map((r) => r.original.stats?.comment_count)}
                        />
                    );
                },
            },
            {
                header: 'Notes',
                accessorKey: 'notes',
                cell: ({ getValue, row: { original }, table }: TableCellContext<'notes'>) => {
                    const { isLoading, updatePost } = getTableMeta(table);

                    if (isLoading) {
                        return <Skeleton />;
                    }
                    return (
                        <NotesCell
                            notes={getValue()}
                            updateNotes={(notes: string) => updatePost(original.id, { notes })}
                        />
                    );
                },
            },
            {
                header: '',
                id: 'actions',
                cell: ({ row: { original }, table }) => {
                    const { isLoading, onDeletePost } = getTableMeta(table);

                    if (isLoading) {
                        return <Skeleton />;
                    }

                    return <ActionsCell onDelete={() => onDeletePost(original)} />;
                },
            },
        ],
        []
    );

    // meta object is passed to every cell component. This allows us to reduce rerenders and prevents unnecessary cell unmounts.
    const meta = {
        isLoading,
        statsLoading,
        onDeletePost,
        updatePost,
        campaign,
    };

    return (
        <PostsTable
            columns={columns}
            data={data}
            meta={meta}
            isLoading={isLoading}
            hasError={hasError}
            noDataLabel={<PostsNoDataLabel hasError={hasError} onAddPost={() => onCreatePost(campaign)} />}
        />
    );
};

export default InstagramPostsTable;
