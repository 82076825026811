import { Form, FormikProvider, useFormik, yupToFormErrors } from 'formik';
import Modal from 'ui-new/whitelabel/Modal/Modal';
import CampaignFormBase, {
    BaseCampaignFormValues,
    mapCampaignApiErrorsToFormikErrors,
    validationSchema,
    mapCampaignFormValuesToApiData,
} from '../../components/CampaignFormBase/CampaignFormBase';
import Button from 'ui-new/whitelabel/Button/Button';
import { UseCampaigns, useCampaigns } from 'Modules/Plans/Project/contexts/CampaignsContext';
import { showNotification } from 'helpers';
import { omit } from 'lodash';
import useNonNullContext from 'Hooks/useNonNullContext';
import { OptionsContext } from 'contexts/OptionsContext/OptionsContext';
import styles from './CreateYoutubeCampaignModal.module.css';
import { useProjectDetails } from 'Modules/Plans/Project/contexts/ProjectContext';

type Props = {
    isOpen: boolean;
    closeModal: () => void;
    createCampaign: UseCampaigns['createCampaign'];
};

const CreateYoutubeCampaignModal = ({ isOpen, closeModal, createCampaign }: Props) => {
    const { currencies } = useNonNullContext(OptionsContext);
    const { data: campaigns, status: campaignsStatus } = useCampaigns();
    const projectCurrency = campaigns?.length
        ? currencies.find((curr) => curr.id === campaigns[0].currency_id) || null
        : null;
    const { data: projectData } = useProjectDetails();

    const formik = useFormik<BaseCampaignFormValues>({
        initialValues: {
            cost: '',
            currency: projectCurrency ? { value: projectCurrency.id, label: projectCurrency.name } : null,
            is_post_cost_editable: null,
            name: '',
            team_members: [],
        },
        onSubmit: async (values, helpers) => {
            if (values.is_post_cost_editable === null) {
                return;
            }

            try {
                const response = await createCampaign({
                    ...mapCampaignFormValuesToApiData(values),
                    platform: 'youtube',
                });

                if (!response) {
                    showNotification('Seems like project page was not initialized correctly', 'error');
                    return;
                }

                if (response.status === 201) {
                    showNotification('Campaign created', 'info');
                    helpers.resetForm();
                    closeModal();
                    return;
                }

                if (response.status === 400) {
                    helpers.setErrors(mapCampaignApiErrorsToFormikErrors(response.data));
                    return;
                }

                showNotification('Could not create campaign', 'error');
            } catch {
                showNotification('Could not create campaign', 'error');
            }
        },
        // validationSchema param threw an error on validation
        validate: async (values) => {
            try {
                const transformedValues = values.is_post_cost_editable === false ? values : omit(values, 'cost');
                await validationSchema.validate(transformedValues, { abortEarly: false });
            } catch (e) {
                return yupToFormErrors(e);
            }
        },
        enableReinitialize: true,
    });

    const handleClose = () => {
        formik.resetForm();
        closeModal();
    };

    // Shouldn't be able to create campaign, when we haven't checked for currencies of other campaigns
    const isSubmitDisabled = !formik.dirty || !formik.isValid || campaignsStatus !== 'success';
    return (
        <Modal
            className={styles.modal}
            closeOnOverlayClick
            isOpen={isOpen}
            onClose={handleClose}
            ariaLabel="Create youtube campaign modal"
        >
            <Modal.Header>
                <Modal.Title>Create campaign</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormikProvider value={formik}>
                    <Form className={styles.form}>
                        <CampaignFormBase
                            readonlyFields={projectCurrency ? ['currency'] : undefined}
                            teamId={projectData?.team?.id}
                        />
                    </Form>
                </FormikProvider>
            </Modal.Body>
            <Modal.Actions>
                <Button
                    appearance="primary"
                    disabled={isSubmitDisabled}
                    isLoading={formik.isSubmitting}
                    onClick={formik.submitForm}
                >
                    Create campaign
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default CreateYoutubeCampaignModal;
