import { Currency, creatorbase } from '@round/api';
import { getTableMetaHelper, ProgressBar, TableProps } from '@round/ui-kit';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import styles from './ProjectCampaignsTable.module.css';
import { DistributiveOmit } from 'utility/utility.types';
import { asMoney, numberWithCommas } from 'helpers';
import { toDecimalPoint } from '@round/utils';
import Button from 'ui-new/whitelabel/Button/Button';
import { ReactComponent as TrashIcon } from 'assets/whitelabel/Trash.svg';
import { ReactComponent as PlusIcon } from 'assets/whitelabel/Plus.svg';
import { ReactComponent as EditIcon } from 'assets/whitelabel/Edit.svg';
import RunDates from 'Modules/Plans/components/RunDates/RunDates';
import moment from 'moment';
import Dropdown from 'ui-new/whitelabel/Dropdown/Dropdown';
import TruncationPopover from 'ui-new/TruncationPopover/TruncationPopover';
import { AudioTimeSeriesData } from 'Modules/Plans/Project/contexts/AudioTimeSeriesContext/reducer';
import { tooltipCopy } from 'Modules/Plans/helpers';
import TooltipHeader from 'ui-new/whitelabel/Table/TooltipHeader/TooltipHeader';
import CircleBadge from 'Modules/Plans/components/CircleBadge/CircleBadge';
import { getUserNameInitials } from 'Modules/Plans/User/user.helpers';
import AudioGraph from 'Modules/Plans/components/AudioGraph/AudioGraph';
import ExpandCell from 'Modules/Plans/Campaigns/components/cells/ExpandCell/ExpandCell';
import CampaignsTable from 'Modules/Plans/Campaigns/components/CampaignsTable/CampaignsTable';

export type CampaignTableRow = DistributiveOmit<creatorbase.Campaign, 'team_members' | 'currency_id'> & {
    teamMembers: creatorbase.User[];
    stats: creatorbase.AggregatedPostStats | null;
    audioTimeSeries?: AudioTimeSeriesData | null;
    currency: Currency | undefined;
    audioUrl?: string;
};

type Props = Pick<
    TableProps<CampaignTableRow>,
    'data' | 'expandedState' | 'onExpandedChange' | 'renderSubComponent'
> & {
    hasError?: boolean;
    isLoading: boolean;
    statsLoading: number[];
    onEdit?: (campaign: CampaignTableRow) => void;
    onCreatePost?: (campaign: CampaignTableRow) => void;
    onCreateCampaign: () => void;
    onDeleteCampaign: (campaign: CampaignTableRow) => void;
};

type TableCellContext<K extends keyof CampaignTableRow> = CellContext<CampaignTableRow, CampaignTableRow[K]>;
type Meta = Pick<Props, 'isLoading' | 'statsLoading' | 'onCreatePost' | 'onDeleteCampaign' | 'onEdit'>;
const getTableMeta = getTableMetaHelper<Meta>();

const ProjectCampaignsTable = ({
    data,
    isLoading,
    statsLoading,
    hasError,
    onEdit,
    onCreateCampaign,
    expandedState,
    onExpandedChange,
    renderSubComponent,
    onCreatePost,
    onDeleteCampaign,
}: Props) => {
    const columns = useMemo<ColumnDef<CampaignTableRow, any>[]>(
        () => [
            {
                header: '',
                id: 'expand',
                cell: ExpandCell,
            },
            {
                header: 'Campaign',
                accessorKey: 'name',
                meta: {
                    className: styles.campaignColumn,
                },
                cell: ({ getValue, row: { original }, table }: TableCellContext<'name'>) => {
                    const { isLoading, onEdit, onCreatePost } = getTableMeta(table);
                    if (isLoading) {
                        return <Skeleton />;
                    }

                    return (
                        <div className={styles.campaignContainer}>
                            <TruncationPopover content={getValue()}>
                                {(setRef) => (
                                    <span ref={setRef} className={styles.campaignName}>
                                        {getValue()}
                                    </span>
                                )}
                            </TruncationPopover>
                            <div className={styles.campaignActionsContainer}>
                                <button className={styles.campaignActionButton} onClick={() => onEdit?.(original)}>
                                    Edit campaign
                                </button>
                                <button
                                    className={styles.campaignActionButton}
                                    onClick={() => onCreatePost?.(original)}
                                >
                                    Create post
                                </button>
                            </div>
                        </div>
                    );
                },
            },
            {
                header: 'Posts',
                accessorKey: 'stats',
                id: 'posts',
                cell: ({ getValue, row: { original }, table }: TableCellContext<'stats'>) => {
                    const { isLoading, statsLoading } = getTableMeta(table);
                    if (isLoading || statsLoading.includes(original.id)) {
                        return <Skeleton />;
                    }

                    const stats = getValue();
                    return stats?.post_count ?? '-';
                },
            },
            {
                header: 'Spend',
                accessorKey: 'stats',
                id: 'spend',
                cell: ({ getValue, row: { original }, table }: TableCellContext<'stats'>) => {
                    const { isLoading, statsLoading } = getTableMeta(table);
                    if (isLoading || statsLoading.includes(original.id)) {
                        return <Skeleton />;
                    }

                    const stats = getValue();

                    const totalSpentCurrencyValuePair = Object.entries(stats?.total_spent ?? {})[0] ?? [];
                    const amount = totalSpentCurrencyValuePair[1];

                    // TODO: calculate when we have planned posts
                    const spentPercentage = null;

                    return (
                        <div className={styles.amountSpentContainer}>
                            <div className={styles.amountSpent}>
                                <span>{asMoney(amount, original.currency)}</span>
                                <span className={styles.spentPercentage}>
                                    {typeof spentPercentage === 'number'
                                        ? ` ${toDecimalPoint(spentPercentage, 2)}%`
                                        : '-'}
                                </span>
                            </div>
                            {typeof spentPercentage === 'number' && (
                                <ProgressBar
                                    progress={spentPercentage}
                                    trackClassName={styles.spentTrack}
                                    barClassName={styles.spentBar}
                                />
                            )}
                        </div>
                    );
                },
            },
            {
                header: 'Views',
                accessorKey: 'stats',
                id: 'views',
                cell: ({ getValue, row: { original }, table }: TableCellContext<'stats'>) => {
                    const { isLoading, statsLoading } = getTableMeta(table);
                    if (isLoading || statsLoading.includes(original.id)) {
                        return <Skeleton />;
                    }

                    const stats = getValue();
                    return stats?.view_count ? numberWithCommas(stats.view_count) : '-';
                },
            },
            {
                header: () => <TooltipHeader header="CPM" tooltipBody={tooltipCopy.cpm} />,
                accessorKey: 'stats',
                id: 'cpm',
                cell: ({ getValue, row: { original }, table }: TableCellContext<'stats'>) => {
                    const { isLoading, statsLoading } = getTableMeta(table);
                    if (isLoading || statsLoading.includes(original.id)) {
                        return <Skeleton />;
                    }

                    const stats = getValue();

                    const cpmCurrencyValuePair = Object.entries(stats?.cpm ?? {})[0] ?? [];
                    const amount = cpmCurrencyValuePair[1];

                    return asMoney(amount, original.currency);
                },
            },
            {
                header: () => (
                    <TooltipHeader
                        header="ER"
                        tooltipTitle="Engagement Rate"
                        tooltipBody={tooltipCopy.engagementRate}
                    />
                ),
                accessorKey: 'stats',
                id: 'er',
                cell: ({ getValue, row: { original }, table }: TableCellContext<'stats'>) => {
                    const { isLoading, statsLoading } = getTableMeta(table);
                    if (isLoading || statsLoading.includes(original.id)) {
                        return <Skeleton />;
                    }

                    const stats = getValue();
                    const engagementRate =
                        typeof stats?.engagement_rate === 'number'
                            ? `${toDecimalPoint(stats?.engagement_rate * 100, 2)}%`
                            : '-';

                    return engagementRate;
                },
            },
            {
                header: 'Run dates',
                id: 'runDates',
                cell: ({ row: { original }, table }) => {
                    const { isLoading, statsLoading } = getTableMeta(table);
                    if (isLoading || statsLoading.includes(original.id)) {
                        return <Skeleton />;
                    }

                    if (!original.stats?.first_post_date && !original.stats?.last_post_date) {
                        return '-';
                    }

                    const startDate = moment(original.stats?.first_post_date).format('DD MMM');
                    const endDate = moment(original.stats?.last_post_date).format('DD MMM');

                    return <RunDates startDate={startDate} endDate={endDate} />;
                },
            },
            {
                header: 'Team',
                accessorKey: 'teamMembers',
                cell: ({ getValue, table }: TableCellContext<'teamMembers'>) => {
                    const { isLoading } = getTableMeta(table);
                    if (isLoading) {
                        return <Skeleton />;
                    }

                    const users = getValue();
                    return (
                        <div className={styles.teamContainer}>
                            {users.map((user, index) => (
                                <CircleBadge key={index} tooltip={user.name}>
                                    {getUserNameInitials(user.name)}
                                </CircleBadge>
                            ))}
                        </div>
                    );
                },
            },
            {
                header: 'Audio',
                id: 'audioGraph',
                accessorKey: 'audioTimeSeries',
                meta: {
                    className: styles.dailyChange,
                },
                cell: ({ getValue, row: { original } }: TableCellContext<'audioTimeSeries'>) => {
                    const timeSeriesData = getValue();
                    const isTimeSeriesLoading = !!timeSeriesData && timeSeriesData.status === 'loading';

                    const timeSeries = timeSeriesData?.data?.posts_daily_change ?? [];
                    const shouldDisplayTimeSeriesData = !!timeSeries.length;
                    const points = timeSeries.map((s) => ({ x: s.timestamp, y: s.value }));

                    return (
                        <AudioGraph
                            audioUrl={original.audioUrl}
                            points={points}
                            shouldDisplayTimeSeriesData={shouldDisplayTimeSeriesData}
                            isTimeSeriesLoading={isTimeSeriesLoading}
                        />
                    );
                },
            },
            {
                header: '',
                id: 'actions',
                meta: {
                    className: styles.actionsCell,
                },
                cell: function Actions({ row: { original }, table }) {
                    const { isLoading, onDeleteCampaign, onCreatePost, onEdit } = getTableMeta(table);
                    if (isLoading) {
                        return;
                    }

                    return (
                        <Dropdown>
                            {({ setIsOpen }) => (
                                <>
                                    <Dropdown.Target />
                                    <Dropdown.Menu>
                                        <Dropdown.Item
                                            icon={PlusIcon}
                                            onClick={() => {
                                                onCreatePost?.(original);
                                                setIsOpen(false);
                                            }}
                                        >
                                            New post
                                        </Dropdown.Item>

                                        <Dropdown.Item
                                            icon={EditIcon}
                                            onClick={() => {
                                                onEdit?.(original);
                                                setIsOpen(false);
                                            }}
                                        >
                                            Edit campaign
                                        </Dropdown.Item>

                                        <Dropdown.Separator />

                                        <Dropdown.Item
                                            icon={() => <TrashIcon className={styles.deleteCampaign} />}
                                            onClick={() => {
                                                onDeleteCampaign(original);
                                                setIsOpen(false);
                                            }}
                                            className={styles.deleteCampaign}
                                        >
                                            Delete campaign
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </>
                            )}
                        </Dropdown>
                    );
                },
            },
        ],
        []
    );

    const meta: Meta = {
        isLoading,
        statsLoading,
        onDeleteCampaign,
        onCreatePost,
        onEdit,
    };

    return (
        <CampaignsTable
            className={styles.table}
            meta={meta}
            data={data}
            columns={columns}
            expandedState={expandedState}
            onExpandedChange={onExpandedChange}
            renderSubComponent={renderSubComponent}
            noDataLabel={
                <div className={styles.noDataContainer} aria-label="no campaigns">
                    {hasError ? (
                        'Could not load campaigns'
                    ) : (
                        <Button
                            appearance="primary"
                            onClick={onCreateCampaign}
                            iconLeft={<PlusIcon className={styles.plusIcon} />}
                        >
                            Campaign
                        </Button>
                    )}
                </div>
            }
            columnVisibility={{
                audioGraph: !!data.find((row) => !!row.audioTimeSeries),
            }}
        />
    );
};

export default ProjectCampaignsTable;
