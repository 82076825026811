import { UsePostsReturn } from 'Modules/Plans/Project/contexts/PostsContext';
import Modal from 'ui-new/whitelabel/Modal/Modal';
import PostForm, { PostFormValues, getValidationSchema } from '../PostForm/PostForm';
import Button from 'ui-new/whitelabel/Button/Button';
import { FormikProvider, useFormik } from 'formik';
import { creatorbase } from '@round/api';
import { showNotification } from 'helpers';
import styles from './CreatePostModal.module.css';
import { mapApiErrorsToFormikErrors } from 'utility/utility';

type Props = {
    isOpen: boolean;
    closeModal: () => void;
    createPost: UsePostsReturn['createPost'];
    campaign: creatorbase.Campaign | null | undefined;
};

const CreatePostModal = ({ isOpen, closeModal, createPost, campaign }: Props) => {
    const formik = useFormik<PostFormValues>({
        initialValues: {
            identifier: '',
            cost: undefined,
        },
        onSubmit: async (values, helpers) => {
            if (!campaign) {
                return;
            }

            try {
                const response = await createPost({
                    campaign_id: campaign.id,
                    identifier: values.identifier,
                    cost: values.cost,
                });

                if (response.status === 201) {
                    showNotification('Post created', 'info');
                    helpers.resetForm();
                    closeModal();
                    return;
                }

                if (response.status === 400) {
                    helpers.setErrors(mapApiErrorsToFormikErrors(response.data));
                    return;
                }

                showNotification('Could not create post', 'error');
            } catch {
                showNotification('Could not create post', 'error');
            }
        },
        validationSchema: getValidationSchema(Boolean(campaign?.is_post_cost_editable)),
        enableReinitialize: true,
    });

    const handleClose = () => {
        formik.resetForm();
        closeModal();
    };

    return (
        <Modal className={styles.modal} closeOnOverlayClick isOpen={isOpen} onClose={handleClose}>
            <Modal.Header>
                <Modal.Title>Create Post</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormikProvider value={formik}>
                    <PostForm isPostCostEditable={campaign?.is_post_cost_editable} currency={campaign?.currency_id} />
                </FormikProvider>
            </Modal.Body>
            <Modal.Actions>
                <Button
                    appearance="primary"
                    isLoading={formik.isSubmitting}
                    disabled={!formik.dirty || !formik.isValid}
                    onClick={formik.submitForm}
                >
                    Create post
                </Button>

                <Button appearance="ghost" onClick={handleClose}>
                    Cancel
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default CreatePostModal;
