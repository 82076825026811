import { createContext, useCallback, useEffect, useState } from 'react';
import { FCWithChildren } from '../../utility/utility.types';
import { showNotification } from '../../helpers';

type AudioPlayerContextValues = {
    playing: boolean;
    loading: boolean;
    togglePlayPause: () => Promise<void>;
    load: (options: LoadOptions) => void;
    audio: HTMLAudioElement | null;
};

type LoadOptions = { src: string; autoplay: boolean };

export const AudioPlayerContext = createContext<AudioPlayerContextValues | null>(null);
export const AudioPlayerProvider: FCWithChildren = ({ children }) => {
    const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
    const [playing, setPlaying] = useState(false);
    const [loading, setLoading] = useState(false);
    const [autoplay, setAutoplay] = useState(false);

    const togglePlayPause = useCallback(async () => {
        if (!audio) {
            return;
        }

        try {
            if (audio.paused) {
                await audio.play();
                return;
            }

            audio.pause();
        } catch {
            showNotification('Could not play an audio', 'error');
        }
    }, [audio]);

    const load = useCallback(
        ({ src, autoplay }: LoadOptions) => {
            audio?.pause();
            setAudio(new Audio(src));
            setAutoplay(autoplay);
        },
        [audio]
    );

    useEffect(() => {
        if (audio) {
            const onPlay = () => setPlaying(true);
            const onPause = () => setPlaying(false);
            const onLoading = () => setLoading(true);
            const onLoadingEnd = () => setLoading(false);
            const onError = () => {
                setPlaying(false);
                setLoading(false);
                showNotification('Could not play an audio', 'error');
            };

            audio.addEventListener('play', onPlay);
            audio.addEventListener('pause', onPause);
            audio.addEventListener('ended', onPause);
            audio.addEventListener('waiting', onLoading);
            audio.addEventListener('playing', onLoadingEnd);
            audio.addEventListener('loadstart', onLoading);
            audio.addEventListener('loadeddata', onLoadingEnd);
            audio.addEventListener('error', onError);

            if (autoplay) {
                audio.play();
            }

            return () => {
                audio.removeEventListener('play', onPlay);
                audio.removeEventListener('pause', onPause);
                audio.removeEventListener('ended', onPause);
                audio.removeEventListener('waiting', onLoading);
                audio.removeEventListener('loadstart', onLoading);
                audio.removeEventListener('loadeddata', onLoadingEnd);
                audio.removeEventListener('playing', onLoadingEnd);
                audio.removeEventListener('error', onError);
            };
        }
    }, [audio, autoplay]);

    return (
        <AudioPlayerContext.Provider
            value={{
                togglePlayPause,
                load,
                playing,
                loading,
                audio,
            }}
        >
            {children}
        </AudioPlayerContext.Provider>
    );
};
