import { getInstagramAudios, GetInstagramAudiosParams, InstagramAudio, PaginatedApiResponseData } from '@round/api';
import { DataState } from 'App.types';
import { useCallback, useState } from 'react';

type State = DataState<Pick<PaginatedApiResponseData<InstagramAudio>, 'count' | 'results' | 'next'>>;
const initialState: State = {
    data: null,
    error: null,
    status: 'idle',
};

export default function useInstagramAudios() {
    const [state, setState] = useState<State>(initialState);

    const fetchData = useCallback(async (params: GetInstagramAudiosParams, requestInit?: RequestInit) => {
        setState((prev) => ({ status: 'loading', data: prev.data, error: null }));

        try {
            const response = await getInstagramAudios(params, requestInit);
            if (response.status === 200) {
                setState((prev) => ({
                    status: 'success',
                    error: null,
                    data: {
                        count: response.data.count,
                        next: response.data.next,
                        results: prev.data?.results.concat(response.data.results) ?? response.data.results,
                    },
                }));

                return response;
            }

            setState((prev) => ({ status: 'error', data: prev.data, error: response.data.detail }));
            return response;
        } catch (e) {
            if (e instanceof Error && e.name === 'AbortError') {
                setState((prev) => ({ status: 'idle', data: prev.data, error: null }));
                throw e;
            }

            setState((prev) => ({ status: 'error', data: prev.data, error: 'Could not fetch instagram audios' }));
            throw e;
        }
    }, []);

    const reset = useCallback(() => setState(initialState), []);

    return {
        ...state,
        fetchData,
        reset,
    };
}
