import { youtube } from '@round/api';
import useNonNullContext from 'Hooks/useNonNullContext';
import {
    initialState,
    reducer,
    State as YoutubePostStatsByPostIdState,
    Actions as YoutubePostStatsByPostIdActions,
} from 'Modules/Plans/Posts/reducers/youtubePostStatsByPostId';
import { Dispatch, ReactNode, createContext, useCallback, useContext, useReducer } from 'react';
import { PostIdData } from 'Modules/Plans/Posts/types';
import { getIdsFromPostIdData } from 'Modules/Plans/Posts/helpers';

const YoutubePostStatsContext = createContext<
    [YoutubePostStatsByPostIdState, Dispatch<YoutubePostStatsByPostIdActions>] | null
>(null);

type Props = { children?: ReactNode };
export const YoutubePostStatsProvider = ({ children }: Props) => {
    const state = useReducer(reducer, initialState);

    return <YoutubePostStatsContext.Provider value={state}>{children}</YoutubePostStatsContext.Provider>;
};

export function useYoutubePostStats() {
    const [state, dispatch] = useNonNullContext(YoutubePostStatsContext);

    const fetchData = useCallback(
        async (idData: PostIdData[], requestInit?: RequestInit) => {
            if (!idData.length) {
                dispatch({ type: 'youtubePostStatsInitialized' });
                return;
            }

            const postIds = idData.map(({ postId }) => postId);
            const contentIds = idData.map(({ contentId }) => contentId);

            try {
                dispatch({ type: 'youtubePostStatsLoading', payload: { postIds } });

                const response = await youtube.getYoutubeVideos(
                    {
                        id: contentIds.join(','),
                        page_size: contentIds.length,
                    },
                    requestInit
                );

                if (response.status !== 200) {
                    dispatch({
                        type: 'youtubePostStatsError',
                        payload: { postIds, error: `Couldn't fetch Youtube post stats` },
                    });

                    return;
                }

                dispatch({
                    type: 'youtubePostStatsSuccess',
                    payload: {
                        idData,
                        stats: response.data.results,
                    },
                });

                dispatch({ type: 'youtubePostStatsInitialized' });
            } catch (error) {
                if (error instanceof Error && error.name === 'AbortError') {
                    dispatch({ type: 'youtubePostStatsIdle', payload: { postIds } });
                    return;
                }

                dispatch({
                    type: 'youtubePostStatsError',
                    payload: { postIds, error: `Couldn't fetch Youtube post stats` },
                });
            }
        },
        [dispatch]
    );

    return {
        data: state.data,
        isInitialized: state.isInitialized,
        fetchData,
    };
}

export function useYoutubePostStatsActions() {
    const context = useContext(YoutubePostStatsContext);

    const [, dispatch] = context ?? [];

    const fetchData = useCallback(
        async (idData: PostIdData[], requestInit?: RequestInit) => {
            if (!dispatch) {
                return;
            }

            const { postIds, contentIds } = getIdsFromPostIdData(idData);

            try {
                dispatch({ type: 'youtubePostStatsLoading', payload: { postIds } });

                const response = await youtube.getYoutubeVideos(
                    {
                        id: contentIds.join(','),
                        page_size: contentIds.length,
                    },
                    requestInit
                );

                if (response.status !== 200) {
                    dispatch({
                        type: 'youtubePostStatsError',
                        payload: { postIds, error: `Couldn't fetch Youtube post stats` },
                    });

                    return;
                }

                dispatch({
                    type: 'youtubePostStatsSuccess',
                    payload: {
                        idData,
                        stats: response.data.results,
                    },
                });
            } catch (error) {
                if (error instanceof Error && error.name === 'AbortError') {
                    dispatch({ type: 'youtubePostStatsIdle', payload: { postIds } });
                    return;
                }

                dispatch({
                    type: 'youtubePostStatsError',
                    payload: { postIds, error: `Couldn't fetch Youtube post stats` },
                });
            }
        },
        [dispatch]
    );

    const removeStats = useCallback(
        (postIds: string[]) => {
            if (!dispatch) {
                return;
            }

            dispatch({ type: 'removeYoutubePostStats', payload: postIds });
        },
        [dispatch]
    );

    return {
        fetchData,
        removeStats,
    };
}
