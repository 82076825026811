import { creatorbase } from '@round/api';
import { getTableMetaHelper, Skeleton } from '@round/ui-kit';
import { HeaderContext } from '@tanstack/react-table';
import { formatPostStatsCellValue } from 'Modules/Plans/Posts/helpers';
import { BasePostTableMeta } from 'Modules/Plans/Posts/types';

type Row = creatorbase.PublicPost;

type Context<T extends Row> = HeaderContext<T, number | null | undefined>;

const getTableMeta = getTableMetaHelper<BasePostTableMeta<Row>>();

const PostStatsViewsFooterCell = <T extends Row>({ table }: Context<T>) => {
    const { isLoading, getAreStatsLoading, campaignStats, areCampaignStatsLoading } = getTableMeta(table);

    if (
        isLoading ||
        table.getRowModel().rows.some((row) => getAreStatsLoading(row.original)) ||
        areCampaignStatsLoading
    ) {
        return <Skeleton />;
    }

    return formatPostStatsCellValue(campaignStats?.view_count);
};

export default PostStatsViewsFooterCell;
