import styles from './CampaignForm.module.css';
import SelectField from 'ui-new/formik/SelectField';
import { omit, range } from 'lodash';
import DateField from 'ui-new/formik/DateField';
import MoneyField from 'ui-new/formik/MoneyField';
import FormField from 'ui-new/formik/FormField';
import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import usePlannerUsersSelect from 'Modules/Auth/hooks/usePlannerUsersSelect';
import { getMicrowavePlatformLabel, getPlatformIcon } from 'Modules/Advertising/Microwave/helpers';
import ErrorMessage from 'ui-new/formik/ErrorMessage';
import { Skeleton } from '@round/ui-kit';
import { CampaignFormValues } from './helpers';
import { useInstagramAudiosSelect } from 'Modules/Instagram/hooks/selectHooks/useInstagramAudiosSelect';
import { useAudioSelect } from 'Modules/TikTok/hooks/useAudioSelect';
import { AudioOption } from 'Modules/TikTok/components/AudioSelectComponents/AudioSelectComponents';
import Button from 'ui/Button/Button';
import { microwave } from '@round/api';

type FormValues = Pick<
    CampaignFormValues,
    | 'budget'
    | 'currency'
    | 'planned_end_date'
    | 'planned_start_date'
    | 'platform'
    | 'target_post_count'
    | 'team_members'
    | 'instagram_audio'
    | 'is_instagram_audio_select_mode'
    | 'instagram_audio_identifier'
    | 'tiktok_audio'
    | 'tiktok_audio_identifier'
    | 'is_tiktok_audio_select_mode'
>;

type Props = {
    isLoading?: boolean;
    availablePlatforms: microwave.Platform[];
    readonlyFields?: Array<keyof FormValues>;
};

const CampaignForm = ({ isLoading, availablePlatforms, readonlyFields }: Props) => {
    const { values, setFieldValue, setFieldError } = useFormikContext<FormValues>();
    const plannersSelectProps = usePlannerUsersSelect(undefined, { initOptionsOnMenuOpen: true });
    const instagramAudioSelectProps = useInstagramAudiosSelect({ initOn: 'menuOpen' });
    const tiktokAudiosSelectProps = useAudioSelect({ initialAudioIdData: undefined });

    useEffect(() => {
        function autoSelectPlatform() {
            const isOnlyPlatformSelected = availablePlatforms.length === 1 && values.platform === availablePlatforms[0];

            if (availablePlatforms.length !== 1 || isOnlyPlatformSelected) {
                return;
            }
            setFieldValue('platform', availablePlatforms[0]);
        }

        autoSelectPlatform();
    }, [availablePlatforms, setFieldValue, values.platform]);

    return (
        <article className={styles.container}>
            <div className={styles.platformSelect}>
                {isLoading
                    ? range(3).map((i) => <Skeleton key={i} className={styles.platformSkeleton} />)
                    : availablePlatforms.map((platform) => {
                          const Icon = getPlatformIcon(platform);
                          return (
                              <label htmlFor={platform} className={styles.platformOption} key={platform}>
                                  <Icon />
                                  <input
                                      type="radio"
                                      id={platform}
                                      name="platform"
                                      value={platform}
                                      checked={values.platform === platform}
                                      onChange={(e) => setFieldValue('platform', e.target.value)}
                                  />
                                  <span>{getMicrowavePlatformLabel(platform)}</span>
                              </label>
                          );
                      })}
            </div>

            <ErrorMessage name="platform" />

            <div className={styles.row}>
                <div className={styles.fieldGroup}>
                    {isLoading ? (
                        range(2).map((i) => <Skeleton key={i} className={styles.fieldSkeleton} />)
                    ) : (
                        <>
                            <DateField
                                label="Start Date"
                                name="planned_start_date"
                                valueFormat="MMM DD, YYYY"
                                className={styles.dateField}
                            />

                            <DateField
                                label="End Date"
                                name="planned_end_date"
                                valueFormat="MMM DD, YYYY"
                                minDate={new Date(values.planned_start_date!)}
                                className={styles.dateField}
                            />
                        </>
                    )}
                </div>

                <div className={styles.fieldGroup}>
                    {isLoading ? (
                        range(2).map((i) => <Skeleton key={i} className={styles.fieldSkeleton} />)
                    ) : (
                        <>
                            <MoneyField currencyFieldName="currency" label="Budget" name="budget" placeholder="00.00" />

                            <FormField
                                type="number"
                                name="target_post_count"
                                label="Post Target"
                                placeholder="Enter post target"
                            />
                        </>
                    )}
                </div>
            </div>

            {values.platform === 'instagram' && (
                <>
                    {isLoading ? (
                        <Skeleton className={styles.fieldSkeleton} />
                    ) : (
                        <div>
                            {values.is_instagram_audio_select_mode ? (
                                <SelectField
                                    label="Instagram Audio"
                                    name="instagram_audio"
                                    disabled={readonlyFields?.includes('instagram_audio')}
                                    {...omit(instagramAudioSelectProps.props, ['value', 'onChange'])}
                                />
                            ) : (
                                <FormField
                                    className={styles.identifierField}
                                    name="instagram_audio_identifier"
                                    label="Instagram Audio"
                                    placeholder="Enter instagram audio URL or ID"
                                />
                            )}

                            {!readonlyFields?.includes('instagram_audio') && (
                                <Button
                                    className={styles.switchAudioInputButton}
                                    appearance="ghost"
                                    type="button"
                                    onClick={() => {
                                        setFieldValue(
                                            'is_instagram_audio_select_mode',
                                            !values.is_instagram_audio_select_mode
                                        );
                                        setFieldError('instagram_audio', undefined);
                                        setFieldError('instagram_audio_identifier', undefined);
                                    }}
                                >
                                    {values.is_instagram_audio_select_mode ? 'Add audio via URL/ID' : 'Select audio'}
                                </Button>
                            )}
                        </div>
                    )}
                </>
            )}

            {values.platform === 'tiktok' && (
                <>
                    {isLoading ? (
                        <Skeleton className={styles.fieldSkeleton} />
                    ) : (
                        <div>
                            {values.is_tiktok_audio_select_mode ? (
                                <SelectField
                                    label="TikTok Audio"
                                    name="tiktok_audio"
                                    disabled={readonlyFields?.includes('tiktok_audio')}
                                    {...omit(tiktokAudiosSelectProps, ['value', 'onChange'])}
                                    formatOptionLabel={(option: AudioOption) =>
                                        `${option.label} - ${option.tiktokId ?? ''}`
                                    }
                                />
                            ) : (
                                <FormField
                                    className={styles.identifierField}
                                    name="tiktok_audio_identifier"
                                    label="TikTok Audio"
                                    placeholder="Enter tiktok audio URL or ID"
                                />
                            )}

                            {!readonlyFields?.includes('tiktok_audio') && (
                                <Button
                                    className={styles.switchAudioInputButton}
                                    appearance="ghost"
                                    type="button"
                                    onClick={() => {
                                        setFieldValue(
                                            'is_tiktok_audio_select_mode',
                                            !values.is_tiktok_audio_select_mode
                                        );
                                        setFieldError('tiktok_audio', undefined);
                                        setFieldError('tiktok_audio_identifier', undefined);
                                    }}
                                >
                                    {values.is_tiktok_audio_select_mode ? 'Add audio via URL/ID' : 'Select audio'}
                                </Button>
                            )}
                        </div>
                    )}
                </>
            )}

            {isLoading ? (
                <Skeleton className={styles.fieldSkeleton} />
            ) : (
                <SelectField
                    label="Account Team"
                    name="team_members"
                    isMulti
                    {...omit(plannersSelectProps, ['value', 'onChange'])}
                />
            )}
        </article>
    );
};

export default CampaignForm;
