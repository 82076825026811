import { creatorbase } from '@round/api';
import { CellContext } from '@tanstack/react-table';
import { getTableMetaHelper, Image, Skeleton } from '@round/ui-kit';
import { ProjectTableMeta } from '../../types';
import styles from './BrandCell.module.css';

type Row = creatorbase.Project & {
    team: creatorbase.Team | null;
    brand: creatorbase.Brand | null;
};

type Context<T extends Row> = CellContext<T, T['brand']>;
const getTableMeta = getTableMetaHelper<ProjectTableMeta<Row>>();

const BrandCell = <T extends Row>({ getValue, row: { original }, table }: Context<T>) => {
    const { getIsRowLoading } = getTableMeta(table);

    if (getIsRowLoading(original)) {
        return <Skeleton width="10rem" />;
    }

    const brand = getValue();
    const team = original.team;

    return (
        <div className={styles.container}>
            <Image className={styles.image} src={brand?.image || ''} alt={brand?.name} />
            <div>
                <p className={styles.brandName}>{brand?.name || '-'}</p>
                <p className={styles.teamName}>{team?.name || '-'}</p>
            </div>
        </div>
    );
};

export default BrandCell;
