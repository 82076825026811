import { creatorbase } from '@round/api';
import { DataState, ReducerActionWithPayload } from 'App.types';
import { createReducer } from 'helpers';

type PostsUnion = creatorbase.Post | creatorbase.PublicPost;

type State<T extends PostsUnion> = {
    [campaignId: number]: DataState<T[]> | undefined;
};

type Actions<T extends PostsUnion> =
    | ReducerActionWithPayload<'postsIdle', number>
    | ReducerActionWithPayload<'loadPosts', number>
    | ReducerActionWithPayload<'postsSuccess', { campaignId: number; posts: T[] }>
    | ReducerActionWithPayload<'errorLoadingPosts', { campaignId: number; message: string }>;

export const initialState = {};

export const makePostsReducer = <T extends PostsUnion>() =>
    createReducer<State<T>, Actions<T>>({
        postsIdle: (state, { payload: campaignId }) => ({
            ...state,
            [campaignId]: {
                data: state[campaignId]?.data ?? null,
                status: 'idle',
                error: null,
            },
        }),
        loadPosts: (state, { payload: campaignId }) => ({
            ...state,
            [campaignId]: {
                data: state[campaignId]?.data ?? null,
                status: 'loading',
                error: null,
            },
        }),
        errorLoadingPosts: (state, { payload: { campaignId, message } }) => ({
            ...state,
            [campaignId]: {
                data: state[campaignId]?.data ?? null,
                error: message,
                status: 'error',
            },
        }),
        postsSuccess: (state, { payload: { campaignId, posts } }) => {
            return {
                ...state,
                [campaignId]: {
                    data: posts,
                    error: null,
                    status: 'success',
                },
            };
        },
    });
