import { creatorbase, TiktokVideo } from '@round/api';
import { getTableMetaHelper, Skeleton } from '@round/ui-kit';
import { CellContext } from '@tanstack/react-table';
import { formatPostUploadDate } from 'Modules/Plans/Posts/helpers';
import { BasePostTableMeta } from 'Modules/Plans/Posts/types';

type Row = creatorbase.PublicPost & {
    video: TiktokVideo | null;
};

type Context<T extends Row> = CellContext<T, T['video']>;

const getTableMeta = getTableMetaHelper<BasePostTableMeta<Row>>();

const TiktokPostUploadDateCell = <T extends Row>({ row, table, getValue }: Context<T>) => {
    const { isLoading, getAreStatsLoading } = getTableMeta(table);

    if (isLoading || getAreStatsLoading(row.original)) {
        return <Skeleton />;
    }

    const uploadDateInSeconds = getValue()?.create_time;

    const uploadedAt = uploadDateInSeconds ? new Date(uploadDateInSeconds * 1000) : null;

    if (uploadedAt === null) {
        return <>-</>;
    }

    return <>{formatPostUploadDate(uploadedAt)}</>;
};

export default TiktokPostUploadDateCell;
