import { TiktokVideoStats } from './videoStats.types';
import { call, encodeUrlSearchParams, fetchAll } from '../../helpers';
import {
    ApiResponse,
    ForbiddenResponse,
    NotFoundResponse,
    PaginatedApiResponseData,
    PaginatedRequest,
} from '../../types';
import { isPublicReportRequestInit } from '../../influencer';

type GetTiktokVideoStatsParams = Partial<
    PaginatedRequest & {
        video_ids: string;
        latest: boolean;
    }
>;

type GetTiktokVideoStatsResponse =
    | ApiResponse<PaginatedApiResponseData<TiktokVideoStats>, 200>
    | NotFoundResponse
    | ForbiddenResponse;

export async function getTiktokVideoStats(
    params: GetTiktokVideoStatsParams,
    requestInit?: RequestInit
): Promise<GetTiktokVideoStatsResponse> {
    const response = await call(`/api/tiktok/video-stats/${encodeUrlSearchParams(params)}`, requestInit, {
        shouldBypassTokens: isPublicReportRequestInit(requestInit),
    });

    if (response.status === 404 || response.status === 403) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not get tiktok video stats');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export async function getAllTiktokVideoStats(
    params: GetTiktokVideoStatsParams,
    requestInit?: RequestInit
): Promise<TiktokVideoStats[]> {
    return fetchAll(`/api/tiktok/video-stats/${encodeUrlSearchParams(params)}`, requestInit);
}
