import { creatorbase } from '@round/api';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';
import styles from './ProjectsTable.module.css';
import PlatformStatsBadge from 'Modules/Plans/Project/components/PlatformStatsBadge/PlatformStatsBadge';
import { ReactComponent as SongIcon } from 'assets/whitelabel/Song.svg';
import moment from 'moment';
import { getTableMetaHelper, Skeleton } from '@round/ui-kit';
import MiniLineChart from 'ui-new/whitelabel/Charts/MiniLineChart/MiniLineChart';
import Tooltip from 'ui-new/whitelabel/Tooltip/Tooltip';
import { numberWithCommas } from 'helpers';
import VirtualizedTable, { Props as VirtualizedTableProps } from 'ui-new/whitelabel/Table/VirtualizedTable';
import ProjectCell from '../../../components/cells/ProjectCell/ProjectCell';
import BrandCell from '../../../components/cells/BrandCell/BrandCell';
import UpdatedCell from '../../../components/cells/UpdatedCell';
import DurationCell from '../../../components/cells/DurationCell';
import UsersCell from '../../../components/cells/UsersCell/UsersCell';

export type ProjectsTableRow = creatorbase.Project & {
    brand: creatorbase.Brand | null;
    team: creatorbase.Team | null;
    users: creatorbase.User[];
    songStatsTimeSeries: creatorbase.TimeSeriesResponse<'tiktok_daily_change'>[0] | null;
};
type TableCellContext<TKey extends keyof ProjectsTableRow> = CellContext<ProjectsTableRow, ProjectsTableRow[TKey]>;

type Props = Pick<VirtualizedTableProps<ProjectsTableRow>, 'data' | 'onEndReached'> & {
    onSongChartClick: (songId: number) => void;
    isLoading?: boolean;
    hasError?: boolean;
};

type Meta = Pick<Props, 'onSongChartClick'> & {
    getIsRowLoading: (row: ProjectsTableRow | {}) => boolean;
};

const getTableMeta = getTableMetaHelper<Meta>();

const allPlatforms = ['tiktok', 'instagram', 'youtube'] as const;

const ProjectsTable = ({ data, isLoading, hasError, onSongChartClick, onEndReached }: Props) => {
    const navigate = useNavigate();

    const columns: ColumnDef<ProjectsTableRow, any>[] = [
        {
            header: 'Artist',
            accessorKey: 'brand',
            cell: BrandCell,
        },
        {
            header: 'Project',
            accessorKey: 'name',
            cell: ProjectCell,
        },
        {
            header: 'Channels',
            accessorKey: 'platforms',
            cell: ({ row: { original }, getValue, table }: TableCellContext<'platforms'>) => {
                const { getIsRowLoading } = getTableMeta(table);

                if (getIsRowLoading(original)) {
                    return <Skeleton width="10rem" />;
                }

                const platforms = getValue() || [];

                return (
                    <div className={styles.channelsContainer}>
                        {allPlatforms.map((platform) => (
                            <PlatformStatsBadge
                                key={platform}
                                projectId={original.id}
                                platform={platform}
                                isActive={platforms.includes(platform)}
                            />
                        ))}
                    </div>
                );
            },
        },
        {
            header: 'Last updated',
            accessorKey: 'associations_updated_at',
            cell: UpdatedCell,
        },
        {
            header: 'Run dates',
            id: 'runDates',
            cell: DurationCell,
        },
        {
            header: 'Team',
            accessorKey: 'users',
            cell: UsersCell,
        },
        {
            header: 'Song',
            id: 'song',
            meta: {
                className: styles.songColumn,
            },
            accessorKey: 'songStatsTimeSeries',
            cell: ({ getValue, row: { original }, table }: TableCellContext<'songStatsTimeSeries'>) => {
                const { getIsRowLoading, onSongChartClick } = getTableMeta(table);

                if (getIsRowLoading(original)) {
                    return <Skeleton width="10rem" />;
                }

                if (original.song_id === null) {
                    return '-';
                }

                const series = getValue()?.['tiktok_daily_change'] ?? [];
                return (
                    <div
                        className={styles.dailyChangeContainer}
                        onClick={(e) => {
                            e.stopPropagation();
                            if (!original.song_id) {
                                return;
                            }

                            onSongChartClick(original.song_id);
                        }}
                    >
                        <SongIcon className={styles.songIcon} />

                        <div className={styles.chartContainer}>
                            <MiniLineChart
                                flatLineOnEmpty
                                lineColor="#2A94FF"
                                points={series.map((s) => ({ x: s.timestamp, y: s.value }))}
                                renderTooltip={(tooltipItem) => (
                                    <>
                                        <Tooltip.Title>{moment(tooltipItem.x).format('D MMM YYYY')}</Tooltip.Title>
                                        <Tooltip.Body>
                                            <dl className={styles.chartTooltipStats}>
                                                <div className={styles.chartTooltipStatsItem}>
                                                    <dt>TikTok growth</dt>
                                                    <dd>{numberWithCommas(tooltipItem.y)}</dd>
                                                </div>
                                            </dl>
                                        </Tooltip.Body>
                                    </>
                                )}
                            />
                        </div>
                    </div>
                );
            },
        },
    ];

    const meta: Meta = {
        getIsRowLoading: (row) => Boolean(isLoading && !Object.keys(row).length),
        onSongChartClick,
    };

    const emptyRows = new Array(10).fill({}) as ProjectsTableRow[];
    const rows = isLoading ? data.concat(emptyRows) : data;

    return (
        <VirtualizedTable
            containerClassName={styles.tableContainer}
            data={rows}
            columns={columns}
            meta={meta}
            onRowClick={(row) => navigate(`${row.original.id}/`)}
            className={styles.table}
            noDataLabel={
                <div aria-label="no projects" className={styles.noDataContainer}>
                    {hasError ? "Couldn't fetch projects" : 'No projects found'}
                </div>
            }
            virtualizerOptions={{
                count: rows.length,
                estimateSize: () => 50,
                getItemKey: (index) => rows[index]?.id || index,
                overscan: 5,
            }}
            onEndReached={onEndReached}
        />
    );
};

export default ProjectsTable;
