import { TiktokVideo, TiktokVideoStats } from '@round/api';
import { DataState, ReducerAction, ReducerActionWithPayload } from 'App.types';
import { createReducer } from 'helpers';
import omit from 'lodash/omit';
import { PostIdData } from 'Modules/Plans/Posts/types';

export type Data = {
    [postId: string]: DataState<{ stats: TiktokVideoStats | null; video: TiktokVideo | null }> | undefined;
};

export type State = {
    isInitialized: boolean;
    data: Data;
};

export const initialState: State = {
    isInitialized: false,
    data: {},
};

export type Actions =
    | ReducerActionWithPayload<'tiktokPostStatsLoading', { postIds: string[] }>
    | ReducerActionWithPayload<
          'tiktokPostStatsSuccess',
          { idData: PostIdData[]; stats: TiktokVideoStats[]; videos: TiktokVideo[] }
      >
    | ReducerActionWithPayload<'tiktokPostStatsError', { postIds: string[]; error: string }>
    | ReducerActionWithPayload<'tiktokPostStatsIdle', { postIds: string[] }>
    | ReducerAction<'tiktokPostStatsInitialized'>
    | ReducerActionWithPayload<'removeTiktokPostStats', string[]>;

export const reducer = createReducer<State, Actions>({
    tiktokPostStatsLoading: (state, { payload: { postIds } }) => {
        const incomingTiktokPostStatsAsLoading = postIds.reduce((acc, postId) => {
            acc[postId] = { status: 'loading', data: null, error: null };
            return acc;
        }, {} as Data);

        return { ...state, data: { ...state.data, ...incomingTiktokPostStatsAsLoading } };
    },
    tiktokPostStatsSuccess: (state, { payload: { idData, stats, videos } }) => {
        const incomingTiktokPostStatsAsSuccess = idData?.reduce((acc, { postId, contentId }) => {
            const linkedStat = stats.find((stat) => stat.video_id === contentId) || null;
            const linkedVideo = videos.find((video) => video.id === contentId) || null;

            acc[postId] = {
                status: 'success',
                data: {
                    stats: linkedStat,
                    video: linkedVideo,
                },
                error: null,
            };
            return acc;
        }, {} as Data);

        return { ...state, data: { ...state.data, ...incomingTiktokPostStatsAsSuccess } };
    },
    tiktokPostStatsError: (state, { payload: { postIds, error } }) => {
        const incomingTiktokPostStatsAsError = postIds?.reduce((acc, postId) => {
            acc[postId] = { status: 'error', data: null, error };
            return acc;
        }, {} as Data);

        return { ...state, data: { ...state.data, ...incomingTiktokPostStatsAsError } };
    },
    tiktokPostStatsIdle: (state, { payload: { postIds } }) => {
        const incomingTiktokPostStatsAsIdle = postIds?.reduce((acc, postId) => {
            acc[postId] = { status: 'idle', data: null, error: null };
            return acc;
        }, {} as Data);

        return { ...state, data: { ...state.data, ...incomingTiktokPostStatsAsIdle } };
    },
    tiktokPostStatsInitialized: (state) => ({ ...state, isInitialized: true }),
    removeTiktokPostStats: (state, { payload }) => ({
        ...state,
        data: omit(state.data, payload),
    }),
});
