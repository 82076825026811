import { InstagramAudio } from '@round/api';
import { GenericDropdownOption } from 'App.types';
import { showNotification } from 'helpers';
import useAbortableEffect from 'Hooks/useAbortableEffect';
import { debounce } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { ValueType } from 'react-select';
import useInstagramAudio from '../useInstagramAudio';
import useInstagramAudios from '../useInstagramAudios';

const PAGE_SIZE = 25;

export type InstagramAudioOption = GenericDropdownOption<number>;
export const mapInstagramAudioToOption = (audio: InstagramAudio): InstagramAudioOption => ({
    value: audio.id,
    label: `${audio.title ?? 'Audio'} - ${audio.instagram_id}`,
});

type Params = {
    initOn?: 'menuOpen' | 'mount';
    initialId?: number;
};

export function useInstagramAudiosSelect({ initOn = 'menuOpen', initialId }: Params) {
    const [value, setValue] = useState<ValueType<InstagramAudioOption, false>>(null);
    const { fetchData, isInitialized, reset: resetInitialValue } = useInstagramAudio(initialId);

    useAbortableEffect(
        (signal) => {
            if (initialId && !isInitialized) {
                fetchData(initialId, { signal })
                    .then((response) => {
                        if (response.status === 200) {
                            setValue(mapInstagramAudioToOption(response.data));
                            return;
                        }

                        showNotification('Could not fetch initial audio', 'error');
                    })
                    .catch((e) => {
                        if (e instanceof Error && e.name === 'AbortError') {
                            return;
                        }

                        showNotification('Could not fetch initial audio', 'error');
                    });
            }
        },
        [fetchData, initialId, isInitialized]
    );

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const [areOptionsInitialized, setAreOptionsInitialized] = useState(false);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');

    const { fetchData: fetchAudios, status, data, reset } = useInstagramAudios();

    const fetchOptions = useCallback(
        async (...params: Parameters<typeof fetchAudios>) => {
            try {
                const response = await fetchAudios(...params);
                if (response.status === 404) {
                    showNotification(response.data.detail, 'error');
                }

                return response.status === 200;
            } catch (e) {
                const message = e instanceof Error ? e.message : 'Could not fetch instagram audios';
                showNotification(message, 'error');
            }
        },
        [fetchAudios]
    );

    const debouncedFetchOptions = useMemo(() => debounce(fetchOptions, 700), [fetchOptions]);

    useAbortableEffect(
        (signal) => {
            if (!areOptionsInitialized && (initOn !== 'menuOpen' || isMenuOpen)) {
                fetchOptions({ page: 1, page_size: PAGE_SIZE, identifier_search: search }, { signal }).then(
                    (success) => {
                        if (success) {
                            setAreOptionsInitialized(true);
                        }
                    }
                );
            }
        },
        [areOptionsInitialized, fetchOptions, initOn, isMenuOpen, search]
    );

    const loadNextPage = () => {
        fetchOptions({ page: page + 1, page_size: PAGE_SIZE, identifier_search: search }).then((success) => {
            if (success) {
                setPage((page) => page + 1);
            }
        });
    };

    const options = useMemo(() => data?.results.map(mapInstagramAudioToOption) ?? [], [data?.results]);

    return {
        props: {
            value,
            onChange: (value: ValueType<InstagramAudioOption, false>) => {
                setValue(value);
            },
            options,
            inputValue: search,
            onInputChange: (value: string) => {
                if (value === search) {
                    return;
                }

                reset();
                debouncedFetchOptions({ page: 1, page_size: PAGE_SIZE, identifier_search: value });
                setPage(1);
                setSearch(value);
            },
            onMenuScrollToBottom: () => {
                if (!areOptionsInitialized || status === 'loading' || !data?.next || status === 'error') {
                    return;
                }

                loadNextPage();
            },
            isLoading: status === 'loading' || (!!search.length && status === 'idle'),
            isMenuOpen,
            onMenuOpen: () => setIsMenuOpen(true),
            onMenuClose: () => setIsMenuOpen(false),
            filterOption: null,
        },
        resetValue: () => {
            setValue(null);
            resetInitialValue();
        },
    };
}
