import { InstagramUserImage } from '@round/api/dist/esm/types/src/instagram/userImage/instagramUserImage.types';
import { DataState, ReducerAction, ReducerActionWithPayload } from 'App.types';
import { createReducer } from 'helpers';

export type InstagramAccountsContextData = {
    [accountId: number]: DataState<{ image: InstagramUserImage | null }>;
};

export type InstagramAccountsContextState = {
    isInitialized: boolean;
    data: InstagramAccountsContextData;
};

export type InstagramAccountsContextActions =
    | ReducerActionWithPayload<'setAccountsDataLoading', { accountIds: number[] }>
    | ReducerActionWithPayload<'setAccountsDataSuccess', { accountIds: number[]; userImages: InstagramUserImage[] }>
    | ReducerActionWithPayload<'setAccountsDataError', { accountIds: number[]; error: string }>
    | ReducerActionWithPayload<'setAccountsDataIdle', { accountIds: number[] }>
    | ReducerAction<'setAccountsDataInitialized'>;

export default createReducer<InstagramAccountsContextState, InstagramAccountsContextActions>({
    setAccountsDataLoading: (state, { payload: { accountIds } }) => {
        const incomingAccountsDataAsLoading = accountIds.reduce((acc, accountId) => {
            acc[accountId] = { status: 'loading', data: null, error: null };
            return acc;
        }, {} as InstagramAccountsContextData);

        return { ...state, data: { ...state.data, ...incomingAccountsDataAsLoading } };
    },
    setAccountsDataSuccess: (state, { payload: { accountIds, userImages } }) => {
        const incomingAccountsDataAsSuccess = accountIds.reduce((acc, accountId) => {
            const linkedUserImage = userImages.find((image) => image.user_id === accountId) || null;

            acc[accountId] = {
                status: 'success',
                data: {
                    image: linkedUserImage,
                },
                error: null,
            };
            return acc;
        }, {} as InstagramAccountsContextData);

        return { ...state, data: { ...state.data, ...incomingAccountsDataAsSuccess } };
    },
    setAccountsDataError: (state, { payload: { accountIds, error } }) => {
        const incomingAccountsDataAsError = accountIds.reduce((acc, accountId) => {
            acc[accountId] = { status: 'error', data: null, error };
            return acc;
        }, {} as InstagramAccountsContextData);

        return { ...state, data: { ...state.data, ...incomingAccountsDataAsError } };
    },
    setAccountsDataIdle: (state, { payload: { accountIds } }) => {
        const incomingAccountsDataAsIdle = accountIds.reduce((acc, accountId) => {
            acc[accountId] = { status: 'idle', data: null, error: null };
            return acc;
        }, {} as InstagramAccountsContextData);

        return { ...state, data: { ...state.data, ...incomingAccountsDataAsIdle } };
    },
    setAccountsDataInitialized: (state) => ({ ...state, isInitialized: true }),
});
