import { creatorbase } from '@round/api';
import useNonNullContext from 'Hooks/useNonNullContext';
import { reducer, initialState } from 'Modules/Plans/AggregatedPostStats/reducers/aggregatedPostStatsByCampaign';
import { Dispatch, ReactNode, createContext, useCallback, useContext, useReducer } from 'react';

const CampaignsStatsContext = createContext<
    [ReturnType<typeof reducer>, Dispatch<Parameters<typeof reducer>[1]>] | null
>(null);

type Props = { children?: ReactNode };
export const CampaignsStatsProvider = ({ children }: Props) => {
    const state = useReducer(reducer, initialState);

    return <CampaignsStatsContext.Provider value={state}>{children}</CampaignsStatsContext.Provider>;
};

export function useCampaignStats() {
    const [state, dispatch] = useNonNullContext(CampaignsStatsContext);

    const fetchData = useCallback(
        async (campaignIds: number[], abortSignal?: AbortSignal) => {
            dispatch({ type: 'setStatsLoading', payload: { campaignIds } });

            const results = await Promise.allSettled(
                campaignIds.map(async (campaignId) => {
                    try {
                        const response = await creatorbase.postGetAggregatedPostStats(
                            {
                                campaign_id: campaignId,
                            },
                            abortSignal
                        );

                        if (response.status !== 200) {
                            dispatch({
                                type: 'setStatsError',
                                payload: { campaignId, error: `Couldn't get aggregated stats` },
                            });
                            return response;
                        }
                        dispatch({
                            type: 'setStatsSuccess',
                            payload: { campaignId, data: response.data },
                        });
                        return response;
                    } catch (error) {
                        if (error instanceof Error && error.name === 'AbortError') {
                            dispatch({
                                type: 'setStatsIdle',
                                payload: { campaignId },
                            });
                            return;
                        }

                        dispatch({
                            type: 'setStatsError',
                            payload: { campaignId, error: `Couldn't get aggregated stats` },
                        });
                        throw error;
                    }
                })
            );

            //this ensures that the abort signal is thrown and bubbles up, rather than just being caught in the allSettled result.
            abortSignal?.throwIfAborted();

            dispatch({ type: 'setStatsInitialized' });
            return results;
        },
        [dispatch]
    );

    return {
        data: state.data,
        isInitialized: state.isInitialized,
        fetchData,
    };
}

//creates a stats actions that can be safely called even if the context is not provided/initialized
//this allows us to call it in campaigns context, which is initialized before campaign stats context
export function useCampaignStatsActions() {
    const campaignStatsContext = useContext(CampaignsStatsContext);

    const [, dispatch] = campaignStatsContext ?? [];

    const refreshCampaignStats = useCallback(
        async (campaignId: number, abortSignal?: AbortSignal) => {
            if (!dispatch) {
                console.warn('CampaignsStatsContext is not provided');
                return;
            }

            dispatch({ type: 'setStatsLoading', payload: { campaignIds: [campaignId] } });

            try {
                const response = await creatorbase.postGetAggregatedPostStats(
                    {
                        campaign_id: campaignId,
                    },
                    abortSignal
                );

                if (response.status !== 200) {
                    dispatch({
                        type: 'setStatsError',
                        payload: { campaignId, error: `Couldn't get aggregated stats` },
                    });
                    return response;
                }

                dispatch({
                    type: 'setStatsSuccess',
                    payload: { campaignId, data: response.data },
                });
                return response;
            } catch (error) {
                if (error instanceof Error && error.name === 'AbortError') {
                    dispatch({
                        type: 'setStatsIdle',
                        payload: { campaignId },
                    });
                    throw error;
                }

                dispatch({
                    type: 'setStatsError',
                    payload: { campaignId, error: `Couldn't get aggregated stats` },
                });
                throw error;
            }
        },
        [dispatch]
    );

    const removeCampaignStats = useCallback(
        (campaignId: number) => {
            if (!dispatch) {
                console.warn('CampaignsStatsContext is not provided');
                return;
            }

            dispatch({ type: 'removeCampaignStats', payload: campaignId });
        },
        [dispatch]
    );

    return {
        refreshCampaignStats,
        removeCampaignStats,
    };
}
