import { useMemo, useState } from 'react';
import { useCampaigns } from '../../contexts/CampaignsContext';
import Button from 'ui-new/whitelabel/Button/Button';
import CreateInstagramCampaignModal from './CreateInstagramCampaignModal/CreateInstagramCampaignModal';
import { creatorbase } from '@round/api';
import UpdateInstagramCampaignModal from './UpdateInstagramCampaignModal/UpdateInstagramCampaignModal';
import ProjectCampaignsTable, { CampaignTableRow } from '../components/ProjectCampaignsTable/ProjectCampaignsTable';
import styles from './InstagramCampaigns.module.css';
import { OptionsContext } from 'contexts/OptionsContext/OptionsContext';
import useNonNullContext from 'Hooks/useNonNullContext';
import { useProjectDetails } from '../../contexts/ProjectContext';
import { useCampaignStats } from '../../contexts/CampaignStatsContext/CampaignsStatsContext';
import useAbortableEffect from 'Hooks/useAbortableEffect';
import { showNotification } from 'helpers';
import { ExpandedState } from '@tanstack/react-table';
import InstagramPostsTable, { InstagramPostsTableRow } from '../components/PostsTables/InstagramPostsTable';
import { usePosts } from '../../contexts/PostsContext';
import CreatePostModal from '../Posts/CreatePostModal/CreatePostModal';
import useUrlState from 'Hooks/useUrlState';
import { ReactComponent as PlusIcon } from 'assets/whitelabel/Plus.svg';
import { useInstagramPostStats } from '../../contexts/PostStatsContexts/InstagramPostStatsContext/InstagramPostStatsContext';
import { isNumber, filterKeysByValue } from 'utility/utility';
import DeleteCampaignModal from '../components/DeleteCampaignModal/DeleteCampaignModal';
import DeletePostModal from '../Posts/DeletePostModal/DeletePostModal';
import { useInstagramAccountsData } from '../../contexts/AccountDataContext/InstagramAccountsContext/InstagramAccountsContext';

type UrlState = Partial<{
    expanded: string;
}>;

const InstagramCampaigns = () => {
    const [urlState, setUrlState] = useUrlState<UrlState>();

    const { currencies } = useNonNullContext(OptionsContext);

    const { data: projectData } = useProjectDetails();

    const { data: campaigns, status: campaignsStatus, createCampaign, updateCampaign } = useCampaigns();
    const instagramCampaigns = useMemo(
        () => campaigns?.filter((c): c is creatorbase.InstagramCampaign => c.platform === 'instagram') ?? [],
        [campaigns]
    );

    const areCampaignsInitialized = campaignsStatus === 'error' || campaignsStatus === 'success';
    const { data: campaignsStats, fetchData: fetchStats, isInitialized: areStatsInitialized } = useCampaignStats();

    const campaignStatsLoading = Object.keys(
        filterKeysByValue(campaignsStats, (data) => data?.status === 'loading' || data?.status === 'idle')
    ).map(Number);

    useAbortableEffect(
        (signal) => {
            if (!areCampaignsInitialized || areStatsInitialized) {
                return;
            }

            fetchStats(
                instagramCampaigns.map((campaign) => campaign.id),
                signal
            )
                .then((results) => {
                    if (
                        results.some(
                            (result) => result.status === 'rejected' || (result.value && result.value?.status !== 200)
                        )
                    ) {
                        showNotification('Could not fetch campaign stats', 'error');
                    }
                })
                .catch(() => {});
        },
        [areCampaignsInitialized, fetchStats, areStatsInitialized, instagramCampaigns]
    );

    const tableRows: CampaignTableRow[] = useMemo(() => {
        return (
            instagramCampaigns?.map((campaign) => {
                const currency = currencies.find((c) => c.id === campaign.currency_id);
                const teamMembers =
                    projectData?.usersAssignedToCampaigns?.filter((user) => campaign.team_members.includes(user.id)) ??
                    [];

                const statsData = campaignsStats[campaign.id] ?? null;
                const stats = statsData?.status === 'success' ? statsData.data : null;

                return { ...campaign, teamMembers, currency, stats };
            }) ?? []
        );
    }, [currencies, projectData?.usersAssignedToCampaigns, instagramCampaigns, campaignsStats]);

    const [isCreateCampaignModalOpen, setIsCreateCampaignModalOpen] = useState(false);
    const [isEditCampaignModalOpen, setIsEditCampaignModalOpen] = useState(false);
    const [campaignToEdit, setCampaignToEdit] = useState<CampaignTableRow | null>(null);
    const [isConfirmDeleteCampaignModalOpen, setIsConfirmDeleteCampaignModalOpen] = useState(false);
    const [campaignToDelete, setCampaignToDelete] = useState<CampaignTableRow | null>(null);

    const [isConfirmDeletePostModalOpen, setIsConfirmDeletePostModalOpen] = useState(false);
    const [postToDelete, setPostToDelete] = useState<InstagramPostsTableRow | null>(null);
    const expandedState = useMemo(() => {
        const expanded = urlState?.expanded;
        if (!expanded) {
            return {};
        }

        return expanded.split(',').reduce((acc, campaignId) => {
            acc[campaignId] = true;
            return acc;
        }, {} as Record<string, boolean>);
    }, [urlState]);

    const setExpandedState = (expanded: ExpandedState) => {
        const expandedKeys = Object.keys(expanded).filter((key) => expanded[key as keyof ExpandedState]);
        setUrlState({ expanded: expandedKeys.join(',') });
    };

    const { data: posts, fetchData: fetchPosts, status: postsStatus, createPost, updatePost } = usePosts();
    const arePostsInitialized = postsStatus === 'success' || postsStatus === 'error';

    useAbortableEffect(
        (signal) => {
            if (arePostsInitialized || !instagramCampaigns.length) {
                return;
            }

            fetchPosts({ campaign_id: instagramCampaigns.map((c) => c.id).toString() }, { signal }).catch(() => {});
        },
        [arePostsInitialized, fetchPosts, instagramCampaigns]
    );

    const {
        data: postsStats,
        fetchData: fetchPostsStats,
        isInitialized: arePostsStatsInitialized,
    } = useInstagramPostStats();

    useAbortableEffect(
        (signal) => {
            if (!arePostsInitialized || arePostsStatsInitialized) {
                return;
            }

            const idData =
                posts?.reduce<{ postId: string; contentId: number }[]>((acc, post) => {
                    if (post.instagram_details && isNumber(post.instagram_details.content_id)) {
                        acc.push({ postId: post.id, contentId: post.instagram_details.content_id });
                    }
                    return acc;
                }, []) ?? [];

            fetchPostsStats(idData, { signal }).catch(() => {});
        },
        [arePostsInitialized, fetchPostsStats, arePostsStatsInitialized, posts]
    );
    const {
        data: userImagesData,
        isInitialized: isUserImagesDataInitialized,
        fetchData: fetchAccounts,
    } = useInstagramAccountsData();

    useAbortableEffect(
        (signal) => {
            if (!arePostsInitialized || isUserImagesDataInitialized) {
                return;
            }

            const accountIds = posts?.map((post) => post.instagram_details?.account_id).filter(isNumber) ?? [];

            fetchAccounts(accountIds, { signal }).catch(() => {});
        },
        [arePostsInitialized, fetchAccounts, isUserImagesDataInitialized, posts]
    );

    const [isCreatePostModalOpen, setIsCreatePostModalOpen] = useState(false);
    const [selectedCampaign, setSelectedCampaign] = useState<CampaignTableRow | null>(null);

    const onCreatePost = (campaign: CampaignTableRow) => {
        setIsCreatePostModalOpen(true);
        setSelectedCampaign(campaign);
    };

    const onDeletePost = (post: InstagramPostsTableRow) => {
        setIsConfirmDeletePostModalOpen(true);
        setPostToDelete(post);
    };

    return (
        <div className={styles.container}>
            <div className={styles.actions}>
                <Button
                    appearance="primary"
                    onClick={() => setIsCreateCampaignModalOpen(true)}
                    iconLeft={<PlusIcon className={styles.plusIcon} />}
                >
                    Campaign
                </Button>
            </div>

            <ProjectCampaignsTable
                data={tableRows}
                onEdit={(c) => {
                    setCampaignToEdit(c);
                    setIsEditCampaignModalOpen(true);
                }}
                onCreatePost={onCreatePost}
                onCreateCampaign={() => setIsCreateCampaignModalOpen(true)}
                onDeleteCampaign={(campaign) => {
                    setIsConfirmDeleteCampaignModalOpen(true);
                    setCampaignToDelete(campaign);
                }}
                hasError={campaignsStatus === 'error'}
                expandedState={expandedState}
                onExpandedChange={setExpandedState}
                renderSubComponent={(row) => {
                    const campaignPosts =
                        posts?.filter(
                            (p): p is creatorbase.InstagramPost =>
                                p.campaign_id === row.original.id && p.platform === 'instagram'
                        ) ?? [];

                    const rows: InstagramPostsTableRow[] = campaignPosts.map((post) => {
                        const stats = postsStats[post.id]?.data ?? null;
                        const accountId = post.instagram_details?.account_id;
                        const image = accountId ? userImagesData[accountId]?.data?.image : null ?? null;
                        return { ...post, stats, image };
                    });

                    const statsLoading = Object.keys(
                        filterKeysByValue(postsStats, (data) => data?.status === 'loading' || data?.status === 'idle')
                    ).filter((id) => rows.some((row) => row.id === id));

                    return (
                        <InstagramPostsTable
                            data={rows}
                            campaign={row.original}
                            isLoading={postsStatus === 'loading' || postsStatus === 'idle'}
                            hasError={postsStatus === 'error'}
                            statsLoading={statsLoading}
                            onCreatePost={onCreatePost}
                            onDeletePost={onDeletePost}
                            updatePost={updatePost}
                        />
                    );
                }}
                isLoading={!areCampaignsInitialized}
                statsLoading={campaignStatsLoading}
            />

            <CreateInstagramCampaignModal
                isOpen={isCreateCampaignModalOpen}
                closeModal={() => setIsCreateCampaignModalOpen(false)}
                createCampaign={createCampaign}
            />

            <UpdateInstagramCampaignModal
                isOpen={isEditCampaignModalOpen}
                closeModal={() => {
                    setIsEditCampaignModalOpen(false);
                    setCampaignToEdit(null);
                }}
                campaign={campaignToEdit as creatorbase.InstagramCampaign | null}
                updateCampaign={updateCampaign}
            />

            <DeleteCampaignModal
                isOpen={isConfirmDeleteCampaignModalOpen}
                campaignId={campaignToDelete?.id ?? null}
                onClose={() => {
                    setIsConfirmDeleteCampaignModalOpen(false);
                    setCampaignToDelete(null);
                }}
            />

            <DeletePostModal
                isOpen={isConfirmDeletePostModalOpen}
                postId={postToDelete?.id ?? null}
                onClose={() => {
                    setIsConfirmDeletePostModalOpen(false);
                    setPostToDelete(null);
                }}
            />

            <CreatePostModal
                isOpen={isCreatePostModalOpen}
                closeModal={() => {
                    setIsCreatePostModalOpen(false);
                    setSelectedCampaign(null);
                }}
                campaign={instagramCampaigns.find((c) => c.id === selectedCampaign?.id)}
                createPost={createPost}
            />
        </div>
    );
};

export default InstagramCampaigns;
