import { creatorbase, TiktokUser, TiktokUserStats, TiktokVideo, TiktokVideoStats } from '@round/api';
import { Skeleton, TableProps, getTableMetaHelper } from '@round/ui-kit';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { useMemo } from 'react';
import useAbortableEffect from 'Hooks/useAbortableEffect';
import PostsTable from 'Modules/Plans/Posts/components/PostsTable/PostsTable';
import { usePublicReportTiktokPosts } from './hooks/usePublicReportTiktokPosts';
import { CampaignTableRow } from '../../Campaigns/CampaignsTable/PublicReportCampaignTable';
import TiktokPostUploadDateCell from 'Modules/Plans/Posts/components/PostsTable/cells/TiktokPostUploadDate';
import { BasePostTableMeta } from 'Modules/Plans/Posts/types';
import PostStatsValueCell from 'Modules/Plans/Posts/components/PostsTable/cells/PostsStatsValueCell';
import PostsTableAccountCell from 'Modules/Plans/Posts/components/PostsTable/cells/PostsTableAccountCell/PostsTableAccountCell';
import PostStatsFooterTotalCell from 'Modules/Plans/Posts/components/PostsTable/cells/PostsTableStatFooterCell';
import usePublicReportCampaignStats from '../../Campaigns/usePublicReportCampaignStats';
import PostStatsViewsFooterCell from 'Modules/Plans/Posts/components/PostsTable/cells/PostStatsViewsFooterCell';
import PostsTableStatusCell, {
    StatusData,
} from 'Modules/Plans/Posts/components/PostsTable/cells/StatusCell/PostsTableStatusCell';

export type TiktokPostsTableRow = creatorbase.PublicTiktokPost & {
    video: TiktokVideo | null;
    videoStats: TiktokVideoStats | null;
    user: TiktokUser | null;
    userStats: TiktokUserStats | null;
    userImageUrl: string | null | undefined;
};

type Props = Pick<TableProps<TiktokPostsTableRow>, 'noDataLabel'> & {
    campaign: CampaignTableRow;
    publicReportId: string | undefined;
};

type TableCellContext<K extends keyof TiktokPostsTableRow> = CellContext<TiktokPostsTableRow, TiktokPostsTableRow[K]>;

type Meta = BasePostTableMeta<TiktokPostsTableRow>;
const getTableMeta = getTableMetaHelper<Meta>();

const PublicReportTiktokPostsTable = ({ campaign, publicReportId }: Props) => {
    const { postsData, postStatsData, accountsData, fetchData, getAreStatsLoading } = usePublicReportTiktokPosts(
        campaign.id
    );
    const isInitialized = postsData?.status === 'success' || postsData?.status === 'error';

    useAbortableEffect(
        (signal) => {
            if (!isInitialized && publicReportId) {
                fetchData(publicReportId, { signal }).catch(() => {});
            }
        },
        [publicReportId, fetchData, isInitialized]
    );

    const accountsLoading = Object.entries(accountsData)
        .filter(([_, data]) => data?.status === 'loading' || data?.status === 'idle')
        .map(([key]) => Number(key));

    const { data: campaignStats } = usePublicReportCampaignStats();
    const areCampaignStatsLoading = campaignStats[campaign.id]?.status === 'loading';

    const columns = useMemo<ColumnDef<TiktokPostsTableRow, any>[]>(
        () => [
            {
                header: 'ID',
                accessorKey: 'id',
                cell: ({ getValue, table }: TableCellContext<'id'>) => {
                    const { isLoading } = getTableMeta(table);

                    if (isLoading) {
                        return <Skeleton />;
                    }

                    return getValue().slice(-8);
                },
            },
            {
                header: 'Account',
                accessorFn: (row) => ({
                    image: row.userImageUrl,
                    title: row.user?.unique_id || '-',
                    followerCount: row.userStats?.follower_count,
                }),
                id: 'account',
                cell: PostsTableAccountCell,
            },
            {
                header: 'Status',
                id: 'status',
                accessorFn: (original): StatusData => ({
                    postUrl: original.post_url,
                    platform: original.platform,
                    isReadOnly: true,
                }),
                cell: PostsTableStatusCell,
            },
            {
                header: 'Uploaded date',
                accessorKey: 'video',
                cell: TiktokPostUploadDateCell,
            },
            {
                header: 'Views',
                accessorFn: (row) => row.videoStats?.play_count,
                id: 'views',
                cell: PostStatsValueCell,
                footer: PostStatsViewsFooterCell,
            },
            {
                header: 'Likes',
                accessorFn: (row) => row.videoStats?.digg_count,
                id: 'likes',
                cell: PostStatsValueCell,
                footer: PostStatsFooterTotalCell,
            },
            {
                header: 'Shares',
                accessorFn: (row) => row.videoStats?.share_count,
                id: 'shares',
                cell: PostStatsValueCell,
                footer: PostStatsFooterTotalCell,
            },
            {
                header: 'Comments',
                accessorFn: (row) => row.videoStats?.comment_count,
                id: 'comments',
                cell: PostStatsValueCell,
                footer: PostStatsFooterTotalCell,
            },
            {
                header: 'Saves',
                accessorFn: (row) => row.videoStats?.save_count,
                id: 'saves',
                cell: PostStatsValueCell,
                footer: PostStatsFooterTotalCell,
            },
        ],
        []
    );

    const meta: Meta = {
        isLoading: !isInitialized,
        getAreStatsLoading: (row) => getAreStatsLoading(row.tiktok_details?.content_id),
        getIsAccountDataLoading: (row) => !!accountsLoading.find((id) => row.tiktok_details?.account_id === id),
        campaignStats: campaignStats[campaign.id]?.data || null,
        areCampaignStatsLoading: areCampaignStatsLoading,
    };

    const rows: TiktokPostsTableRow[] = useMemo(
        () =>
            //there is currently no platform field in public posts, so we'll use the tiktok details field for now
            postsData?.data
                ?.filter((p): p is creatorbase.PublicTiktokPost => p.tiktok_details !== null)
                .map((post) => {
                    const videoId = post.tiktok_details?.content_id;
                    const video = typeof videoId === 'number' ? postStatsData?.videos[videoId]?.data || null : null;
                    const videoStats =
                        typeof videoId === 'number' ? postStatsData?.videoStats[videoId]?.data || null : null;

                    const userId = post.tiktok_details?.account_id;
                    const postAccountData = userId ? accountsData?.[userId]?.data : null;
                    const user = postAccountData?.user || null;
                    const userStats = postAccountData?.stats || null;
                    const userImage = postAccountData?.image || null;

                    return {
                        ...post,
                        video,
                        videoStats,
                        user,
                        userStats,
                        userImageUrl: userImage?.avatar_thumb.cached_url || userImage?.avatar_thumb.original_url,
                    };
                }) ?? [],
        [postsData, postStatsData, accountsData]
    );

    return (
        <PostsTable
            columns={columns}
            data={rows}
            meta={meta}
            isLoading={!isInitialized}
            hasError={postsData?.status === 'error'}
        />
    );
};

export default PublicReportTiktokPostsTable;
