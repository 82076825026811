import { creatorbase } from '@round/api';
import { DataState } from 'App.types';
import { uniq } from 'lodash';
import { useCallback, useState } from 'react';

type State = DataState<{
    reports: creatorbase.Report[];
    assignees: creatorbase.User[];
}>;

const initialState: State = {
    data: null,
    error: null,
    status: 'idle',
};

export default function useReports() {
    const [state, setState] = useState<State>(initialState);

    const fetchData = useCallback(
        async (params: Pick<creatorbase.GetReportsParams, 'search'>, requestInit?: RequestInit) => {
            setState((prev) => ({ status: 'loading', error: null, data: prev.data }));
            try {
                const response = await creatorbase.getReports({ page: 1, page_size: 1000, ...params }, requestInit);
                if (response.status !== 200) {
                    setState((prev) => ({ data: prev.data, error: response.data.detail, status: 'error' }));
                    return response;
                }

                const assigneeIds = uniq(response.data.results.map((r) => r.assignee_ids).flat());
                if (!assigneeIds.length) {
                    setState({
                        data: { reports: response.data.results, assignees: [] },
                        status: 'success',
                        error: null,
                    });
                    return response;
                }

                const assigneesResponse = await creatorbase.getUsers(
                    {
                        id: assigneeIds.join(),
                        page_size: assigneeIds.length,
                    },
                    requestInit
                );
                const assignees = assigneesResponse.status === 200 ? assigneesResponse.data.results : [];
                setState({ data: { reports: response.data.results, assignees }, status: 'success', error: null });
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    throw e;
                }

                setState((prev) => ({ data: prev.data, error: 'Could not get reports', status: 'error' }));
                throw e;
            }
        },
        []
    );

    const reset = useCallback(() => {
        setState(initialState);
    }, []);

    const createReport = useCallback(
        async (data: creatorbase.PostReportData) => {
            const response = await creatorbase.postReport(data);

            if (response.status === 201) {
                reset();
            }

            return response;
        },
        [reset]
    );

    const updateReport = useCallback(
        async (reportId: string, data: Partial<creatorbase.PatchReportData>) => {
            const response = await creatorbase.patchReport(reportId, data);

            if (response.status === 200) {
                reset();
            }

            return response;
        },
        [reset]
    );

    return {
        ...state,
        fetchData,
        createReport,
        updateReport,
        reset,
    };
}
