import { creatorbase } from '@round/api';
import { ColumnDef } from '@tanstack/react-table';
import styles from './ProjectSelectTable.module.css';
import { useMemo } from 'react';
import Checkbox from 'ui-new/whitelabel/Checkbox/Checkbox';
import VirtualizedTable, { Props as VirtualizedTableProps } from 'ui-new/whitelabel/Table/VirtualizedTable';
import ProjectCell from '../../../../Project/components/cells/ProjectCell/ProjectCell';
import BrandCell from '../../../../Project/components/cells/BrandCell/BrandCell';
import { getTableMetaHelper } from '@round/ui-kit';
import Skeleton from 'react-loading-skeleton';
import PlatformIcon from '../../../../components/PlatformIcon';
import cn from 'classnames';
import UpdatedCell from '../../../../Project/components/cells/UpdatedCell';
import DurationCell from '../../../../Project/components/cells/DurationCell';
import UsersCell from '../../../../Project/components/cells/UsersCell/UsersCell';

export type ProjectSelectTableRow = creatorbase.Project & {
    team: creatorbase.Team | null;
    brand: creatorbase.Brand | null;
    users: creatorbase.User[];
};

type Props = Pick<
    VirtualizedTableProps<ProjectSelectTableRow>,
    'data' | 'rowSelection' | 'onRowSelectionChange' | 'onEndReached' | 'containerClassName'
> & {
    isLoading?: boolean;
    hasError?: boolean;
};

type Meta = {
    getIsRowLoading: (row: ProjectSelectTableRow | {}) => boolean;
};

const getTableMeta = getTableMetaHelper<Meta>();

const ProjectSelectTable = ({
    data,
    isLoading,
    hasError,
    rowSelection,
    onRowSelectionChange,
    onEndReached,
    containerClassName,
}: Props) => {
    const columns = useMemo<ColumnDef<ProjectSelectTableRow, any>[]>(
        () => [
            {
                id: 'select',
                meta: {
                    className: styles.checkboxColumn,
                },
                //TODO: add skeleton or disable when loading. Waiting for full getIsRowLoading implementation
                header: '',
                cell: ({ row }) => {
                    return (
                        <Checkbox
                            ariaLabel="Select row"
                            value={row.getIsSelected()}
                            onChange={() => row.toggleSelected()}
                        />
                    );
                },
            },
            {
                header: 'Artist',
                accessorKey: 'brand',
                cell: BrandCell,
            },
            {
                header: 'Project',
                id: 'project',
                accessorKey: 'name',
                cell: ProjectCell,
            },
            {
                header: 'Channels',
                accessorKey: 'platforms',
                cell: ({ row: { original }, getValue, table }) => {
                    const { getIsRowLoading } = getTableMeta(table);
                    if (getIsRowLoading(original)) {
                        return <Skeleton width="10rem" />;
                    }

                    return creatorbase.platforms.map((platform) => (
                        <PlatformIcon
                            className={cn(styles.platformIcon, { [styles.ghostIcon]: !getValue().includes(platform) })}
                            key={platform}
                            platform={platform}
                            colored={getValue().includes(platform)}
                        />
                    ));
                },
            },
            {
                header: 'Last updated',
                accessorKey: 'associations_updated_at',
                cell: UpdatedCell,
            },
            {
                header: 'Duration',
                id: 'duration',
                cell: DurationCell,
            },
            {
                header: 'Team',
                accessorKey: 'users',
                cell: UsersCell,
            },
        ],
        []
    );

    const meta: Meta = {
        getIsRowLoading: (row) => !!isLoading && !Object.keys(row).length,
    };

    const emptyRows = new Array(25).fill({}) as ProjectSelectTableRow[];
    const rows = isLoading ? data.concat(emptyRows) : data;

    return (
        <VirtualizedTable
            data={rows}
            columns={columns}
            meta={meta}
            className={styles.table}
            containerClassName={containerClassName}
            noDataLabel={
                <div aria-label="no projects" className={styles.noDataContainer}>
                    {hasError ? "Couldn't fetch projects" : 'No projects found'}
                </div>
            }
            enableRowSelection
            enableMultiRowSelection
            rowSelection={rowSelection}
            onRowSelectionChange={onRowSelectionChange}
            virtualizerOptions={{
                count: rows.length,
                estimateSize: () => 50,
                getItemKey: (index) => rows[index]?.id || index,
                overscan: 5,
            }}
            onEndReached={onEndReached}
            getRowId={(row, index) => (typeof row.id === 'number' ? row.id.toString() : `skeleton-${index}`)}
        />
    );
};

export default ProjectSelectTable;
