import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { creatorbase } from '@round/api';

const platformOptions: creatorbase.Platform[] = ['instagram', 'tiktok', 'youtube'];

type PlatformSetter = (platform: creatorbase.Platform, replace?: boolean) => void;

// Function will be called when no hash is set on url
export type DefaultPlatformSelectorFunction = (setPlatform: PlatformSetter) => void;

export default function usePlatformHashSelect(
    setDefaultPlatform?: DefaultPlatformSelectorFunction
): [creatorbase.Platform, PlatformSetter] {
    const location = useLocation();
    const navigate = useNavigate();

    const goToPlatform = useCallback(
        (platform: creatorbase.Platform, replace?: boolean) => {
            navigate(`${location.search}#${platform}`, {
                replace,
            });
        },
        [location.search, navigate]
    );

    const cleanedHash = location.hash.slice(1) as creatorbase.Platform;

    useEffect(() => {
        if (platformOptions.includes(cleanedHash)) {
            return;
        }

        if (setDefaultPlatform) {
            return setDefaultPlatform(goToPlatform);
        }

        return goToPlatform(platformOptions[0], true);
    }, [goToPlatform, cleanedHash, setDefaultPlatform]);

    return [cleanedHash, goToPlatform];
}
