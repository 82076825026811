import React, { useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { encodeUrlSearchParams, parseUrlSearchParams } from '../helpers';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Primitives } from '../utility/utility.types';
import pick from 'lodash/pick';

type UrlState = { [key: string]: Primitives };

type SetUrlStateOptions = {
    replace: boolean;
};

type Options = {
    shouldSetMissingInitialValues?: boolean;
};

export default function useUrlState<T extends UrlState>(
    initialState?: T,
    options?: Options
): [Partial<Record<keyof T, string>>, (s: React.SetStateAction<Partial<T>>, options?: SetUrlStateOptions) => void] {
    const mountedRef = useRef(false);
    const location = useLocation();
    const navigate = useNavigate();

    const [, update] = useState(false);
    const queryFromUrl = useMemo(() => parseUrlSearchParams(location.search), [location.search]) as Partial<
        Record<keyof T, string>
    >;

    const setState = useCallback(
        (s: React.SetStateAction<Partial<T>>, options?: SetUrlStateOptions) => {
            const incomingQuery = typeof s === 'function' ? (s as Function)(queryFromUrl) : s;
            const updatedQuery = options?.replace ? { ...incomingQuery } : { ...queryFromUrl, ...incomingQuery };

            update((v) => !v);
            navigate(
                {
                    hash: location.hash,
                    search: encodeUrlSearchParams(updatedQuery),
                },
                { replace: true }
            );
        },
        [location.hash, navigate, queryFromUrl]
    );

    useEffect(() => {
        const queryParams = Object.keys(parseUrlSearchParams(location.search));
        const missingInitialState = pick(
            initialState,
            Object.keys(initialState ?? {}).filter((key) => !queryParams.includes(key))
        );

        const nonEmptyMissingInitialStateValuesCount = Object.values(missingInitialState).filter((v) => !!v).length;
        if (nonEmptyMissingInitialStateValuesCount && options?.shouldSetMissingInitialValues && !mountedRef.current) {
            setState(missingInitialState);
            return;
        }

        const nonEmptyInitialStateValuesCount = Object.values(initialState ?? {}).filter((v) => !!v).length;
        if (nonEmptyMissingInitialStateValuesCount === nonEmptyInitialStateValuesCount && !mountedRef.current) {
            setState(initialState ?? {});
        }
    }, [initialState, location.search, options?.shouldSetMissingInitialValues, setState]);

    useEffect(() => {
        mountedRef.current = true;
        return () => {
            mountedRef.current = false;
        };
    }, []);

    return [queryFromUrl, setState];
}
