import { useNonNullContextSelector } from 'Hooks/useNonNullContextSelector';
import { useCallback } from 'react';
import { creatorbase, influencer } from '@round/api';
import { PublicReportContext } from '../../../PublicReportContext';
import { isNumber } from 'utility/utility';
import { makeTiktokVideosDataHook } from 'Modules/TikTok/hooks/dataHooks/useTiktokVideosDataHook';
import omit from 'lodash/omit';
import { makeTiktokVideoStatsDataHook } from 'Modules/TikTok/hooks/dataHooks/useTiktokVideoStatsDataHook';
import { makeTiktokAccountsDataHook } from 'Modules/TikTok/hooks/dataHooks/useTiktokAccountsDataHook';

const useTiktokVideos = makeTiktokVideosDataHook(PublicReportContext, ([values]) => values.tiktok.videos);
const useTiktokVideoStats = makeTiktokVideoStatsDataHook(PublicReportContext, ([values]) => values.tiktok.videoStats);
const useTiktokAccountsData = makeTiktokAccountsDataHook(PublicReportContext, ([values]) => values.tiktok.accounts);

export function usePublicReportTiktokPosts(campaignId: number) {
    const postsState = useNonNullContextSelector(PublicReportContext, ([values]) => values.posts[campaignId]);
    const dispatch = useNonNullContextSelector(PublicReportContext, ([, dispatch]) => dispatch);

    const { data: videosState, fetchData: fetchVideos } = useTiktokVideos();
    const { data: videoStatsState, fetchData: fetchVideoStats } = useTiktokVideoStats();
    const { data: accountsData, fetchData: fetchAccountsData } = useTiktokAccountsData();

    const fetchData = useCallback(
        async (publicReportId: string, requestInit?: RequestInit) => {
            if (!campaignId) {
                return;
            }

            const publicIdRequestInit = influencer.addPublicReportIdToRequestInit(publicReportId, requestInit || {});
            //prevent aborting the fetchVideos and fetchVideoStats requests when posts initialized state changes
            const publicIdRequestInitWithoutSignal = omit(publicIdRequestInit, ['signal']);

            try {
                dispatch({ type: 'loadPosts', payload: campaignId });
                const response = await creatorbase.getPosts(
                    { campaign_id: campaignId.toString(), page_size: 1000 },
                    publicIdRequestInit
                );

                if (response.status === 200) {
                    dispatch({
                        type: 'postsSuccess',
                        payload: { campaignId: campaignId, posts: response.data.results },
                    });

                    const contentIds = response.data.results
                        .map((post) => post.tiktok_details?.content_id)
                        .filter(isNumber);

                    const userIds = response.data.results
                        .map((post) => post.tiktok_details?.account_id)
                        .filter(isNumber);

                    Promise.allSettled([
                        fetchVideos(contentIds, publicIdRequestInitWithoutSignal),
                        fetchVideoStats(contentIds, publicIdRequestInitWithoutSignal),
                        fetchAccountsData(userIds, publicIdRequestInitWithoutSignal),
                    ]);

                    return response;
                }

                dispatch({
                    type: 'errorLoadingPosts',
                    payload: { campaignId: campaignId, message: response.data.detail },
                });
                return response;
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    dispatch({ type: 'postsIdle', payload: campaignId });
                    throw e;
                }
                dispatch({
                    type: 'errorLoadingPosts',
                    payload: { campaignId: campaignId, message: 'Could not get posts' },
                });
                throw e;
            }
        },
        [campaignId, dispatch, fetchVideos, fetchVideoStats, fetchAccountsData]
    );

    return {
        postsData: postsState,
        postStatsData: { videos: videosState, videoStats: videoStatsState },
        accountsData: accountsData.data,
        fetchData,

        getAreStatsLoading: (contentId: number | undefined) => {
            return (
                !!contentId &&
                Boolean(
                    videosState[contentId]?.status === 'loading' || videoStatsState[contentId]?.status === 'loading'
                )
            );
        },
    };
}
