import { creatorbase, Song } from '@round/api';
import { FormikHelpers, FormikProvider, useFormik } from 'formik';
import SongForm, { SongFormValues, validationSchema } from '../../components/SongForm/SongForm';
import Modal from 'ui-new/whitelabel/Modal/Modal';
import Button from 'ui-new/whitelabel/Button/Button';
import styles from './UpdateSongModal.module.css';
import { formatSongFormValuesToApiData } from '../../components/SongForm/helpers';
import { showNotification } from 'helpers';
import { mapApiErrorsToFormikErrors } from 'utility/utility';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    onSuccess: () => void;
    song: creatorbase.Song | null;
    musicSong: Song | null;
};

const UpdateSongModal = ({ isOpen, onClose, onSuccess, song, musicSong }: Props) => {
    const handleSubmit = async (values: SongFormValues, helpers: FormikHelpers<SongFormValues>) => {
        if (!song) {
            return;
        }

        try {
            const formattedValues = formatSongFormValuesToApiData(values);
            const response = await creatorbase.patchSong(song.id, formattedValues);

            if (response.status === 200) {
                showNotification('Song updated', 'info');
                formik.resetForm();
                onSuccess();
                onClose();
                return;
            }

            if (response.status !== 400) {
                showNotification('Could not update song', 'error');
                return;
            }

            if (Array.isArray(response.data)) {
                showNotification(response.data.join(', '), 'error');
                return;
            }

            helpers.setErrors(mapApiErrorsToFormikErrors(response.data));
        } catch {
            showNotification('Could not update song', 'error');
        }
    };

    const formik = useFormik<SongFormValues>({
        initialValues: {
            title: song?.title ?? '',
            artist_name: song?.artist_name ?? '',
            status: song?.status ?? 'unreleased',
            spotify_url: musicSong?.spotify_song_url ?? '',
            tiktok_audios: song?.tiktok_audios ?? [],
            instagram_audios: song?.instagram_audios ?? [],
        },
        validationSchema,
        enableReinitialize: true,
        onSubmit: handleSubmit,
    });

    const handleClose = () => {
        formik.resetForm();
        onClose();
    };

    return (
        <Modal isOpen={isOpen} onClose={handleClose} className={styles.modal}>
            <Modal.Header>
                <Modal.Title>Update song</Modal.Title>
            </Modal.Header>

            <FormikProvider value={formik}>
                <Modal.Body>
                    <SongForm staticTiktokAudios={musicSong?.tiktok_audios} />
                </Modal.Body>

                <Modal.Actions>
                    <Button
                        appearance="primary"
                        onClick={() => {
                            formik.handleSubmit();
                        }}
                        isLoading={formik.isSubmitting}
                        disabled={!formik.isValid || formik.isSubmitting || !formik.dirty}
                    >
                        Update
                    </Button>
                    <Button appearance="ghost" onClick={handleClose}>
                        Cancel
                    </Button>
                </Modal.Actions>
            </FormikProvider>
        </Modal>
    );
};

export default UpdateSongModal;
